import type { IArticle } from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface IEditArticleModalContext {
	article: IArticle | undefined;
}

/** Context */
export const EditArticleModalContext = createContext<IEditArticleModalContext>({
	article: undefined,
});

/** Hook */
export const useEditArticleModalContext = () => {
	return useContext(EditArticleModalContext);
};

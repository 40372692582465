import type { IVendor } from "@msuite/katana";
import {
	Box,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	HStack,
	IconButton,
	Input,
	Select,
	Text,
	useUIContext,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { type UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { TbTrash } from "react-icons/tb";
import { FormError } from "./schema";

/** Props Interface */
interface VendorContactsFormProps {
	contactsArray: UseFieldArrayReturn<IVendor, "contacts", "id">;
}

export const VendorContactsForm: FC<VendorContactsFormProps> = ({ contactsArray }) => {
	/** Context */
	const { colors } = useUIContext();
	const methods = useFormContext<IVendor>();

	/** Render */
	return (
		<Fragment>
			{!contactsArray.fields.length && <Text>Es wurden noch keine Kontakte hinzugefügt</Text>}
			{contactsArray.fields.map((field, index) => {
				const isLast = index === contactsArray.fields.length - 1;
				return (
					<Grid
						key={field.id}
						templateColumns="1fr 1fr"
						gap={6}
						sx={{
							paddingBottom: !isLast ? "2rem" : undefined,
							borderBottomWidth: !isLast ? "1px" : undefined,
						}}
					>
						<GridItem colSpan={2}>
							<FormControl>
								<FormLabel>Position</FormLabel>
								<HStack spacing={6}>
									<Select
										{...methods.register(`contacts.${index}.position`)}
										placeholder="Bitte auswählen"
									>
										<option value="ceo">Geschäftsführer</option>
										<option value="internal-service">Innendienst</option>
										<option value="sales-manager">Vertriebsleiter</option>
										<option value="sales">Vertrieb</option>
										<option value="controlling">Controlling</option>
										<option value="accounting">Buchhaltung</option>
										<option value="service">Service</option>
										<option value="support">Support</option>
										<option value="misc">Sonstiges</option>
									</Select>
									<IconButton
										icon={
											<Box color={colors.red}>
												<TbTrash />
											</Box>
										}
										onClick={() => contactsArray.remove(index)}
										aria-label="Delete address"
									/>
								</HStack>
								<FormError name={`contacts.${index}.position`} />
							</FormControl>
						</GridItem>
						<GridItem colSpan={2}>
							<FormControl>
								<FormLabel>Anrede</FormLabel>
								<Select
									{...methods.register(`contacts.${index}.type`)}
									placeholder="Bitte auswählen"
								>
									<option value="mr">Herr</option>
									<option value="mrs">Frau</option>
									<option value="unknown">Keine Angabe</option>
								</Select>
								<FormError name={`contacts.${index}.type`} />
							</FormControl>
						</GridItem>
						<FormControl>
							<FormLabel>Nachname</FormLabel>
							<Input
								{...methods.register(`contacts.${index}.firstName`)}
								placeholder="z.B.: Max"
							/>
							<FormError name={`contacts.${index}.firstName`} />
						</FormControl>
						<FormControl>
							<FormLabel>Vorname</FormLabel>
							<Input
								{...methods.register(`contacts.${index}.lastName`)}
								placeholder="z.B.: Mustermann"
							/>
							<FormError name={`contacts.${index}.lastName`} />
						</FormControl>
						<GridItem colSpan={2}>
							<FormControl>
								<FormLabel>E-Mail</FormLabel>
								<Input
									{...methods.register(`contacts.${index}.email`)}
									placeholder="z.B.: max@mustermann.de"
								/>
								<FormError name={`contacts.${index}.email`} />
							</FormControl>
						</GridItem>
						<GridItem colSpan={2}>
							<FormControl>
								<FormLabel>Telefon</FormLabel>
								<Input
									{...methods.register(`contacts.${index}.phone`)}
									placeholder="z.B.: +49 176 20 22 63 60"
								/>
								<FormError name={`contacts.${index}.phone`} />
							</FormControl>
						</GridItem>
					</Grid>
				);
			})}
		</Fragment>
	);
};

import type { ConstructionSite, IEmptyLoadCarriersSuborder, IOrder, ISuborder } from "@msuite/katana";
import {
	Button,
	Heading,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SegmentedControl,
	useCollection,
	useDocument,
	VStack,
} from "@msuite/picasso";
import { OrderDetailsModalContext } from "context/order-details-modal";
import { db } from "core/_firebase";
import { type FC, useState } from "react";
import { Details } from "./details";
import { EmptyLoadCarrierSuborders } from "./empty-load-carrier-suborders";
import { SpecialSizes } from "./special-sizes";
import { Suborders } from "./suborders";

/** Props Interface */
interface OrderDetailsModalProps {
	isOpen: boolean;
	onClose: () => void;
	selectedOrderPath: string | null;
	selectedConstructionSite: ConstructionSite | undefined;
	suborderStatus?: Array<ISuborder["status"]>;
	initialTab?: OrderDetailsModalView;
}

/** Types */
export type OrderDetailsModalView = "details" | "specialSizes" | "suborders" | "emptyLoadCarrierSuborders";

export const OrderDetailsModal: FC<OrderDetailsModalProps> = ({
	isOpen,
	onClose,
	selectedOrderPath,
	selectedConstructionSite,
	suborderStatus,
	initialTab = "details",
}) => {
	/** State */
	const [currentView, setCurrentView] = useState<OrderDetailsModalView>(initialTab);
	const enabled = selectedOrderPath !== null;

	/** Hooks */
	const { data: order } = useDocument<IOrder>(db, {
		path: selectedOrderPath ?? "",
		subscribe: true,
		queryClientOptions: { enabled },
	});

	const { data: suborders } = useCollection<ISuborder>(db, {
		path: selectedOrderPath ? `${selectedOrderPath}/suborders` : "",
		subscribe: true,
		queryClientOptions: { enabled },
	});

	const { data: emptyLoadCarrierSuborders } = useCollection<IEmptyLoadCarriersSuborder>(db, {
		path: selectedOrderPath ? `${selectedOrderPath}/emptyLoadCarriersSuborders` : "",
		subscribe: true,
		queryClientOptions: { enabled },
	});

	const { data: specialSizes } = useDocument<ISuborder>(db, {
		path: selectedOrderPath ? `${selectedOrderPath}/suborders/SPECIALSIZE` : "",
		subscribe: true,
		queryClientOptions: { enabled },
	});

	/** Functions */
	function handleOnClose() {
		onClose();
		setCurrentView("details");
	}

	/** Render */
	return (
		<OrderDetailsModalContext.Provider
			value={{
				order,
				specialSizes,
				suborders,
				emptyLoadCarrierSuborders,
				constructionSite: selectedConstructionSite,
				suborderStatus,
				selectedOrderPath,
			}}
		>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<ModalContent minW="90rem">
					<ModalCloseButton />
					<ModalHeader>
						<VStack>
							<Heading size="md">Details</Heading>
							<SegmentedControl
								options={[
									{ label: "Details", value: "details" },
									{
										label: "Suborders",
										value: "suborders",
										icon: <p>{suborders.length}</p>,
									},
									{
										label: "Leergut",
										value: "emptyLoadCarrierSuborders",
										icon: <p>{emptyLoadCarrierSuborders.length}</p>,
									},
									{
										label: "Sondermaße",
										value: "specialSizes",
										icon: specialSizes ? <p>{specialSizes.articles?.length ?? 0}</p> : null,
									},
								]}
								value={currentView}
								onChange={setCurrentView}
							/>
						</VStack>
					</ModalHeader>
					<ModalBody>
						{currentView === "details" && <Details />}
						{currentView === "suborders" && <Suborders />}
						{currentView === "emptyLoadCarrierSuborders" && <EmptyLoadCarrierSuborders />}
						{currentView === "specialSizes" && <SpecialSizes />}
					</ModalBody>
					<ModalFooter>
						<ModalButtonGroup>
							<Button onClick={handleOnClose}>Fertig</Button>
						</ModalButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</OrderDetailsModalContext.Provider>
	);
};

import type { IProductGroup } from "@msuite/katana";
import { FormControl, FormLabel, Input } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { FormError } from "./schema";

export const ProductGroupForm: FC = () => {
	/** Context */
	const methods = useFormContext<IProductGroup>();

	/** Render */
	return (
		<Fragment>
			<FormControl>
				<FormLabel>ID</FormLabel>
				<Input
					{...methods.register("id")}
					placeholder="z.B.: M01"
				/>
				<FormError name="id" />
			</FormControl>
			<FormControl>
				<FormLabel>Name</FormLabel>
				<Input
					{...methods.register("name")}
					placeholder="z.B.: Systemfrei"
				/>
				<FormError name="name" />
			</FormControl>
		</Fragment>
	);
};

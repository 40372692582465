import { HStack } from "@msuite/picasso";
import { BarcodeModalTrigger } from "components/modals";
import type { FC } from "react";

/** Props Interface */
interface RowActionsProps {
	transferCarrierId: string;
}

export const RowActions: FC<RowActionsProps> = ({ transferCarrierId }) => {
	return (
		<HStack justify="flex-end">
			<BarcodeModalTrigger barcodeText={transferCarrierId} />
		</HStack>
	);
};

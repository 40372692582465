import { yupResolver } from "@hookform/resolvers/yup";
import type { IStorageLocation } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	VStack,
	handleOnReject,
	toast,
} from "@msuite/picasso";
import { StorageLocationForm } from "components/forms";
import { storageLocationFormSchema } from "components/forms/storage-location-form/schema";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddProductGroupModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AddStorageLocation: FC<AddProductGroupModalProps> = ({ isOpen, onClose }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const methods = useForm<IStorageLocation>({
		defaultValues: {
			type: "articles",
		},
		resolver: yupResolver(storageLocationFormSchema),
	});

	/** Functions */
	async function handleOnSubmit(data: IStorageLocation) {
		try {
			setIsLoading(true);
			await ax.post("/v2/stock/storage-location", { data });
			onClose();
			methods.reset();
		} catch (error) {
			toast.error(`StorageLocation mit der ID ${data.id} ist bereits vorhanden.`);
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, handleOnReject)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Lagerbereich hinzufügen</ModalHeader>
						<ModalBody>
							<VStack>
								<StorageLocationForm />
							</VStack>
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={onClose}
								>
									abbrechen
								</Button>
								<Button
									type="submit"
									isLoading={isLoading}
								>
									Lagerbereich erstellen
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};

import type { ReturnControlListDelivered, ReturnControlListReturned } from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface IReturnControlModalContext {
	delivered: ReturnControlListDelivered | undefined;
	returned: ReturnControlListReturned | undefined;
}

/** Context */
export const ReturnControlModalContext = createContext<IReturnControlModalContext>({
	delivered: undefined,
	returned: undefined,
});

/** Hook */
export const useReturnControlModalContext = () => {
	return useContext(ReturnControlModalContext);
};

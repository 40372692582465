import { type IArticle, type IArticleStockHistoryItem, moment } from "@msuite/katana";
import { useCollection, useToken } from "@msuite/picasso";
import { ResponsiveLine } from "@nivo/line";
import { useArticleStockModalContext } from "context/article-stock-modal";
import { db } from "core/_firebase";
import type { FC } from "react";

function createChartData(
	month: moment.Moment,
	article: IArticle | undefined,
	stockHistory: IArticleStockHistoryItem[],
) {
	const items = reduceHistoryToLatestTimestamp(stockHistory);
	const data = [];
	const dataItem: {
		id: string;
		data: { x: string; y: number }[];
	} = {
		id: "Artikelbestand",
		data: [],
	};
	for (let i = 0; i < month.daysInMonth(); i++) {
		const day = month.clone().startOf("month").add(i, "days");
		const item = items.find((i) => moment(i.updatedAt).isSame(day, "day"));
		dataItem.data.push({
			x: day.format("DD.MM"),
			y: item?.newStock || 0,
		});
	}

	data.push(dataItem);
	data.push({
		id: "Minimalbestand",
		data: Array.from({ length: month.daysInMonth() }).map((_, i) => ({
			x: month.clone().startOf("month").add(i, "days").format("DD.MM"),
			y: article?.minimumStock || 0,
		})),
	});

	data.push({
		id: "Warnbestand",
		data: Array.from({ length: month.daysInMonth() }).map((_, i) => ({
			x: month.clone().startOf("month").add(i, "days").format("DD.MM"),
			y: article?.warningStock || 0,
		})),
	});
	return data;
}

function reduceHistoryToLatestTimestamp(stockHistory: IArticleStockHistoryItem[]) {
	const reducedItems: IArticleStockHistoryItem[] = [];
	for (const item of stockHistory) {
		const existingItemIndex = reducedItems.findIndex((i) => moment(i.updatedAt).isSame(item.updatedAt, "day"));
		if (existingItemIndex === -1) {
			reducedItems.push(item);
		} else {
			if (moment(reducedItems[existingItemIndex].updatedAt).isBefore(item.updatedAt)) {
				reducedItems[existingItemIndex] = item;
			}
		}
	}
	return reducedItems;
}
export const Chart: FC = () => {
	/** Tokens */
	const green = useToken("colors", "green.500");
	const orange = useToken("colors", "orange.400");
	const red = useToken("colors", "red.500");

	/** Hooks */
	const { article, articleId } = useArticleStockModalContext();
	const { data: stockHistory } = useCollection<IArticleStockHistoryItem>(db, {
		path: `_stock/maindata/articles/${articleId}/stockHistory`,
		orderBy: "updatedAt",
		orderByDirection: "desc",
		subscribe: true,
	});

	/** Constants */
	const data = createChartData(moment(), article, stockHistory || []);
	const max = Math.max(...data.flatMap((d) => d.data.map((d) => d.y)));

	/** Render */
	return (
		<ResponsiveLine
			data={data}
			margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
			xScale={{ type: "point" }}
			yScale={{
				type: "linear",
				min: 0,
				max,
				stacked: false,
				reverse: false,
			}}
			yFormat=" >-.2f"
			curve="linear"
			axisTop={null}
			axisRight={null}
			enableArea
			areaBlendMode="darken"
			areaOpacity={0.1}
			layers={["grid", "markers", "axes", "areas", "crosshair", "lines", "points", "slices", "mesh", "legends"]}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 35,
				legendOffset: 30,
				legendPosition: "middle",
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: "Bestand",
				legendOffset: -40,
				legendPosition: "middle",
			}}
			enableSlices="x"
			colors={[green, orange, red]}
			lineWidth={1.5}
			pointSize={5}
			enablePoints={false}
			pointColor={{ from: "color", modifiers: [] }}
			pointBorderWidth={2}
			pointBorderColor={{ from: "serieColor" }}
			pointLabelYOffset={0}
			useMesh={true}
			legends={[]}
		/>
	);
};

import { type ITransportOrder, getOrderStateString } from "@msuite/katana";
import {
	HStack,
	Heading,
	InnerSiteLayout,
	Input,
	SegmentedControl,
	StandardDataTable,
	useTableOptions,
} from "@msuite/picasso";
import { type FC, useState } from "react";
import { columns } from "./table-config";
import { useTransportOrders } from "./use-transport-orders";

const statusArray: ITransportOrder["status"][] = ["pending", "approved", "in-progress", "completed"];

export const TransportOrders: FC = () => {
	/** State */
	const [selectedStatus, setSelectedStatus] = useState<ITransportOrder["status"]>("pending");

	/** Hooks */
	const tableOptions = useTableOptions();
	const { transportOrders } = useTransportOrders([selectedStatus]);

	/** Render */
	return (
		<InnerSiteLayout>
			<HStack>
				<Heading fontSize="xl">Transportaufträge</Heading>
			</HStack>
			<Input
				isSearch
				value={tableOptions.globalFilter}
				onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
			/>
			<SegmentedControl
				value={selectedStatus}
				onChange={setSelectedStatus}
				options={statusArray.map((status) => ({
					value: status,
					label: getOrderStateString(status),
				}))}
			/>
			<StandardDataTable
				key={selectedStatus}
				{...tableOptions}
				columns={columns}
				data={transportOrders}
				isLoading={false}
			/>
		</InnerSiteLayout>
	);
};

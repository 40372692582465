import type { TLoadCarrierType } from "@msuite/katana";
import { loadCarrierTypes } from "components/forms";
import { createContext, useContext } from "react";

/** Interface */
interface ILoadCarriersContext {
	type: TLoadCarrierType;
	setType: (type: TLoadCarrierType) => void;
	limit: number;
	setLimit: (limit: number) => void;
}

/** Context */
export const LoadCarriersContext = createContext<ILoadCarriersContext>({
	type: loadCarrierTypes?.at(0) ?? "clip",
	setType: () => { },
	limit: 9999,
	setLimit: () => { },
});

/** Hook */
export const useLoadCarriersContext = () => {
	return useContext(LoadCarriersContext);
};

import type { ConstructionSite, IEmptyLoadCarriersSuborder, ISuborder } from "@msuite/katana";
import { useCollection, useDocument } from "@msuite/picasso";
import { OrderRowContext } from "context/order-row";
import { db } from "core/_firebase";
import type { FC } from "react";
import type { FullInformationOrder } from "./use-orders";

export const DataRowWrapper: FC<{
	original?: FullInformationOrder;
	children?: React.ReactNode;
}> = ({ original, children }) => {
	/** Hooks */
	const { data: constructionSite } = useDocument<ConstructionSite>(db, {
		path: `baustellen/${original?.constructionSiteId}`,
		subscribe: true,
	});

	const { data: suborders } = useCollection<ISuborder>(db, {
		path: `baustellen/${original?.constructionSiteId}/orders/${original?.id}/suborders`,
		limit: 9999,
		subscribe: true,
	});

	const { data: emptyLoadCarriersSuborders } = useCollection<IEmptyLoadCarriersSuborder>(db, {
		path: `baustellen/${original?.constructionSiteId}/orders/${original?.id}/emptyLoadCarriersSuborders`,
		limit: 9999,
		subscribe: true,
	});

	/** Render */
	return (
		<OrderRowContext.Provider value={{ suborders, emptyLoadCarriersSuborders, constructionSite }}>
			{children}
		</OrderRowContext.Provider>
	);
};

import { ContentLayout, PrivateRoute } from "@msuite/picasso";
import { NavigationWrapper } from "components/navigation-wrapper";
import "jimp/browser/lib/jimp.js";
import { routes } from "managers/routes";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { DataMaintenance } from "sites/data-maintenance";
import { Employees } from "sites/employees";
import { Home } from "sites/home";
import { Orders } from "sites/orders";
import { ReturnControl } from "sites/return-control";
import { TransportOrders } from "sites/transport-orders";

export const App = () => {
	/** Render */
	return (
		<BrowserRouter>
			<ContentLayout>
				<NavigationWrapper>
					<Routes>
						<Route
							path="/"
							element={<PrivateRoute logoSuffix="Stock" />}
						>
							<Route
								path="/"
								element={<Navigate to="/home" />}
							/>
							<Route
								path={routes.home}
								element={<Home />}
							/>
							<Route
								path={routes.orders}
								element={<Orders />}
							/>
							<Route
								path={routes.returnControl}
								element={<ReturnControl />}
							/>
							<Route
								path={routes.dataMaintenance}
								element={<DataMaintenance />}
							/>
							<Route
								path={routes.transportOrders}
								element={<TransportOrders />}
							/>
							<Route
								path={routes.employees}
								element={<Employees />}
							/>
						</Route>
					</Routes>
				</NavigationWrapper>
			</ContentLayout>
		</BrowserRouter>
	);
};

import type { ISuborder } from "@msuite/katana";
import { createColumnHelper } from "@tanstack/react-table";
import { ChangeMap } from "./change-map";

const columnHelper = createColumnHelper<ISuborder["articles"][0]>();

export const columns = [
	columnHelper.accessor("articleId", {
		header: () => "Artikel-ID",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "15%",
		},
	}),
	columnHelper.accessor("specialSizeHeight", {
		header: () => "Höhe",
		cell: (cell) => (
			<ChangeMap
				initialValue={cell.row.original.height}
				adjustedValue={cell.row.original.specialSizeHeight}
			/>
		),
		meta: {
			width: "15%",
		},
	}),
	columnHelper.accessor("specialSizeWidth", {
		header: () => "Breite",
		cell: (cell) => (
			<ChangeMap
				initialValue={cell.row.original.width}
				adjustedValue={cell.row.original.specialSizeWidth}
			/>
		),
		meta: {
			width: "15%",
		},
	}),
	columnHelper.accessor("specialSizeLength", {
		header: () => "Länge",
		cell: (cell) => (
			<ChangeMap
				initialValue={cell.row.original.length}
				adjustedValue={cell.row.original.specialSizeLength}
			/>
		),
		meta: {
			width: "15%",
		},
	}),
	columnHelper.accessor("specialSizeNote", {
		header: () => "Kommentar",
		cell: (cell) => cell.getValue() || "--",
		meta: {
			width: "40%",
		},
	}),
];

import { type IExactStorageLocation, searchify } from "@msuite/katana";
import { VStack, useCollection, useUIContext } from "@msuite/picasso";
import { db } from "core/_firebase";
import { type FC, useMemo, useState } from "react";
import { InlaySearchInput } from "../inlay-search-input";
import { StorageLocationCardWrapper } from "./storage-location-card-wrapper";
import { TableHeader } from "./table-header";

export const LocationsContainer: FC = () => {
	/** Context */
	const { colors } = useUIContext();
	const [searchValue, setSearchValue] = useState("");

	const { data: exactStorageLocations } = useCollection<IExactStorageLocation>(db, {
		path: "_stock/maindata/exactStorageLocations",
		limit: 9999,
		subscribe: true,
	});

	const filteredLocations = useMemo(() => {
		return searchify(exactStorageLocations, searchValue);
	}, [exactStorageLocations, searchValue]);

	/** Render */
	return (
		<VStack
			bg={colors.whiteAdjusted}
			borderWidth={1}
			rounded="lg"
			minH="0"
			spacing={0}
		>
			<InlaySearchInput
				value={searchValue}
				onChange={setSearchValue}
			/>
			<TableHeader />
			<VStack
				flex={1}
				minH="0"
				overflowY="scroll"
				spacing={0}
			>
				{filteredLocations.map((exactStorageLocation) => (
					<StorageLocationCardWrapper
						key={exactStorageLocation.id}
						exactStorageLocation={exactStorageLocation}
					/>
				))}
			</VStack>
		</VStack>
	);
};

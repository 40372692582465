import { StandardDataTable, useModalContext, useTableOptions } from "@msuite/picasso";
import { useEditArticleModalContext } from "context/edit-article-modal";
import type { FC } from "react";
import { AddDependencyModalWrapper } from "./add-dependency-wrapper";
import { columns } from "./table-config";

/** Props Interface */
interface ArticleDependenciesProps {
	headerRef: React.MutableRefObject<HTMLDivElement>;
}

export const ArticleDependencies: FC<ArticleDependenciesProps> = ({ headerRef }) => {
	/** Context */
	const { article } = useEditArticleModalContext();
	const { isOpen } = useModalContext();

	/** Hooks */
	const tableOptions = useTableOptions();

	if (!isOpen) return null;

	/** Render */
	return (
		<>
			<AddDependencyModalWrapper
				headerRef={headerRef}
				article={article}
			/>
			<StandardDataTable
				key={"article-dependencies-" + JSON.stringify(article?.dependingArticles || {})}
				{...tableOptions}
				data={article?.dependingArticles ?? []}
				sorting={[]}
				setSorting={() => {}}
				columns={columns}
				isLoading={false}
			/>
		</>
	);
};

import type { OrderStatus } from "@msuite/katana";
import { Tag } from "@msuite/picasso";
import { ORDER_STATUS_STRING } from "handlers/orders";
import type { FC } from "react";

/** Interface */
interface StatusBadgeProps {
	status: OrderStatus;
}

export const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
	const shouldTranslate = Object.keys(ORDER_STATUS_STRING).includes(status);
	const label = (shouldTranslate ? ORDER_STATUS_STRING[status] : status).toUpperCase();

	/** Render */
	return (
		<Tag
			size="sm"
			colorScheme="green"
		>
			{label}
		</Tag>
	);
};

import { type IStorageLocation, getFixedLocationString, getLocationIsFixed } from "@msuite/katana";
import { Text, useDocument } from "@msuite/picasso";
import { db } from "core/_firebase";
import type { FC } from "react";

/** Props Interface */
interface StorageLocationNameProps {
	storageLocationId: string | undefined;
}

export const StorageLocationName: FC<StorageLocationNameProps> = ({ storageLocationId }) => {
	/** Computed */
	const isFixed = getLocationIsFixed(storageLocationId);

	/** Hooks */
	const { data: storageLocation } = useDocument<IStorageLocation>(db, {
		path: `_stock/maindata/storageLocations/${storageLocationId}`,
		queryClientOptions: {
			enabled: !isFixed,
		},
	});

	if (!storageLocationId) return null;

	/** Render */
	return isFixed ? <Text>{getFixedLocationString(storageLocationId)}</Text> : <Text>{storageLocation?.name}</Text>;
};

export const routes = {
	login2: "",
	login: "login",
	home: "home",
	orders: "orders",
	returnControl: "return-control",
	dataMaintenance: "data-maintenance",
	employees: "employees",
	transportOrders: "transport-orders",
};

import { getMeterValue } from "@msuite/katana";
import { HStack, Text } from "@msuite/picasso";
import type { FC } from "react";
import { IoArrowForward } from "react-icons/io5";

/** Props Interface */
interface ChangeMapProps {
	initialValue?: number;
	adjustedValue?: number;
}

export const ChangeMap: FC<ChangeMapProps> = ({ initialValue, adjustedValue }) => {
	/** Fallback */
	if (!initialValue || !adjustedValue) return <>{getMeterValue(0)}</>;

	/** Render */
	return (
		<HStack spacing={1}>
			<Text>{getMeterValue(initialValue)}</Text>
			<IoArrowForward />
			<Text>{getMeterValue(adjustedValue)}</Text>
		</HStack>
	);
};

import type { IVendor } from "@msuite/katana";
import { createColumnHelper } from "@tanstack/react-table";
import { EditVendorModalTrigger } from "components/vendors";
import { VendorShopAuthenticationRow } from "./vendor-shop-authentication-row";

const columnHelper = createColumnHelper<IVendor>();

export const columns = [
	columnHelper.accessor("vendorName", {
		header: () => "Name",
		cell: (cell) => cell.row.original.vendorName,
	}),
	columnHelper.accessor("regularDiscount", {
		header: () => "Rabatt",
		cell: (cell) => cell.row.original.regularDiscount + " %",
	}),
	columnHelper.accessor("web", {
		header: () => "Web",
		cell: (cell) => cell.row.original.web || "--",
	}),
	columnHelper.display({
		id: "shop-account",
		header: () => "Lieferanten-Zugang",
		cell: (cell) => <VendorShopAuthenticationRow vendor={cell.row.original} />,
		meta: {
			width: "30%",
		},
	}),
	columnHelper.display({
		id: "actions",
		header: () => "",
		cell: (cell) => <EditVendorModalTrigger vendor={cell.row.original} />,
		meta: {
			isNumeric: true,
		},
	}),
];

import type { IStorageLocation } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";

export const productGroupSchema = yup.object().shape({
	id: yup
		.string()
		.required("Bitte gültige ID angeben.")
		.test("startsWith", "ID muss dem Schema M## entsprechen.", (value) => {
			return /^M\d{2,3}$/.test(value);
		}),
	name: yup.string().required("Bitte gültigen Namen angeben."),
});
export const FormError = FormErrorMessage<IStorageLocation>;

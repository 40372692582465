import { type IArticle, searchify } from "@msuite/katana";
import { VStack, useCollection, useUIContext } from "@msuite/picasso";
import { db } from "core/_firebase";
import { type FC, useMemo, useState } from "react";
import { InlaySearchInput } from "../inlay-search-input";
import { ArticleCardWrapper } from "./article-card-wrapper";
import { TableHeader } from "./table-header";

export const ArticleContainer: FC = () => {
	/** Context */
	const { colors } = useUIContext();
	const [searchValue, setSearchValue] = useState("");

	/** Hooks */
	const { data: articles } = useCollection<IArticle>(db, {
		path: "/_stock/maindata/articles",
		limit: 9999,
		subscribe: true,
	});

	const filteredArticles = useMemo(() => {
		return searchify(articles, searchValue);
	}, [articles, searchValue]);

	/** Render */
	return (
		<VStack
			bg={colors.whiteAdjusted}
			borderWidth={1}
			rounded="lg"
			minH="0"
			spacing={0}
		>
			<InlaySearchInput
				value={searchValue}
				onChange={setSearchValue}
			/>
			<TableHeader />
			<VStack
				flex={1}
				minH="0"
				overflowY="scroll"
				spacing={0}
			>
				{filteredArticles.map((article) => (
					<ArticleCardWrapper
						key={article.articleId}
						article={article}
					/>
				))}
			</VStack>
		</VStack>
	);
};

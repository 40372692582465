import { type IVendor, type IVendorArticle, numbi } from "@msuite/katana";
import { DocumentString } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { db } from "core/_firebase";
import { RowActions } from "./row-actions";

const columnHelper = createColumnHelper<IVendorArticle & { id: string }>();

export const columns = [
	columnHelper.accessor("vendorId", {
		header: "Lieferant",
		cell: (cell) => (
			<DocumentString<IVendor>
				db={db}
				path={`_stock/maindata/vendors/${cell.row.original.vendorId}`}
				fields={["vendorName"]}
				shouldExtendSearch
				id={cell.row.original.vendorId}
			/>
		),
	}),
	columnHelper.accessor("purchasePrice", {
		header: "Einkaufspreis",
		cell: (cell) => numbi(cell.getValue()).currency(),
	}),
	columnHelper.accessor("purchasePriceWithDiscount", {
		header: "Einkaufspreis mit Rabatt",
		cell: (cell) => numbi(cell.getValue()).currency(),
	}),
	columnHelper.accessor("specialDiscount", {
		header: "Rabatt",
		cell: (cell) => {
			if (cell.getValue()) return cell.getValue() + "%";
			return (
				<DocumentString<IVendor>
					db={db}
					path={`_stock/maindata/vendors/${cell.row.original.vendorId}`}
					fields={["regularDiscount"]}
					transformers={[
						{
							field: "regularDiscount",
							transformer: (value) => value + "%",
						},
					]}
					shouldExtendSearch
					id={cell.row.original.vendorId}
				/>
			);
		},
	}),
	columnHelper.accessor("deliveryDays", {
		header: "Lieferzeit",
		cell: (cell) => (cell.getValue() ?? 0) + " Tage",
	}),
	columnHelper.display({
		id: "actions",
		cell: (cell) => <RowActions vendorArticle={cell.row.original} />,
	}),
];

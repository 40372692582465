import type { IArticleForm } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";

const numberValidator = (min: number) =>
	yup
		.number()
		.default(0)
		.typeError("Wert muss eine Zahl sein.")
		.min(min, `Wert muss größer als ${min - 1} sein.`);

export const articleFormSchema = yup.object().shape({
	productTypeId: yup.string().required("Bitte gültige Produkttyp-ID angeben."),
	articleName_de: yup.string().required("Bitte gültigen Namen angeben."),
	width: numberValidator(0).required("Bitte gültige Breite angeben."),
	length: numberValidator(0).required("Bitte gültige Länge angeben."),
	height: numberValidator(0).required("Bitte gültige Höhe angeben."),
	additionalSizeInfo: yup.string(),
	pickingPriorization: yup.number(),
	isCustomizable: yup.boolean().default(false).required("Bitte angeben, ob der Artikel individualisierbar ist."),
	currentStock: numberValidator(0).required("Bitte gültigen Lagerbestand angeben."),
	minimumStock: numberValidator(1).required("Bitte gültigen Mindestbestand angeben."),
	warningStock: numberValidator(1).required("Bitte gültigen Warnbestand angeben."),
	isStoredOutside: yup
		.boolean()
		.default(false)
		.required("Bitte angeben, ob der Artikel im Außenbereich gelagert wird."),
});

export const FormError = FormErrorMessage<IArticleForm>;

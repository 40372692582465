import type { IEmployeeSessionExtended } from "@msuite/katana";
import { InnerSiteLayout, Input, StandardDataTable, useTableOptions, useTitle } from "@msuite/picasso";
import { TableContext } from "context/table";
import { type FC, useState } from "react";
import { Header } from "./header";
import { columns } from "./table-config";
import { useSessions } from "./use-sessions";

export const Employees: FC = () => {
	/** Hooks */
	const [selectedEmployeeSession, setSelectedEmployeeSession] = useState<IEmployeeSessionExtended | undefined>(
		undefined,
	);
	const { sessions, isLoading } = useSessions();
	const tableOptions = useTableOptions();
	useTitle("Mitarbeiter");

	/** Functions */
	function onOpen(employeeSession: IEmployeeSessionExtended) {
		setSelectedEmployeeSession(employeeSession);
	}

	function onClose() {
		setSelectedEmployeeSession(undefined);
	}

	/** Render */
	return (
		<TableContext.Provider value={tableOptions}>
			<InnerSiteLayout>
				<Header />
				<Input
					isSearch
					value={tableOptions.globalFilter}
					onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
				/>
				<StandardDataTable
					data={sessions}
					columns={columns}
					{...tableOptions}
					isLoading={isLoading}
					onRowClick={(original) => {
						onOpen(original);
					}}
				/>
			</InnerSiteLayout>
		</TableContext.Provider>
	);
};

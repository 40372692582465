import type { ILoadCarrier } from "@msuite/katana";
import { Box, ButtonGroup, IconButton, useDisclosure, useUIContext } from "@msuite/picasso";
import { DeleteLoadCarrierModal } from "components/load-carriers/modals/delete-load-carrier-modal";
import { BarcodeModalTrigger } from "components/modals";
import type { FC } from "react";
import { IoTrash } from "react-icons/io5";

/** Props Interface */
interface RowActionsProps {
	loadCarrier: ILoadCarrier;
}

export const RowActions: FC<RowActionsProps> = ({ loadCarrier }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Render */
	return (
		<>
			<DeleteLoadCarrierModal
				loadCarrier={loadCarrier}
				isOpen={isOpen}
				onClose={onClose}
			/>
			<ButtonGroup
				justifyContent="flex-end"
				width="100%"
			>
				<BarcodeModalTrigger barcodeText={loadCarrier.loadCarrierId} />
				<IconButton
					size="xs"
					icon={
						<Box color={colors.red}>
							<IoTrash />
						</Box>
					}
					onClick={onOpen}
					aria-label="öffnen"
				/>
			</ButtonGroup>
		</>
	);
};

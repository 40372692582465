import type { IArticle } from "@msuite/katana";
import { createContext, useContext } from "react";

export type ArticleStockModalView = "current-stock" | "stock-history" | "vendor-articles";

/** Interface */
interface IArticleStockModalContext {
	currentView: ArticleStockModalView;
	articleId: string | undefined;
	article: IArticle | undefined;
}

/** Context */
export const ArticleStockModalContext = createContext<IArticleStockModalContext>({} as IArticleStockModalContext);

/** Hooks */
export const useArticleStockModalContext = () => {
	return useContext(ArticleStockModalContext);
};

import { type IArticle, type IGetArticleStockValuesApiResponse, getMeasuresStrings, numbi } from "@msuite/katana";
import {
	Box,
	Grid,
	GridItem,
	HStack,
	Heading,
	Label,
	StandardBox,
	Text,
	useApi,
	useDocument,
	useUIContext,
} from "@msuite/picasso";
import { ax } from "core/_axios";
import { db } from "core/_firebase";
import type { FC } from "react";
import { TbTriangleFilled, TbTriangleInvertedFilled } from "react-icons/tb";

/** Props Interface */
interface ArticleOverviewProps {
	articleId: string;
}

export const ArticleOverview: FC<ArticleOverviewProps> = ({ articleId }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { data: article } = useDocument<IArticle>(db, {
		path: `_stock/maindata/articles/${articleId}`,
		subscribe: true,
	});
	const { data } = useApi<IGetArticleStockValuesApiResponse>(ax, {
		url: `/v2/stock/article/${articleId}/stock-values`,
		dependencies: [articleId, "stock-values", article?.currentStock + "-stock"],
	});

	if (!article) return null;

	/** Render */
	return (
		<Grid
			templateColumns="repeat(5, 1fr) repeat(2, 1.15fr)"
			gridAutoRows="max-content"
			gridGap={6}
		>
			<GridItem colSpan={7}>
				<StandardBox>
					<Text
						fontSize="sm"
						fontWeight="medium"
					>
						{article.articleId} / {article.articleName_de}
					</Text>
					<Text>Maße: {getMeasuresStrings(article, "de")[1]}</Text>
					<Text>Zusatzinformationen: {article.additionalSizeInfo ?? "Keine"}</Text>
				</StandardBox>
			</GridItem>
			<StandardBox>
				<Label>Aktueller Bestand</Label>
				<Heading size="sm">{article.currentStock}</Heading>
			</StandardBox>
			<StandardBox>
				<Label>Warnbestand</Label>
				<Heading size="sm">{article.warningStock}</Heading>
			</StandardBox>
			<StandardBox>
				<Label>Mindestbestand</Label>
				<Heading size="sm">{article.minimumStock}</Heading>
			</StandardBox>
			<StandardBox>
				<Label>Listenwert</Label>
				<Heading size="sm">{numbi(data?.stockValue).currency()}</Heading>
			</StandardBox>
			<StandardBox>
				<Label>Einkaufswert</Label>
				<Heading size="sm">{numbi(data?.purchaseValue).currency()}</Heading>
			</StandardBox>
			<StandardBox>
				<Label>&#8960; Verbrauch</Label>
				<Heading size="sm">&#8960; 0 / Tag</Heading>
			</StandardBox>
			<StandardBox>
				<Label>Ab- und Zugänge</Label>
				<HStack>
					<HStack spacing={1}>
						<Heading
							size="sm"
							flexDirection="row"
							display="flex"
							gap={2}
						>
							<Box
								color={colors.green}
								style={{ transform: "translateY(1px)" }}
							>
								<TbTriangleFilled />
							</Box>
							--
						</Heading>
					</HStack>
					<HStack spacing={1}>
						<Heading
							size="sm"
							flexDirection="row"
							display="flex"
							gap={2}
							alignItems="center"
						>
							<Box
								color={colors.red}
								style={{ transform: "translateY(-1px)" }}
							>
								<TbTriangleInvertedFilled />
							</Box>
							--
						</Heading>
					</HStack>
				</HStack>
			</StandardBox>
		</Grid>
	);
};

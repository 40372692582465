import { moment } from "@msuite/katana";
import { DashboardWrapper, useTitle } from "@msuite/picasso";
import { HomeContext, type Workspaces } from "context/home";
import { isLocalhost } from "hooks/use-navigtation-links";
import { type FC, useState } from "react";

export const Home: FC = () => {
	/** State */
	const [activeWorkspace, setActiveWorkspace] = useState<Workspaces>("all");
	const [startDate, setStartDate] = useState<string>(moment().add(1, "days").format("YYYY-MM-DD"));

	/** Hooks */
	useTitle("Home");

	/** Functions */
	function handleOnRedirectSuite() {
		const baseUrl = isLocalhost() ? "http://localhost:5173" : "https://suite.baugeruest.de";
		window.open(`${baseUrl}/home?vacationsTileOpen=true`, "_blank");
	}

	/** Render */
	return (
		<HomeContext.Provider value={{ activeWorkspace, setActiveWorkspace, startDate, setStartDate }}>
			<DashboardWrapper
				tiles={[
					{ type: "stock-orders", renderOnWorkspaceChange: true },
					{ type: "stock-deliveries", renderOnWorkspaceChange: true },
					{ type: "stock-review-movements", renderOnWorkspaceChange: true },
					{ type: "stock-webfleet-tours" },
					{ type: "vacations", onClick: handleOnRedirectSuite },
				]}
				workspaceIds={["menke", "schröter", "scan-rent"]}
				activeWorkspace={activeWorkspace}
				setActiveWorkspace={(workspace: string) => setActiveWorkspace(workspace as "menke" | "schröter" | "scan-rent")}
				includeWorkspaceAll
				alerts={[]}
			/>
		</HomeContext.Provider>
	);
};

import { type IArticle, type IStorageLocation, getArticleMeasureString } from "@msuite/katana";
import { Grid, HStack, Text, VStack, useDocument } from "@msuite/picasso";
import { EditArticleModalTrigger } from "components/articles";
import { db } from "core/_firebase";
import { useInView } from "framer-motion";
import { type FC, useRef } from "react";

export const articleCardColumns = "1fr 1fr 1fr 2fr 0.25fr";

/** Props Interface */
export interface ArticleCardProps {
	article: IArticle;
}

export const ArticleCard: FC<ArticleCardProps> = ({ article }) => {
	/** Hooks */
	const ref = useRef<HTMLDivElement>(null);
	const isInView = useInView(ref);
	const { data: storageLocation } = useDocument<IStorageLocation>(db, {
		path: `_stock/maindata/storageLocations/${article.storageLocationId}`,
		subscribe: false,
		queryClientOptions: {
			enabled: isInView && article.storageLocationId,
		},
	});

	/** Render */
	return (
		<VStack
			_notLast={{ borderBottomWidth: 1 }}
			p="1rem"
			cursor="grab"
			borderBottomWidth={1}
			ref={ref}
		>
			<Grid
				templateColumns={articleCardColumns}
				alignItems="center"
			>
				<Text fontSize="sm">{article.articleId}</Text>
				<Text fontSize="sm">{article.articleProductGroup}</Text>
				<Text fontSize="sm">{storageLocation?.name}</Text>
				<VStack spacing={0}>
					<Text
						fontSize="sm"
						fontWeight="medium"
						noOfLines={1}
					>
						{article.articleName_de}
					</Text>
					<Text
						fontSize="sm"
						color="gray.500"
					>
						{getArticleMeasureString(article)}
					</Text>
				</VStack>
				<HStack justify="flex-end">
					<EditArticleModalTrigger article={article} />
				</HStack>
			</Grid>
		</VStack>
	);
};

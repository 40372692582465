import type { ISuborder } from "@msuite/katana";
import { HStack, Tag, Text, useUIContext } from "@msuite/picasso";
import { useOrderRowContext } from "context/order-row";
import { type FC, useState } from "react";
import { ActionMenu } from "./action-menu";
import { OrderDetailsModal } from "./modals";
import type { FullInformationOrder } from "./use-orders";

/** Props Interface */
interface StorageLocationActionsProps {
	storageLocationIds: string[];
	order: FullInformationOrder;
}

export const StorageLocationActions: FC<StorageLocationActionsProps> = ({ storageLocationIds, order }) => {
	/** Context */
	const { colors } = useUIContext();
	const { suborders, emptyLoadCarriersSuborders, constructionSite } = useOrderRowContext();

	/** State */
	const [selectedOrderPath, setSelectedOrderPath] = useState<string | null>(null);
	const [suborderStatus, setSuborderStatus] = useState<Array<ISuborder["status"]> | undefined>(undefined);

	/** Memoized */
	const _elcStatus = emptyLoadCarriersSuborders?.at(0)?.status;
	const subordersPending =
		(suborders?.filter((suborder) => suborder.status === "pending")?.length ?? 0) + (_elcStatus === "pending" ? 1 : 0);
	const subordersInProgress =
		(suborders?.filter((suborder) =>
			["ready_for_picking", "in_progress", "quantity_approved"].includes(suborder.status),
		)?.length ?? 0) + (_elcStatus === "ready_for_picking" ? 1 : 0);
	const subordersCompleted =
		(suborders?.filter((suborder) => suborder.status === "completed")?.length ?? 0) +
		(_elcStatus === "completed" ? 1 : 0);

	/** Functions */
	function handleOnOpenDetails(types: Array<ISuborder["status"]>) {
		setSuborderStatus(types);
		const selectedOrderPath = `baustellen/${order.constructionSiteId}/orders/${order.id}`;
		setSelectedOrderPath(selectedOrderPath);
	}

	function handleOnClose() {
		setSelectedOrderPath(null);
	}

	/** Render */
	return (
		<>
			{selectedOrderPath && suborderStatus !== undefined && (
				<OrderDetailsModal
					isOpen={Boolean(selectedOrderPath)}
					onClose={handleOnClose}
					selectedOrderPath={selectedOrderPath}
					suborderStatus={suborderStatus}
					initialTab="suborders"
					selectedConstructionSite={constructionSite}
				/>
			)}
			<HStack justify="space-between">
				<HStack fontWeight="semibold">
					<Tag
						colorScheme="red"
						variant={(subordersPending ?? 0) > 0 ? "solid" : "subtle"}
						size="sm"
						fontWeight="medium"
						_hover={{ opacity: 0.75 }}
						_active={{ opacity: 0.5 }}
						cursor="pointer"
						onClick={() => handleOnOpenDetails(["pending"])}
					>
						{subordersPending}
					</Tag>
					<Text
						color={colors.gray}
						opacity="0.25"
					>
						|
					</Text>
					<Tag
						colorScheme="amber"
						variant={(subordersInProgress ?? 0) > 0 ? "solid" : "subtle"}
						size="sm"
						fontWeight="medium"
						_hover={{ opacity: 0.75 }}
						_active={{ opacity: 0.5 }}
						cursor="pointer"
						onClick={() => handleOnOpenDetails(["ready_for_picking", "in_progress"])}
					>
						{subordersInProgress}
					</Tag>
					<Text
						color={colors.gray}
						opacity="0.25"
					>
						|
					</Text>
					<Tag
						colorScheme="green"
						variant={(subordersCompleted ?? 0) > 0 ? "solid" : "subtle"}
						size="sm"
						fontWeight="medium"
						_hover={{ opacity: 0.75 }}
						_active={{ opacity: 0.5 }}
						cursor="pointer"
						onClick={() => handleOnOpenDetails(["completed"])}
					>
						{subordersCompleted}
					</Tag>
				</HStack>
				<ActionMenu
					storageLocationIds={storageLocationIds}
					order={order}
				/>
			</HStack>
		</>
	);
};

import type { IVendor } from "@msuite/katana";
import { useDisclosure, WhiteIconButton } from "@msuite/picasso";
import type { FC } from "react";
import { IoPencil } from "react-icons/io5";
import { EditVendorModal } from "./edit-vendor-modal";

/** Props Interface */
interface EditVendorModalTriggerProps {
	vendor: IVendor;
}

export const EditVendorModalTrigger: FC<EditVendorModalTriggerProps> = ({ vendor }) => {
	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Render */
	return (
		<>
			{isOpen && (
				<EditVendorModal
					isOpen={isOpen}
					onClose={onClose}
					vendor={vendor}
				/>
			)}
			<WhiteIconButton
				icon={<IoPencil />}
				aria-label="Edit"
				size="xs"
				onClick={onOpen}
			/>
		</>
	);
};

import type { IArticle } from "@msuite/katana";
import { Input, Portal, StandardDataTable, useCollection, useTableOptions } from "@msuite/picasso";
import { db } from "core/_firebase";
import type { FC } from "react";
import type { DataMaintenanceChildrenProps } from "../data-maintenance";
import { Header } from "./header";
import { columns } from "./table-config";

/** Props Interface */
interface ProductTypesProps extends DataMaintenanceChildrenProps {}

export const ProductTypes: FC<ProductTypesProps> = ({ searchBarRef, headerRef }) => {
	/** Hooks */
	const tableOptions = useTableOptions();
	const { data: productTypes } = useCollection<IArticle>(db, {
		path: "_stock/maindata/productTypes",
		limit: 9999,
		subscribe: true,
	});

	/** Render */
	return (
		<>
			<Header
				headerRef={headerRef}
				searchBarRef={searchBarRef}
			/>
			<Portal containerRef={searchBarRef}>
				<Input
					isSearch
					value={tableOptions.globalFilter}
					onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
				/>
			</Portal>
			<StandardDataTable
				key="product-types-table"
				{...tableOptions}
				columns={columns}
				data={productTypes}
				isLoading={false}
				autoResetPageIndex={false}
				autoResetAll={false}
			/>
		</>
	);
};

import { getArticleMeasureString, type IArticle, type IArticleDependency } from "@msuite/katana";
import { FormControl, FormLabel, Input, Select, Text, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";

/** Props Interface */
interface ArticleDependencyFormProps {
	rootArticle: IArticle | undefined;
	articles: IArticle[] | undefined;
	isEdit?: boolean;
}

export const ArticleDependencyForm: FC<ArticleDependencyFormProps> = ({ articles = [], rootArticle, isEdit }) => {
	/** Context */
	const { register, control } = useFormContext<IArticleDependency>();
	const [selectedArticleId, dependencyFactor] = useWatch({ control, name: ["articleId", "dependencyFactor"] });
	const selectedArticle = articles?.find((article) => article.articleId === selectedArticleId);

	/** Render */
	return (
		<VStack>
			{isEdit ? null : (
				<FormControl>
					<FormLabel>Artikel</FormLabel>
					<Select
						placeholder="Artikel auswählen"
						{...register("articleId")}
					>
						{articles?.map((article) => {
							if (article.articleId === rootArticle?.articleId) return null;
							if (rootArticle?.dependingArticles?.find((a) => a.articleId === article.articleId)) return null;
							return (
								<option
									key={article.articleId}
									value={article.articleId}
								>
									{article.articleId} / {article.articleName_de} / {getArticleMeasureString(article)}
								</option>
							);
						})}
					</Select>
				</FormControl>
			)}
			<FormControl>
				<FormLabel>Abhängigkeitsfaktor</FormLabel>
				<Input
					{...register("dependencyFactor", { valueAsNumber: true })}
					type="number"
					step={1}
					min={1}
					max={10_000}
					placeholder="1"
				/>
			</FormControl>
			{selectedArticle && (
				<FormControl>
					<FormLabel>Erklärung</FormLabel>
					<Text>
						Pro{" "}
						<strong>
							{'"'}
							{rootArticle?.articleName_de}
							{'"'}
						</strong>{" "}
						werden <strong>{dependencyFactor || 1}</strong>{" "}
						<strong>
							{'"'}
							{selectedArticle.articleName_de}
							{'"'}
						</strong>{" "}
						benötigt.
					</Text>
				</FormControl>
			)}
		</VStack>
	);
};

import { Box, useUIContext } from "@msuite/picasso";
import { useArticleStockModalContext } from "context/article-stock-modal";
import type { FC } from "react";
import { Chart } from "./chart";

export const CurrentStock: FC = () => {
	/** Context */
	const { article } = useArticleStockModalContext();
	const { colors } = useUIContext();

	/** Render */
	return (
		<Box
			width="100%"
			height="40rem"
			backgroundColor={colors.whiteAdjusted}
			borderWidth={0.5}
			borderRadius="md"
		>
			<Chart />
		</Box>
	);
};

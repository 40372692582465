import { Flex, Grid } from "@msuite/picasso";
import type { FC } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import type { DataMaintenanceChildrenProps } from "../data-maintenance";
import { ArticleContainer } from "./article-container";
import { LocationsContainer } from "./locations-container";

/** Props Interface */
interface StorageLocationsAssignmentProps extends DataMaintenanceChildrenProps {}

export const StorageLocationsAssignment: FC<StorageLocationsAssignmentProps> = () => {
	/** Render */
	return (
		<DndProvider backend={HTML5Backend}>
			<Flex
				paddingBottom="5vh"
				flex={1}
				minH="0"
			>
				<Grid
					templateColumns="1fr 1fr"
					flex={1}
					gap={6}
				>
					<ArticleContainer />
					<LocationsContainer />
				</Grid>
			</Flex>
		</DndProvider>
	);
};

import { type IArticle, type createArticleSections, getFirstArticleIdOfArticleProductGroup } from "@msuite/katana";
import { Box, Collapse, HStack, Text, VStack, useDisclosure, useDocument, useUIContext } from "@msuite/picasso";
import { db } from "core/_firebase";
import type { FC } from "react";
import { IoChevronBack } from "react-icons/io5";
import { ArticleTypeSection } from "./article-type-section";

/** Props Interface */
interface ProductGroupSectionProps {
	section: ReturnType<typeof createArticleSections>[0];
}

export const ProductGroupSection: FC<ProductGroupSectionProps> = ({ section }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Constants */
	const articleId = getFirstArticleIdOfArticleProductGroup(section);

	/** Hooks */
	const { onToggle, isOpen } = useDisclosure({
		defaultIsOpen: false,
	});
	const { data: firstArticle } = useDocument<IArticle>(db, {
		path: `_stock/maindata/articles/${articleId}`,
		subscribe: false,
	});

	/** Render */
	return (
		<VStack spacing={0}>
			<HStack
				py={2}
				px={3}
				backgroundColor={colors.whiteAdjusted}
				justify="space-between"
				borderWidth={1}
				rounded="md"
				cursor="pointer"
				onClick={onToggle}
			>
				<Text
					fontWeight="medium"
					fontSize="sm"
				>
					{firstArticle?.articleProductGroup}
				</Text>
				<Box
					fontSize="sm"
					transition=".2s"
					sx={{
						transform: isOpen ? "rotate(-90deg)" : "rotate(0deg)",
					}}
				>
					<IoChevronBack />
				</Box>
			</HStack>
			<Collapse in={isOpen}>
				<VStack
					pl={6}
					pt={6}
					spacing={2}
				>
					{section.articleTypes.map((articleTypeSection) => (
						<ArticleTypeSection
							key={articleTypeSection.articleType}
							section={articleTypeSection}
							firstArticle={firstArticle}
						/>
					))}
				</VStack>
			</Collapse>
		</VStack>
	);
};

import type { IStorageLocation } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";

export const storageLocationFormSchema = yup.object().shape({
	name: yup.string().required("Name is required"),
	type: yup.string().oneOf(["articles", "loadCarriers"]).required("Type is required"),
});

export const FormError = FormErrorMessage<IStorageLocation>;

import { Button, Portal, useDisclosure } from "@msuite/picasso";
import { AddExactStorageLocationsModal } from "components/modals/add-exact-storage-locations-modal";
import type { FC } from "react";
import { IoAdd } from "react-icons/io5";
import type { DataMaintenanceChildrenProps } from "../data-maintenance";

/** Props Interface */
interface HeaderProps extends DataMaintenanceChildrenProps {}

export const Header: FC<HeaderProps> = ({ headerRef }) => {
	/** State */
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Render */
	return (
		<>
			<Portal containerRef={headerRef}>
				<Button
					leftIcon={<IoAdd />}
					onClick={onOpen}
				>
					Lagerorte hinzufügen
				</Button>
			</Portal>
			<AddExactStorageLocationsModal
				isOpen={isOpen}
				onClose={onClose}
			/>
		</>
	);
};

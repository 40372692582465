import { LoadCarrierCardReturnControl } from "components/cards";
import { useReturnControlModalContext } from "context/return-control-modal";
import { type FC, useMemo } from "react";
import { IoCheckmark } from "react-icons/io5";
import type { ReturnControlOverviewProps } from "../return-control-articles-overview";

export const ReturnControlLoadCarriersOverview: FC<ReturnControlOverviewProps> = ({ delivered }) => {
	/** Context */
	const { returned } = useReturnControlModalContext();

	/** Memoized */
	const loadCarriersSorted = useMemo(() => {
		return [...delivered.loadCarriers].sort((a, b) => {
			return a.localeCompare(b);
		});
	}, [delivered.loadCarriers]);

	/** Render */
	return (
		<>
			{loadCarriersSorted.map((loadCarrierId) => (
				<LoadCarrierCardReturnControl
					key={loadCarrierId}
					loadCarrierId={loadCarrierId}
					rightAppendix={returned?.loadCarriers.includes(loadCarrierId) && <IoCheckmark />}
				/>
			))}
		</>
	);
};

import { type ReturnControlListDelivered, type ReturnControlListReturned, createArticleSections } from "@msuite/katana";
import { VStack } from "@msuite/picasso";
import { type FC, useMemo } from "react";
import { ProductGroupSection } from "./product-group-section";

/** Props Interface */
export interface ReturnControlOverviewProps {
	delivered: ReturnControlListDelivered;
	returned: ReturnControlListReturned;
}

export const ReturnControlArticlesOverview: FC<ReturnControlOverviewProps> = ({ delivered, returned }) => {
	/** Memoized */
	const articleSections = useMemo(() => {
		return createArticleSections(delivered?.articles ?? []);
	}, [delivered]);

	/** Render */
	return (
		<VStack spacing={0}>
			{articleSections.map((section) => (
				<ProductGroupSection
					section={section}
					key={section.productGroup}
				/>
			))}
		</VStack>
	);
};

import type { IProductType } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";

export const productTypeSchema = yup.object().shape({
	productTypeId: yup
		.string()
		.required("Bitte gültige Produkttyp-ID angeben.")
		.test("startsWith", "Produkttyp-ID muss dem Schema ### entsprechen.", (value) => {
			return /^\d{3}$/.test(value);
		}),
	name: yup.string().required("Bitte gültigen Namen angeben."),
	productGroupId: yup.string().required("Bitte gültige Produktgruppe auswählen."),
});
export const FormError = FormErrorMessage<IProductType>;

import type { OrderStatus } from "@msuite/katana";

/**
 * Order status string mapping
 */
export const ORDER_STATUS_STRING: Record<OrderStatus | "NA", string> = {
	pending: "Ausstehend",
	canceled: "Abgebrochen",
	completed: "Abgeschlossen",
	in_progress: "In Bearbeitung",
	quantity_approved: "Artikel kontrolliert",
	ready_for_picking: "Bereit zur Kommissionierung",
	NA: "--",
};

/**
 * Order status color mapping
 */
export const ORDER_STATUS_COLOR: Record<OrderStatus | "NA", string> = {
	pending: "red",
	canceled: "red",
	completed: "green",
	in_progress: "amber",
	quantity_approved: "amber",
	ready_for_picking: "amber",
	NA: "gray",
};

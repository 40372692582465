import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from "@msuite/picasso";
import { ax } from "core/_axios";
import { type FC, useState } from "react";

/** Props Interface */
interface AddTransferCarrierModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AddTransferCarrierModal: FC<AddTransferCarrierModalProps> = ({ isOpen, onClose }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Functions */
	async function onSubmit() {
		try {
			setIsLoading(true);
			await ax.post("/v2/stock/transfer-carrier");
			onClose();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>Transportbehälter hinzufügen</ModalHeader>
				<ModalBody>
					<VStack>
						<Text>Möchten Sie wirklich einen neuen Transport behälter erstellen?</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<ModalButtonGroup>
						<Button
							variant="ghost"
							onClick={onClose}
						>
							abbrechen
						</Button>
						<Button
							type="button"
							isLoading={isLoading}
							onClick={onSubmit}
						>
							Transportbehälter erstellen
						</Button>
					</ModalButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

import type { IArticle } from "@msuite/katana";
import { toast } from "@msuite/picasso";
import { StorageLocationCard, type StorageLocationCardProps } from "components/cards/storage-location-card";
import { ax } from "core/_axios";
import type { FC } from "react";
import { useDrop } from "react-dnd";

export const StorageLocationCardWrapper: FC<StorageLocationCardProps> = ({ exactStorageLocation }) => {
	/** Hooks */
	const [monitor, dropRef] = useDrop(
		() => ({
			accept: "article",
			drop: handleDrop,
			collect: (monitor) => ({
				isOverAndCantDrop: monitor.isOver() && exactStorageLocation.storageLocationId === undefined,
				isOverAndCanDrop: monitor.isOver() && exactStorageLocation.storageLocationId !== undefined,
			}),
		}),
		[exactStorageLocation],
	);

	/** Handlers */
	async function handleDrop(payload: { article: IArticle }) {
		try {
			if (!exactStorageLocation.storageLocationId) throw new Error("No storage location id found.");
			toast.promise(
				async () => {
					await ax.patch(`/v2/stock/storage-location/${exactStorageLocation.id}/connect/${payload.article.articleId}`);
				},
				{
					loading: `Verbinde '${payload.article.articleName_de}' mit ${exactStorageLocation.id}...}`,
					success: `Artikel '${payload.article.articleName_de}' mit ${exactStorageLocation.id} erfolgreich verbunden.`,
					error: `Artikel '${payload.article.articleName_de}' konnte nicht mit ${exactStorageLocation.id} verbunden werden.`,
				},
			);
		} catch (error) {
			toast.error("Lagerort ist keinem Kommissionierbereich zugeordnet.");
			console.error(error);
		}
	}

	/** Render */
	return (
		<div ref={dropRef}>
			<StorageLocationCard
				{...monitor}
				exactStorageLocation={exactStorageLocation}
			/>
		</div>
	);
};

import type { IVendor } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import { z } from "zod";

export const FormError = FormErrorMessage<IVendor>;
const vendorSchema = z.object({
	vendorName: z.string().min(1, { message: "Dieses Feld muss mindestens 1 Zeichen lang sein." }),
	regularDiscount: z
		.number()
		.min(0, { message: "Dieses Feld muss mindestens 0 sein." })
		.max(100, { message: "Dieses Feld darf höchstens 100 sein." }),
	addresses: z.array(
		z.object({
			type: z.union([z.literal("hq"), z.literal("branch")]),
			street: z.string().min(1, { message: "Dieses Feld muss mindestens 1 Zeichen lang sein." }),
			number: z.string().min(1, { message: "Dieses Feld muss mindestens 1 Zeichen lang sein." }),
			zipCode: z.string().min(1, { message: "Dieses Feld muss mindestens 1 Zeichen lang sein." }),
			city: z.string().min(1, { message: "Dieses Feld muss mindestens 1 Zeichen lang sein." }),
			phone: z.string().optional(),
			email: z.string().email({ message: "Ungültige E-Mail-Adresse." }).optional(),
			fax: z.string().optional(),
		}),
	).optional(),
	contacts: z.array(
		z.object({
			type: z.union([z.literal("mr"), z.literal("mrs"), z.literal("unknown")]),
			position: z.union([
				z.literal("ceo"),
				z.literal("internal-service"),
				z.literal("sales-manager"),
				z.literal("sales"),
				z.literal("controlling"),
				z.literal("accounting"),
				z.literal("service"),
				z.literal("support"),
				z.literal("misc"),
			]),
			firstName: z.string().min(1, { message: "Dieses Feld muss mindestens 1 Zeichen lang sein." }),
			lastName: z.string().min(1, { message: "Dieses Feld muss mindestens 1 Zeichen lang sein." }),
			email: z.string().email({ message: "Ungültige E-Mail-Adresse." }).optional(),
			phone: z.string().optional(),
			fax: z.string().optional(),
		}),
	).optional(),
	web: z.string().optional(),
	signIn: z.object({
		shopUrl: z.string().optional(),
		username: z.string().optional(),
		password: z.string().optional(),
	}).optional(),
});

export { vendorSchema };

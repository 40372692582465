import {
	type GenerateLoadCarrierForm,
	loadCarrierTypeToIdentificationNumber,
	type TLoadCarrierType,
} from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";

export const FormError = FormErrorMessage<GenerateLoadCarrierForm>;

export const loadCarrierTypes = (["clip", "eurobox", "latticebox", "tubebox", "palette"] as TLoadCarrierType[]).sort(
	(a, b) => {
		const numberA = loadCarrierTypeToIdentificationNumber(a);
		const numberB = loadCarrierTypeToIdentificationNumber(b);
		return numberA - numberB;
	},
);

export const loadCarrierSchema = yup.object().shape({
	type: yup.string().oneOf(loadCarrierTypes).required("Ladungsträger Typ ist erforderlich"),
	numberOfLoadCarriers: yup
		.number()
		.required("Anzahl ist erforderlich")
		.min(1, "Anzahl muss größer als 0 sein")
		.max(9999, "Anzahl muss kleiner als 10.000 sein"),
	fillIdGaps: yup.boolean().default(true),
});

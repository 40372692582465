import {
	type Employee,
	type ILoadCarrier,
	type ITransferCarrier,
	type ITransportOrder,
	generateId,
	getOrderStateString,
	moment,
} from "@msuite/katana";
import { DocumentString } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { StorageLocationName } from "components/storage-locations";
import { db } from "core/_firebase";
import { RowAction } from "./row-action";

const columnHelper = createColumnHelper<ITransportOrder>();

export const columns = [
	columnHelper.accessor("id", {
		header: "ID",
		cell: (cell) => cell.getValue(),
		meta: {
			width: `${100 / 6}%`,
		},
	}),
	columnHelper.display({
		id: "transferCarrierId",
		header: "TB / LT",
		cell: (cell) =>
			cell.row.original.transferCarrierId ? (
				<DocumentString<ITransferCarrier>
					db={db}
					path={`_stock/maindata/transferCarriers/${cell.row.original.transferCarrierId}`}
					fields={["transferCarrierId"]}
				/>
			) : (
				<DocumentString<ILoadCarrier>
					db={db}
					path={`_stock/maindata/loadCarriers/${cell.row.original.loadCarrierId}`}
					fields={["loadCarrierId"]}
				/>
			),
		meta: {
			width: `${100 / 6}%`,
		},
	}),
	columnHelper.accessor("origin", {
		header: "Start",
		cell: (cell) => <StorageLocationName storageLocationId={cell.getValue()} />,
		meta: {
			width: `${100 / 6}%`,
		},
	}),
	columnHelper.accessor("destination", {
		header: "Ziel",
		cell: (cell) => <StorageLocationName storageLocationId={cell.getValue()} />,
		meta: {
			width: `${100 / 6}%`,
		},
	}),
	columnHelper.accessor("status", {
		header: "Status",
		cell: (cell) => getOrderStateString(cell.getValue()),
		meta: {
			width: `${100 / 6}%`,
		},
	}),
	columnHelper.accessor("updatedAt", {
		header: "Erstellet am",
		cell: (cell) => moment(cell.getValue()).format("LLLL"),
		meta: {
			width: `${100 / 6}%`,
		},
	}),
	columnHelper.accessor("updatedBy", {
		header: "Erstellt von",
		cell: (cell) => (
			<DocumentString<Employee>
				db={db}
				id={cell.getValue()}
				path={`mitarbeiter/${cell.getValue()}`}
				fields={["vorname", "nachname"]}
				shouldExtendSearch
			/>
		),
		meta: {
			width: `${100 / 6}%`,
		},
	}),
	columnHelper.display({
		id: generateId(),
		header: "",
		cell: (cell) => <RowAction transportOrder={cell.row.original} />,
		meta: {
			width: `${100 / 6}%`,
			isNumeric: true,
		},
	}),
];

import type { IExactStorageLocation } from "@msuite/katana";
import { IconButton, Tooltip, toast, useAsyncModal } from "@msuite/picasso";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { MdClear } from "react-icons/md";

/** Props Interface */
interface CleanStorageLocationTriggerProps {
	storageLocation: IExactStorageLocation;
}

export const CleanStorageLocationTrigger: FC<CleanStorageLocationTriggerProps> = ({ storageLocation }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const asyncModal = useAsyncModal();
	const storageLocationId = storageLocation.id;
	const currentlyStoredArticleId = storageLocation.currentlyStoredArticleId;

	/** Functions */
	async function handleOnCleanStorageLocation() {
		try {
			setIsLoading(true);
			const isConfirmed = await asyncModal({
				headerText: "Artikel entfernen",
				bodyText: `Sind Sie sicher, dass sie den Artikel (${currentlyStoredArticleId}) von diesem Lagerort (${storageLocationId}) entfernen wollen?`,
			});
			if (!isConfirmed) {
				toast("Abgebrochen");
				return;
			}
			await ax.patch(`/v2/stock/storage-location/${storageLocationId}/clean-article`);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	if (!currentlyStoredArticleId) return null;

	/** Render */
	return (
		<Tooltip label="Artikel entfernen">
			<IconButton
				size="xs"
				icon={<MdClear />}
				aria-label="Clean storage location"
				onClick={handleOnCleanStorageLocation}
				isLoading={isLoading}
			/>
		</Tooltip>
	);
};

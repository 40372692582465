import type { IArticle } from "@msuite/katana";
import {
	Button,
	Heading,
	HStack,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SegmentedControl,
	Spacer,
	useDisclosure,
	useDocument,
	VStack,
	WhiteIconButton,
} from "@msuite/picasso";
import { EditArticleModal } from "components/articles";
import { ArticleStockModalContext, type ArticleStockModalView } from "context/article-stock-modal";
import { db } from "core/_firebase";
import { type FC, useRef, useState } from "react";
import { IoPencil } from "react-icons/io5";
import { Content } from "./content";

/** Props Interface */
interface ArticleStockModalProps {
	isOpen: boolean;
	onClose: () => void;
	articleId: string | undefined;
}

export const ArticleStockModal: FC<ArticleStockModalProps> = ({ isOpen, onClose, articleId }) => {
	/** State */
	const [currentView, setCurrentView] = useState<ArticleStockModalView>("current-stock");

	/** Hooks */
	const headerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const editArticleModal = useDisclosure();
	const { data: article } = useDocument<IArticle>(db, {
		path: `_stock/maindata/articles/${articleId}`,
		subscribe: true,
		queryClientOptions: {
			enabled: articleId !== undefined,
		},
	});

	/** Render */
	return (
		<>
			<ArticleStockModalContext.Provider value={{ currentView, articleId, article }}>
				<Modal
					isOpen={isOpen}
					onClose={onClose}
				>
					<ModalOverlay />
					<ModalContent maxW="100rem">
						<ModalCloseButton />
						<ModalHeader>
							<VStack>
								<Heading
									size="md"
									fontWeight="semibold"
								>
									Artikelübersicht
								</Heading>
								<HStack>
									<SegmentedControl
										options={[
											{ value: "current-stock", label: "Bestand" },
											{ value: "vendor-articles", label: "Lieferanten" },
											{ value: "stock-history", label: "Historie" },
										]}
										value={currentView}
										onChange={setCurrentView}
									/>
									<Spacer />
									<HStack>
										<div ref={headerRef} />
										<WhiteIconButton
											onClick={editArticleModal.onOpen}
											aria-label="edit"
											icon={<IoPencil />}
											size="sm"
											text="Artikel bearbeiten"
										/>
									</HStack>
								</HStack>
							</VStack>
						</ModalHeader>
						<ModalBody>
							{articleId !== undefined && (
								<Content
									articleId={articleId}
									headerRef={headerRef}
								/>
							)}
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button onClick={onClose}>Fertig</Button>
							</ModalButtonGroup>
						</ModalFooter>
						{article && (
							<EditArticleModal
								isOpen={editArticleModal.isOpen}
								onClose={editArticleModal.onClose}
								prefetchedArticle={article}
							/>
						)}
					</ModalContent>
				</Modal>
			</ArticleStockModalContext.Provider>
		</>
	);
};

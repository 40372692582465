import { yupResolver } from "@hookform/resolvers/yup";
import type { GenerateLoadCarrierForm } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import { LoadCarrierForm, loadCarrierSchema } from "components/forms";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddLoadCarrierModalProps {
	isOpen: boolean;
	onClose: () => void;
	defaultType: GenerateLoadCarrierForm["type"];
}

export const AddLoadCarrierModal: FC<AddLoadCarrierModalProps> = ({ isOpen, onClose, defaultType }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** React-Hook-Form */
	const resolver = yupResolver(loadCarrierSchema);
	const methods = useForm<GenerateLoadCarrierForm>({
		resolver,
		reValidateMode: "onChange",
		defaultValues: {
			type: defaultType,
			numberOfLoadCarriers: 1,
		},
	});

	/** Functions */
	async function onSubmit() {
		try {
			setIsLoading(true);
			const values = await methods.getValues();
			const isValid = await methods.trigger();
			if (!isValid) throw new Error("Formular ist nicht valide");
			toast.promise(ax.post("/v2/stock/load-carrier", { data: { values } }), {
				success: () => {
					handleClose();
					setIsLoading(false);
					return "Ladungsträger erfolgreich hinzugefügt";
				},
				error: () => {
					setIsLoading(false);
					return "Fehler beim Hinzufügen des Ladungsträgers";
				},
				loading: "Ladungsträger wird hinzugefügt",
			});
		} catch (error) {
			console.error(error);
			toast.error((error as any).message);
			setIsLoading(false);
		}
	}

	function handleClose() {
		methods.reset({});
		setIsLoading(false);
		onClose();
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalHeader>Ladungsträger hinzufügen</ModalHeader>
					<ModalBody>
						<LoadCarrierForm />
					</ModalBody>
					<ModalFooter>
						<ModalButtonGroup>
							<Button
								variant="ghost"
								onClick={handleClose}
							>
								abbrechen
							</Button>
							<Button
								type="submit"
								isLoading={isLoading}
								onClick={onSubmit}
							>
								Hinzufügen
							</Button>
						</ModalButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</FormProvider>
	);
};

import type { ConstructionSite } from "@msuite/katana";
import { Text, VStack } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface AddressInformationProps {
	constructionSite: ConstructionSite;
}

export const AddressInformation: FC<AddressInformationProps> = ({ constructionSite }) => {
	/** Constants */
	const firstLine = constructionSite.strasse + " " + constructionSite.hausnummer;
	const secondLine = constructionSite.plz + " " + constructionSite.ort;

	/** Render */
	return (
		<VStack spacing={0}>
			<Text fontWeight="medium">{firstLine}</Text>
			<Text>{secondLine}</Text>
		</VStack>
	);
};

import type { ISuborder } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	StandardDataTable,
	useTableOptions,
} from "@msuite/picasso";
import type { FC } from "react";
import { columns } from "./table-config";

/** Props Interface */
interface SuborderDetailsModalProps {
	isOpen: boolean;
	onClose: () => void;
	suborder: ISuborder;
}

export const SuborderDetailsModal: FC<SuborderDetailsModalProps> = ({ isOpen, onClose, suborder }) => {
	/** Hooks */
	const tableOptions = useTableOptions();

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent minW="90rem">
				<ModalCloseButton />
				<ModalHeader>Suborder Details</ModalHeader>
				<ModalBody>
					<StandardDataTable
						{...tableOptions}
						data={suborder.articles}
						columns={columns}
						isLoading={false}
					/>
				</ModalBody>
				<ModalFooter>
					<ModalButtonGroup>
						<Button onClick={onClose}>Fertig</Button>
					</ModalButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

import type { ILoadCarrier, TLoadCarrierType } from "@msuite/katana";
import { db } from "core/_firebase";
import { collection, onSnapshot, orderBy, query, limit as queryLimit, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useLoadCarriers = ({ type, limit = 50 }: { type: TLoadCarrierType; limit: number }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loadCarriers, setLoadCarriers] = useState<ILoadCarrier[]>([]);

	/** Effects */
	useEffect(() => {
		function fetchLoadCarriers() {
			setIsLoading(true);
			const loadCarriersRef = collection(db, "_stock/maindata/loadCarriers");
			const q = query(loadCarriersRef, where("type", "==", type), orderBy("loadCarrierId"), queryLimit(100));
			return onSnapshot(q, (querySnapshot) => {
				if (querySnapshot.empty) {
					setLoadCarriers([]);
					setIsLoading(false);
					return;
				}
				const _loadCarriers = (querySnapshot?.docs ?? []).map((doc) => doc.data() as ILoadCarrier);
				setLoadCarriers(_loadCarriers);
				setIsLoading(false);
			});
		}

		const unsubscribe = fetchLoadCarriers();
		return () => unsubscribe?.();
	}, [limit, type]);

	/** Functions */

	return {
		isLoading,
		loadCarriers,
		setLoadCarriers,
	};
};

import { loadCarrierTypeToReadable, type TLoadCarrierType } from "@msuite/katana";
import { Input, Portal, SegmentedControl, StandardDataTable, useTableOptions } from "@msuite/picasso";
import { loadCarrierTypes } from "components/forms";
import { LoadCarriersContext } from "context/load-carriers";
import { type FC, useState } from "react";
import type { DataMaintenanceChildrenProps } from "../data-maintenance";
import { Header } from "./header";
import { columns } from "./table-config";
import { useLoadCarriers } from "./use-load-carriers";

/** Props Interface */
interface LoadCarriersProps extends DataMaintenanceChildrenProps {}

export const LoadCarriers: FC<LoadCarriersProps> = ({ headerRef, searchBarRef }) => {
	/** State */
	const [type, setType] = useState<TLoadCarrierType>("latticebox");
	const [limit, setLimit] = useState<number>(10);

	/** Hooks */
	const { loadCarriers, isLoading } = useLoadCarriers({ type, limit });
	const tableOptions = useTableOptions();

	/** Render */
	return (
		<LoadCarriersContext.Provider value={{ type, setType, limit, setLimit }}>
			<Header
				headerRef={headerRef}
				searchBarRef={searchBarRef}
			/>
			<Portal containerRef={searchBarRef}>
				<Input
					isSearch
					value={tableOptions.globalFilter}
					onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
				/>
			</Portal>
			<SegmentedControl
				value={type}
				onChange={setType}
				options={loadCarrierTypes.map((type) => ({
					label: loadCarrierTypeToReadable(type),
					value: type,
				}))}
			/>
			<StandardDataTable
				key={type}
				{...tableOptions}
				columns={columns}
				data={loadCarriers}
				isLoading={isLoading}
				autoResetPageIndex={false}
				autoResetAll={false}
			/>
		</LoadCarriersContext.Provider>
	);
};

import { moment } from "@msuite/katana";
import { createContext, useContext } from "react";

export type Workspaces = "all" | "menke" | "schröter" | "scan-rent";

/** Interface */
interface IHomeContext {
	activeWorkspace: Workspaces;
	setActiveWorkspace: (workspace: Workspaces) => void;
	startDate: string;
	setStartDate: (date: string) => void;
}

/** Context */
export const HomeContext = createContext<IHomeContext>({
	activeWorkspace: "menke",
	setActiveWorkspace: () => { },
	startDate: moment().format("YYYY-MM-DD"),
	setStartDate: () => { },
});

/** Hooks */
export const useHomeContext = () => {
	return useContext(HomeContext);
};

import type { IArticle } from "@msuite/katana";
import { HStack, Text, useDocument, useUIContext, VStack } from "@msuite/picasso";
import { db } from "core/_firebase";
import type { FC } from "react";

/** Props Interface */
interface ArticleCardReturnControlProps {
	articleId: string;
	onPress?: () => void;
	rightAppendix?: React.ReactNode;
}

export const ArticleCardReturnControl: FC<ArticleCardReturnControlProps> = ({ articleId, rightAppendix }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { data: article } = useDocument<IArticle>(db, {
		path: `_stock/maindata/articles/${articleId}`,
		subscribe: false,
	});

	/** Render */
	return (
		<HStack
			justify="space-between"
			backgroundColor={colors.whiteAdjusted}
			px={4}
			py={2}
			borderWidth={1}
			rounded="md"
		>
			<VStack spacing={0}>
				<Text
					fontSize="sm"
					fontWeight="medium"
					color="gray.500"
				>
					{articleId}
				</Text>
				<Text fontWeight="semibold">{article?.articleName_de}</Text>
			</VStack>
			{rightAppendix}
		</HStack>
	);
};

import { Grid, Text, useUIContext } from "@msuite/picasso";
import { storageLocationCardColumns } from "components/cards/storage-location-card";
import type { FC } from "react";

export const TableHeader: FC = () => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Grid
			templateColumns={storageLocationCardColumns}
			px="1rem"
			py="0.25rem"
			borderTopWidth={1}
			borderBottomWidth={1}
			overflowY="scroll"
			backgroundColor={colors.grayLight}
			fontWeight="semibold"
		>
			<Text fontSize="sm">Lagerplatz-ID</Text>
			<Text fontSize="sm">Kommissionierbereich</Text>
			<Text fontSize="sm">Gelagerter Artikel</Text>
			<div />
		</Grid>
	);
};

import type { IVendor } from "@msuite/katana";
import {
	Button,
	HStack,
	Input,
	Portal,
	StandardDataTable,
	useCollection,
	useDisclosure,
	useTableOptions,
} from "@msuite/picasso";
import { VendorsContext } from "context/vendors";
import { db } from "core/_firebase";
import { type FC, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { TbLock } from "react-icons/tb";
import type { DataMaintenanceChildrenProps } from "../data-maintenance";
import { AddVendorModal, TrustedModal } from "./modals";
import { columns } from "./table-config";

/** Props Interface */
interface VendorProps extends DataMaintenanceChildrenProps {}

export const Vendors: FC<VendorProps> = ({ headerRef, searchBarRef }) => {
	/** State */
	const [isTrusted, setIsTrusted] = useState<boolean>(false);

	/** Hooks */
	const trustedModal = useDisclosure();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const tableOptions = useTableOptions({
		defaultSorting: [{ id: "vendorName", desc: false }],
	});

	/** Collection */
	const { data: vendors } = useCollection<IVendor>(db, {
		path: "_stock/maindata/vendors",
		limit: 9999,
		subscribe: true,
	});

	/** Render */
	return (
		<VendorsContext.Provider value={{ isTrusted, setIsTrusted }}>
			<AddVendorModal
				isOpen={isOpen}
				onClose={onClose}
			/>
			<Portal containerRef={headerRef}>
				<HStack>
					<Button
						leftIcon={<TbLock />}
						onClick={trustedModal.onOpen}
					>
						Lieferanten Zugänge anzeigen
					</Button>
					<Button
						leftIcon={<IoAdd />}
						onClick={onOpen}
					>
						Lieferant hinzufügen
					</Button>
				</HStack>
			</Portal>
			<Portal containerRef={searchBarRef}>
				<Input
					isSearch
					value={tableOptions.globalFilter}
					onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
				/>
			</Portal>
			<StandardDataTable
				key="vendors"
				{...tableOptions}
				data={vendors}
				columns={columns}
				isLoading={false}
			/>
			<TrustedModal
				isOpen={trustedModal.isOpen}
				onClose={trustedModal.onClose}
				isTrusted={isTrusted}
				setIsTrusted={setIsTrusted}
			/>
		</VendorsContext.Provider>
	);
};

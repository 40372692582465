import type { IProductGroup, IProductType, IVendor } from "@msuite/katana";
import { Box, HStack, InnerSiteLayout, SegmentedControl, useCollection, useTitle } from "@msuite/picasso";
import { db } from "core/_firebase";
import { type FC, useRef, useState } from "react";
import { Articles } from "./articles";
import { ExactStorageLocations } from "./exact-storage-locations";
import { Header } from "./header";
import { LoadCarriers } from "./load-carriers";
import { ProductGroups } from "./product-groups";
import { ProductTypes } from "./product-types";
import { StorageLocations } from "./storage-locations";
import { StorageLocationsAssignment } from "./storage-locations-assignment";
import { TransferCarriers } from "./transfer-carriers";
import { Vendors } from "./vendors";

export interface DataMaintenanceChildrenProps {
	headerRef: React.RefObject<HTMLDivElement>;
	searchBarRef: React.RefObject<HTMLDivElement>;
}

export const DataMaintenance: FC = () => {
	/** State */
	const [currentView, setCurrentView] = useState<
		| "articles"
		| "productGroups"
		| "productTypes"
		| "storageLocations"
		| "exactStorageLocations"
		| "storageLocationsAssignment"
		| "vendors"
		| "load-carriers"
		| "transfer-carriers"
	>("articles");

	/** Hooks */
	const headerRef = useRef<HTMLDivElement>(null);
	const searchBarRef = useRef<HTMLDivElement>(null);
	useTitle("Bestandsverwaltung");

	/** Prefetch */
	useCollection<IVendor>(db, {
		path: "_stock/maindata/vendors",
	});
	useCollection<IProductGroup>(db, {
		path: "_stock/maindata/productGroups",
		subscribe: false,
	});
	useCollection<IProductType>(db, {
		path: "_stock/maindata/productTypes",
		subscribe: false,
	});

	/** Render */
	return (
		<InnerSiteLayout>
			<Header />
			<div ref={searchBarRef} />
			<HStack justifyContent="space-between">
				<SegmentedControl
					value={currentView}
					onChange={setCurrentView}
					options={[
						{ label: "Artikel", value: "articles" },
						{ label: "Produktgruppen", value: "productGroups" },
						{ label: "Produkttypen", value: "productTypes" },
						{ label: "Lagerbereiche", value: "storageLocations" },
						{ label: "Lagerorte", value: "exactStorageLocations" },
						{ label: "Lieferanten", value: "vendors" },
						{ label: "Ladungsträger", value: "load-carriers" },
						{ label: "Transferbehälter", value: "transfer-carriers" },
						{ label: "Lagerort-Verwaltung", value: "storageLocationsAssignment" },
					]}
				/>
				<Box ref={headerRef} />
			</HStack>
			{currentView === "articles" && (
				<Articles
					headerRef={headerRef}
					searchBarRef={searchBarRef}
				/>
			)}
			{currentView === "productGroups" && (
				<ProductGroups
					headerRef={headerRef}
					searchBarRef={searchBarRef}
				/>
			)}
			{currentView === "productTypes" && (
				<ProductTypes
					headerRef={headerRef}
					searchBarRef={searchBarRef}
				/>
			)}
			{currentView === "storageLocations" && (
				<StorageLocations
					headerRef={headerRef}
					searchBarRef={searchBarRef}
				/>
			)}
			{currentView === "exactStorageLocations" && (
				<ExactStorageLocations
					headerRef={headerRef}
					searchBarRef={searchBarRef}
				/>
			)}
			{currentView === "storageLocationsAssignment" && (
				<StorageLocationsAssignment
					headerRef={headerRef}
					searchBarRef={searchBarRef}
				/>
			)}
			{currentView === "vendors" && (
				<Vendors
					headerRef={headerRef}
					searchBarRef={searchBarRef}
				/>
			)}
			{currentView === "load-carriers" && (
				<LoadCarriers
					headerRef={headerRef}
					searchBarRef={searchBarRef}
				/>
			)}
			{currentView === "transfer-carriers" && (
				<TransferCarriers
					headerRef={headerRef}
					searchBarRef={searchBarRef}
				/>
			)}
		</InnerSiteLayout>
	);
};

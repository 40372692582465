import type { IVendor, IVendorArticle } from "@msuite/katana";
import {
	FormControl,
	FormHelperText,
	FormLabel,
	Input,
	Select,
	useCollection,
	useDocument,
	VStack,
} from "@msuite/picasso";
import { db } from "core/_firebase";
import type { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormError } from "./schema";

/**
 * vendorId: string;
 * vendorArticleId: string; // auto
 * internalArticleId: string;
 * purchasePrice: number;
 * purchasePriceWithDiscount: number;
 * specialDiscount?: number;
 * deliveryDays?: number;
 */
export const VendorArticleForm: FC = () => {
	/** Context */
	const methods = useFormContext<IVendorArticle>();
	const [vendorId] = useWatch({ control: methods.control, name: ["vendorId"] });

	/** Hooks */
	const { data: vendors } = useCollection<IVendor>(db, {
		path: "_stock/maindata/vendors",
		subscribe: true,
	});
	const { data: currentVendor } = useDocument<IVendor>(db, {
		path: `_stock/maindata/vendors/${vendorId}`,
	});

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Lieferant</FormLabel>
				<Select
					placeholder="Lieferant auswählen"
					{...methods.register("vendorId")}
				>
					{vendors?.map((vendor) => (
						<option
							key={vendor.vendorId}
							value={vendor.vendorId}
						>
							{vendor.vendorName}
						</option>
					))}
				</Select>
				<FormError name="vendorId" />
			</FormControl>
			<FormControl>
				<FormLabel>Hersteller Artikel ID</FormLabel>
				<Input
					{...methods.register("vendorArticleId")}
					placeholder="000-000-000"
				/>
				<FormError name="purchasePrice" />
			</FormControl>
			<FormControl>
				<FormLabel>Einkaufspreis</FormLabel>
				<Input
					type="number"
					{...methods.register("purchasePrice", { valueAsNumber: true })}
					placeholder="0"
					min={0}
					step={0.01}
				/>
				<FormError name="purchasePrice" />
			</FormControl>
			<FormControl>
				<FormLabel>Sonder-Rabatt</FormLabel>
				<Input
					type="number"
					{...methods.register("specialDiscount", { valueAsNumber: true })}
					placeholder="0"
					min={0}
					max={100}
					step={0.01}
				/>
				<FormHelperText>
					{`Bei 0 wird der Standardrabatt des Lieferanten (${(currentVendor?.regularDiscount ?? 0).toFixed(0)} %) verwendet.`}
				</FormHelperText>
				<FormError name="specialDiscount" />
			</FormControl>
			<FormControl>
				<FormLabel>Lieferzeit</FormLabel>
				<Input
					type="number"
					{...methods.register("deliveryDays", { valueAsNumber: true })}
					placeholder="0"
					min={0}
				/>
				<FormError name="deliveryDays" />
			</FormControl>
		</VStack>
	);
};

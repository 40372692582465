import { StandardDataTable, useTableOptions, VStack } from "@msuite/picasso";
import { useOrderDetailsModalContext } from "context/order-details-modal";
import type { FC } from "react";
import { columns } from "./table-config";

export const SpecialSizes: FC = () => {
	/** Context */
	const { specialSizes } = useOrderDetailsModalContext();

	/** Hooks */
	const tableOptions = useTableOptions();

	/** Render */
	return (
		<VStack>
			<StandardDataTable
				{...tableOptions}
				data={specialSizes?.articles ?? []}
				columns={columns}
				isLoading={false}
			/>
		</VStack>
	);
};

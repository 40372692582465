import type { IExactStorageLocation } from "@msuite/katana";
import { HStack } from "@msuite/picasso";
import { BarcodeModalTrigger } from "components/modals/barcode-modal/barcode-modal-trigger";
import { EditExactStorageLocationModalTrigger } from "components/storage-locations";
import type { FC } from "react";
import { CleanStorageLocationTrigger } from "./clean-storage-location-trigger";
import { useExactStorageLocationsContext } from "./context";

/** Props Interface */
interface RowActionsProps {
	storageLocation: IExactStorageLocation;
}

export const RowActions: FC<RowActionsProps> = ({ storageLocation }) => {
	const { selectedIds, setRowSelection } = useExactStorageLocationsContext();

	return (
		<HStack
			justify="flex-end"
			spacing={2}
		>
			<CleanStorageLocationTrigger storageLocation={storageLocation} />
			<BarcodeModalTrigger barcodeText={storageLocation.id} />
			<EditExactStorageLocationModalTrigger
				storageLocation={storageLocation}
				storageLocationIds={selectedIds}
				setRowSelection={setRowSelection}
			/>
		</HStack>
	);
};

import type { ConstructionSite, IEmptyLoadCarriersSuborder, ISuborder } from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface IOrderRowContext {
	suborders: (ISuborder & { id: string })[] | undefined;
	emptyLoadCarriersSuborders: (IEmptyLoadCarriersSuborder & { id: string })[] | undefined;
	constructionSite: ConstructionSite | undefined;
}

/** Context */
export const OrderRowContext = createContext<IOrderRowContext>({
	suborders: undefined,
	constructionSite: undefined,
	emptyLoadCarriersSuborders: undefined,
});

/** Hook */
export const useOrderRowContext = () => {
	return useContext(OrderRowContext);
};

import type { IExactStorageLocation, IStorageLocation } from "@msuite/katana";
import { Box, Grid, Text, useColorModeValue, useDocument } from "@msuite/picasso";
import { db } from "core/_firebase";
import { useInView } from "framer-motion";
import { type FC, useRef } from "react";
import { RowActions } from "sites/data-maintenance/exact-storage-locations/row-actions";
import { CurrentlyStoredArticle } from "./currently-stored-article";

export const storageLocationCardColumns = "2fr 2fr 3fr 2rem";

export interface StorageLocationCardProps {
	exactStorageLocation: IExactStorageLocation;
	isOverAndCanDrop?: boolean;
	isOverAndCantDrop?: boolean;
}

export const StorageLocationCard: FC<StorageLocationCardProps> = ({
	isOverAndCanDrop = false,
	isOverAndCantDrop = false,
	exactStorageLocation,
}) => {
	/** Hooks */
	const ref = useRef<HTMLDivElement>(null);
	const isInView = useInView(ref);

	const { data: exactStorageLocationSubscribed } = useDocument<IExactStorageLocation>(db, {
		path: `_stock/maindata/exactStorageLocations/${exactStorageLocation.id}`,
		subscribe: true,
		queryClientOptions: {
			enabled: !!isInView,
		},
	});

	const { data: storageLocation } = useDocument<IStorageLocation>(db, {
		path: `_stock/maindata/storageLocations/${exactStorageLocation.storageLocationId}`,
		subscribe: false,
		queryClientOptions: {
			enabled: !!(isInView && !!exactStorageLocation.storageLocationId),
		},
	});

	const canDropBackgroundColor = useColorModeValue("blue.50", "blue.900");
	const cantDropBackgroundColor = useColorModeValue("red.50", "red.900");

	/** Render */
	return (
		<Box
			ref={ref}
			cursor="pointer"
			padding="1rem"
			borderBottomWidth={1}
			backgroundColor={
				isOverAndCanDrop ? canDropBackgroundColor : isOverAndCantDrop ? cantDropBackgroundColor : "transparent"
			}
		>
			<Grid
				templateColumns={storageLocationCardColumns}
				alignItems="center"
			>
				<Text
					fontSize="sm"
					fontWeight="medium"
				>
					{exactStorageLocation?.id}
				</Text>
				<Text
					fontSize="sm"
					fontWeight="medium"
				>
					{storageLocation?.name}
				</Text>
				{isInView ? (
					<CurrentlyStoredArticle currentlyStoredArticleId={exactStorageLocationSubscribed?.currentlyStoredArticleId} />
				) : (
					<div />
				)}
				<RowActions storageLocation={exactStorageLocation} />
			</Grid>
		</Box>
	);
};

import type { IProductGroup, IProductType } from "@msuite/katana";
import { FormControl, FormLabel, Input, Select, useCollection } from "@msuite/picasso";
import { db } from "core/_firebase";
import { type FC, Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { FormError } from "./schema";

export const ProductTypeForm: FC = () => {
	/** Context */
	const methods = useFormContext<IProductType>();

	/** Hooks */
	const { data: productGroups } = useCollection<IProductGroup>(db, {
		path: "_stock/maindata/productGroups",
		subscribe: true,
	});

	/** Render */
	return (
		<Fragment>
			<FormControl>
				<FormLabel>Produktgruppe</FormLabel>
				<Select
					{...methods.register("productGroupId")}
					placeholder="Produktgruppe auswählen"
				>
					{productGroups?.map((productGroup) => (
						<option
							key={productGroup.id}
							value={productGroup.id}
						>
							{productGroup.id} - {productGroup.name}
						</option>
					))}
				</Select>
				<FormError name="productGroupId" />
			</FormControl>
			<FormControl>
				<FormLabel>ID</FormLabel>
				<Input
					{...methods.register("productTypeId")}
					placeholder="z.B.: 001"
				/>
				<FormError name="productTypeId" />
			</FormControl>
			<FormControl>
				<FormLabel>Name</FormLabel>
				<Input
					{...methods.register("name")}
					placeholder="z.B.: Bohlen"
				/>
				<FormError name="name" />
			</FormControl>
		</Fragment>
	);
};

import { createContext, useContext } from "react";

/** Interface */
interface IReturnControlContext {
	globalFilter: string;
	setGlobalFilter: (value: string) => void;
}

/** Context */
export const ReturnControlContext = createContext<IReturnControlContext>({
	globalFilter: "",
	setGlobalFilter: () => {},
});

/** Hook */
export const useReturnControlContext = () => {
	return useContext(ReturnControlContext);
};

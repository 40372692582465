import type { IProductGroup } from "@msuite/katana";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<IProductGroup>();

export const columns = [
	columnHelper.accessor("id", {
		header: "id",
		cell: (cell) => cell.getValue(),
	}),
	columnHelper.accessor("name", {
		header: "Name",
		cell: (cell) => cell.getValue(),
	}),
];

import { Grid, Text, useUIContext } from "@msuite/picasso";
import { articleCardColumns } from "components/cards";
import type { FC } from "react";

export const TableHeader: FC = () => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Grid
			templateColumns={articleCardColumns}
			px="1rem"
			py="0.25rem"
			borderTopWidth={1}
			borderBottomWidth={1}
			overflowY="scroll"
			backgroundColor={colors.grayLight}
			fontWeight="semibold"
		>
			<Text fontSize="sm">Artikelnummer</Text>
			<Text fontSize="sm">Produktgruppe</Text>
			<Text fontSize="sm">Kommissionierbereich</Text>
			<Text fontSize="sm">Artikelbezeichnung</Text>
			<div />
		</Grid>
	);
};

import type { IAddExactStorageLocationsModalForm, IExactStorageLocation } from "@msuite/katana";
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	VStack,
	handleOnReject,
	toast,
	useCollection,
} from "@msuite/picasso";
import { ax } from "core/_axios";
import { db } from "core/_firebase";
import { type FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

/** Props Interface */
interface AddExactStorageLocationsModalProps {
	isOpen: boolean;
	onClose: () => void;
}

const defaultValues: IAddExactStorageLocationsModalForm = {
	numberOfShelfs: 1,
	numberOfCompartments: 1,
	numberOfRows: 1,
};

function getValuesFromId(id: string) {
	const [numberOfShelfs, numberOfCompartments, numberOfRows] = id.split("-");
	return {
		numberOfShelfs: Number(removeLetters(numberOfShelfs)),
		numberOfCompartments: Number(removeLetters(numberOfCompartments)),
		numberOfRows: Number(removeLetters(numberOfRows)),
	};
}

function removeLetters(str: string) {
	return str.replace(/[a-zA-Z]/g, "");
}

export const AddExactStorageLocationsModal: FC<AddExactStorageLocationsModalProps> = ({ isOpen, onClose }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentForm, setCurrentForm] = useState<IAddExactStorageLocationsModalForm>(defaultValues);

	/** Hooks */
	const { data: lastExactStorageLocation } = useCollection<IExactStorageLocation>(db, {
		path: "_stock/maindata/exactStorageLocations",
		limit: 1,
		orderBy: "id",
		orderByDirection: "desc",
		subscribe: true,
	});
	const methods = useForm<IAddExactStorageLocationsModalForm>({
		defaultValues: {
			numberOfShelfs: 1,
			numberOfCompartments: 1,
			numberOfRows: 1,
		},
	});

	useEffect(() => {
		if (!isOpen) return;
		const document = lastExactStorageLocation.at(0);
		if (document) {
			const { numberOfShelfs, numberOfCompartments, numberOfRows } = getValuesFromId(document.id);
			methods.reset({
				numberOfShelfs,
				numberOfCompartments,
				numberOfRows,
			});
			setCurrentForm({
				numberOfShelfs,
				numberOfCompartments,
				numberOfRows,
			});
		}
	}, [lastExactStorageLocation, isOpen]);

	/** Functions */
	async function onSubmit(data: IAddExactStorageLocationsModalForm) {
		try {
			setIsLoading(true);
			await ax.post("/v2/stock/exact-storage-locations", { data });
			onClose();
		} catch (error) {
			console.error(error);
			toast.error("Etwas ist schief gelaufen");
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<form onSubmit={methods.handleSubmit(onSubmit, handleOnReject)}>
				<ModalContent>
					<ModalHeader>Lagerorte hinzufügen</ModalHeader>
					<ModalBody>
						<VStack>
							<FormControl>
								<FormLabel>Anzahl der Regale</FormLabel>
								<Input
									type="number"
									{...methods.register("numberOfShelfs")}
									min={currentForm.numberOfShelfs}
								/>
							</FormControl>
							<FormControl>
								<FormLabel>Anzahl der Fächer pro Regal</FormLabel>
								<Input
									type="number"
									{...methods.register("numberOfCompartments")}
									min={currentForm.numberOfCompartments}
								/>
							</FormControl>
							<FormControl>
								<FormLabel>Anzahl der Ebenen pro Regal</FormLabel>
								<Input
									type="number"
									{...methods.register("numberOfRows")}
									min={currentForm.numberOfRows}
								/>
							</FormControl>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<ModalButtonGroup>
							<Button
								variant="ghost"
								onClick={onClose}
							>
								abbrechen
							</Button>
							<Button
								type="submit"
								isLoading={isLoading}
							>
								Lagerorte hinzufügen
							</Button>
						</ModalButtonGroup>
					</ModalFooter>
				</ModalContent>
			</form>
		</Modal>
	);
};

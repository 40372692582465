import { yupResolver } from "@hookform/resolvers/yup";
import type { IProductType } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	VStack,
	handleOnReject,
	toast,
} from "@msuite/picasso";
import { ProductTypeForm } from "components/forms";
import { productTypeSchema } from "components/forms/product-type-form/schema";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddProductTypeModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AddProductTypeModal: FC<AddProductTypeModalProps> = ({ isOpen, onClose }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const methods = useForm<IProductType>({
		resolver: yupResolver(productTypeSchema),
	});

	/** Functions */
	async function handleOnSubmit(data: IProductType) {
		try {
			setIsLoading(true);
			await ax.post("/v2/stock/product-type", { data });
			onClose();
			methods.reset();
		} catch (error) {
			toast.error(
				`Produkttyp mit der ID ${data.productTypeId} ist in der Produktgruppe ${data.productGroupId} bereits vorhanden.`,
			);
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, handleOnReject)}>
					<ModalContent maxWidth="90rem">
						<ModalCloseButton />
						<ModalHeader>Produkttyp hinzufügen</ModalHeader>
						<ModalBody>
							<VStack>
								<ProductTypeForm />
							</VStack>
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={onClose}
								>
									abbrechen
								</Button>
								<Button
									isLoading={isLoading}
									type="submit"
								>
									Produkttyp hinzufügen
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};

import type { IArticle, ISuborder } from "@msuite/katana";
import { DocumentString } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { db } from "core/_firebase";

const columnHelper = createColumnHelper<ISuborder["articles"][number]>();

export const columns = [
	columnHelper.accessor("articleId", {
		header: "Artikel-ID",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "15%",
		},
	}),
	columnHelper.display({
		id: "articleName",
		header: "Artikelname",
		cell: (cell) => (
			<DocumentString<IArticle>
				path={`_stock/maindata/articles/${cell.row.original.articleId}`}
				db={db}
				fields={["articleName_de"]}
			/>
		),
		meta: {
			width: "30%",
		},
	}),
	columnHelper.display({
		id: "articleName",
		header: "Artikelname",
		cell: (cell) => (
			<DocumentString<IArticle>
				path={`_stock/maindata/articles/${cell.row.original.articleId}`}
				db={db}
				fields={["exactStorageLocationId"]}
			/>
		),
		meta: {
			width: "30%",
		},
	}),
	columnHelper.accessor("quantity", {
		header: "Anzahl",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "15%",
		},
	}),
];

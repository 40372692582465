import type { IReactTableState } from "@msuite/picasso";
import { createContext, useContext } from "react";

/** Interface */
interface ITableContext extends IReactTableState {}

/** Context */
export const TableContext = createContext<ITableContext>({} as ITableContext);

/** Hook */
export const useTableContext = () => {
	return useContext(TableContext);
};

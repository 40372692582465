import type { IVendor } from "@msuite/katana";
import {
	Divider,
	FormControl,
	FormLabel,
	HStack,
	Input,
	SegmentedControl,
	VStack,
	WhiteIconButton,
} from "@msuite/picasso";
import { useVendorsContext } from "context/vendors";
import { type FC, Fragment, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { TbPercentage, TbPlus } from "react-icons/tb";
import { FormError } from "./schema";
import { VendorAdressesForm } from "./vendor-addresses-form";
import { VendorContactsForm } from "./vendor-contacts-form";
import { VendorShopAuthentication } from "./vendor-shop-authentication";

export const VendorForm: FC = () => {
	/** State */
	const [currentView, setCurrentView] = useState<"addresses" | "contacts">("addresses");

	/** Context */
	const { isTrusted } = useVendorsContext();
	const { register, control } = useFormContext<IVendor>();
	const contactsArray = useFieldArray({ control, name: "contacts" });
	const addressesArray = useFieldArray({ control, name: "addresses" });

	/** Functions */
	function handleOnAdd() {
		if (currentView === "addresses") {
			addressesArray.append({ street: "", zipCode: "", city: "", number: "", type: "hq" });
		} else {
			contactsArray.append({
				type: "unknown",
				position: "ceo",
				firstName: "",
				lastName: "",
			});
		}
	}

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Name</FormLabel>
				<Input
					{...register("vendorName", { minLength: 1 })}
					placeholder="z.B.: Plettac"
				/>
				<FormError name="vendorName" />
			</FormControl>
			<FormControl>
				<FormLabel>Lieferanten-Rabatt</FormLabel>
				<Input
					type="number"
					min={0}
					max={100}
					step={0.1}
					{...register("regularDiscount", {
						min: 0,
						max: 100,
						valueAsNumber: true,
					})}
					placeholder="0"
					rightIcon={<TbPercentage />}
				/>
				<FormError name="regularDiscount" />
			</FormControl>
			<FormControl>
				<FormLabel>Web</FormLabel>
				<Input
					{...register("web")}
					placeholder="z.B.: https://www.plettac.de/"
				/>
				<FormError name="web" />
			</FormControl>
			<Divider />
			{isTrusted && (
				<Fragment>
					<VendorShopAuthentication />
					<Divider />
				</Fragment>
			)}
			<HStack
				justify="space-between"
				position="sticky"
				top="-0.48rem"
				zIndex={101}
				style={{
					backdropFilter: "blur(15px)",
				}}
			>
				<SegmentedControl
					options={[
						{ label: "Adressen", value: "addresses" },
						{ label: "Kontakte", value: "contacts" },
					]}
					value={currentView}
					onChange={(value) => setCurrentView(value as "addresses" | "contacts")}
				/>
				<WhiteIconButton
					size="xs"
					icon={<TbPlus />}
					aria-label="Add"
					onClick={handleOnAdd}
				/>
			</HStack>
			{currentView === "addresses" && <VendorAdressesForm addressesArray={addressesArray} />}
			{currentView === "contacts" && <VendorContactsForm contactsArray={contactsArray} />}
		</VStack>
	);
};

import type { createArticleSections, IArticle } from "@msuite/katana";
import { HStack, Text, VStack } from "@msuite/picasso";
import { ArticleCardReturnControl } from "components/cards/article-card-return-control";
import type { FC } from "react";
import { ReturnControlArticleOverview } from "./return-control-article-overview";

/** Props Interface */
interface ArticleTypeSectionProps {
	section: ReturnType<typeof createArticleSections>[0]["articleTypes"][0];
	firstArticle: IArticle | undefined;
}

export const ArticleTypeSection: FC<ArticleTypeSectionProps> = ({ section, firstArticle }) => {
	return (
		<VStack>
			<HStack
				width="100%"
				justify="space-between"
			>
				<Text fontWeight="medium">{firstArticle?.articleType}</Text>
				<Text fontWeight="medium">{section.articleIds.length}</Text>
			</HStack>
			<VStack>
				{section.articleIds.map((articleId) => (
					<ArticleCardReturnControl
						articleId={articleId}
						key={articleId}
						rightAppendix={<ReturnControlArticleOverview articleId={articleId} />}
					/>
				))}
			</VStack>
		</VStack>
	);
};

import { type IArticle, getArticleMeasureString } from "@msuite/katana";
import { Text, VStack, useDocument } from "@msuite/picasso";
import { db } from "core/_firebase";
import type { FC } from "react";

/** Props Interface */
interface CurrentlyStoredArticleProps {
	currentlyStoredArticleId: string | undefined;
}

export const CurrentlyStoredArticle: FC<CurrentlyStoredArticleProps> = ({ currentlyStoredArticleId }) => {
	/** Hooks */
	const { data: article } = useDocument<IArticle>(db, {
		path: `_stock/maindata/articles/${currentlyStoredArticleId}`,
		subscribe: false,
		queryClientOptions: {
			enabled: !!currentlyStoredArticleId,
		},
	});

	/** Render */
	return (
		<VStack spacing={0}>
			{article !== undefined ? (
				<>
					<Text fontSize="sm">{article?.articleId}</Text>
					<Text
						fontSize="sm"
						fontWeight="semibold"
						noOfLines={1}
					>
						{article?.articleName_de}
					</Text>
					<Text
						fontSize="sm"
						color="gray.500"
					>
						{getArticleMeasureString(article)}
					</Text>
				</>
			) : (
				<>
					<Text
						fontSize="sm"
						userSelect="none"
						visibility="hidden"
					>
						placeholder
					</Text>
					<Text
						fontSize="sm"
						fontWeight="semibold"
					>
						Kein Artikel verbunden
					</Text>
					<Text
						fontSize="sm"
						userSelect="none"
						visibility="hidden"
					>
						placeholder
					</Text>
				</>
			)}
		</VStack>
	);
};

import { Center } from "@msuite/picasso";
import type { FC } from "react";
import ReactBarcode from "react-barcode";
import { useFormContext, useWatch } from "react-hook-form";
import type { BarcodeOptions } from "../modals/barcode-modal";

/** Props Interface */
interface BarcodeProps {
	value: string;
	id?: string;
}

export const Barcode: FC<BarcodeProps> = ({ value, id }) => {
	/** Options */
	const { control } = useFormContext<BarcodeOptions>();
	const options = useWatch({ control });
	const workingValue = options.value ?? value;
	const { value: formValue, ...barcodeOptions } = options;

	/** Render */
	return (
		<Center
			backgroundColor={options.background}
			padding={4}
			rounded="lg"
			borderWidth={0.5}
			id="barcode-container"
		>
			<div id={id ?? `barcode-${workingValue}`}>
				<ReactBarcode
					value={workingValue}
					{...barcodeOptions}
				/>
			</div>
		</Center>
	);
};

import { ArticleCard, type ArticleCardProps } from "components/cards";
import type { FC } from "react";
import { useDrag } from "react-dnd";

export const ArticleCardWrapper: FC<ArticleCardProps> = ({ article }) => {
	/** Hooks */
	const [{ opacity, isDragging }, dragRef, dragPreview] = useDrag(
		() => ({
			type: "article",
			item: { article },
			collect: (monitor) => ({
				opacity: monitor.isDragging() ? 0.5 : 1,
				isDragging: monitor.isDragging(),
			}),
		}),
		[],
	);

	/** Render */
	return (
		<div
			style={{ opacity }}
			ref={dragRef}
		>
			<ArticleCard article={article} />
		</div>
	);
};

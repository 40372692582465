import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	toast,
	VStack,
} from "@msuite/picasso";
import { BarcodeForm } from "components/forms/barcode-form";
import download from "downloadjs";
import { toPng } from "html-to-image";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdFileDownload } from "react-icons/md";
import { Barcode } from "../../barcode/barcode";

/** Props Interface */
interface BarcodeModalProps {
	isOpen: boolean;
	onClose: () => void;
	value: string;
}

export interface BarcodeOptions {
	value: string;
	width: number; // 2
	height: number; // 100,
	displayValue: boolean;
	textMargin: number; // 2,
	fontSize: number; // 20,
	background: string; // "#ffffff",
	lineColor: string; // "#000000",
}

export const BarcodeModal: FC<BarcodeModalProps> = ({ isOpen, onClose, value }) => {
	/** State */
	const [downloadIsLoading, setDownloadIsLoading] = useState<boolean>(false);

	/** Hooks */
	const methods = useForm<BarcodeOptions>({
		defaultValues: {
			value,
			width: 2,
			height: 100,
			displayValue: false,
			textMargin: 2,
			fontSize: 20,
			background: "#ffffff",
			lineColor: "#000000",
		},
	});

	async function handleDownloadImage() {
		try {
			setDownloadIsLoading(true);
			const value = methods.getValues("value");
			const barcode = document.getElementById(`barcode-${value}`);
			if (!barcode) throw new Error("Barcode not found");
			const png = await toPng(barcode);
			download(png, `${value}.png`);
			toast.success("Barcode wurde heruntergeladen.");
		} catch (error) {
			toast.error("Barcode konnte nicht heruntergeladen werden.");
		} finally {
			setDownloadIsLoading(false);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalHeader>Barcode generieren</ModalHeader>
					<ModalBody>
						<VStack>
							<Barcode value={value} />
							<Button
								alignSelf="center"
								variant="link"
								leftIcon={<MdFileDownload />}
								onClick={handleDownloadImage}
								width="max-content"
							>
								Download
							</Button>
							<BarcodeForm />
						</VStack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</FormProvider>
	);
};

import { type IArticle, getMeasuresStrings } from "@msuite/katana";
import { HStack, Heading, IconButton, ImagePopover, Text, Tooltip, VStack, useDisclosure } from "@msuite/picasso";
import { InventorizeArticleModal } from "components/modals/inventorize-article-modal/inventorize-article-modal";
import { type FC, Fragment } from "react";
import { TbNumbers } from "react-icons/tb";

interface RowActionsProps {
	article: IArticle;
}

export const RowActions: FC<RowActionsProps> = ({ article }) => {
	/** Hooks */
	const inventorizeArticleModal = useDisclosure();

	/** Render */
	return (
		<Fragment>
			<HStack
				spacing={2}
				justifyContent="flex-end"
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<ImagePopover
					imageUrl={article.imageUrl}
					header={
						<VStack
							spacing={0}
							mb={4}
						>
							<HStack justify="space-between">
								<Heading size="sm">{article.articleName_de}</Heading>
								<Text>{article.articleId}</Text>
							</HStack>
							<Text>
								{getMeasuresStrings(article, "de")?.at(1)} ({getMeasuresStrings(article, "de")?.at(0)})
							</Text>
						</VStack>
					}
				/>
				<Tooltip label="Inventur">
					<IconButton
						icon={<TbNumbers />}
						aria-label="Inventur"
						size="xs"
						onClick={inventorizeArticleModal.onOpen}
					/>
				</Tooltip>
			</HStack>
			{inventorizeArticleModal.isOpen && (
				<InventorizeArticleModal
					isOpen={inventorizeArticleModal.isOpen}
					onClose={inventorizeArticleModal.onClose}
					article={article}
				/>
			)}
		</Fragment>
	);
};

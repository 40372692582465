import { type Employee, type ILoadCarrier, moment } from "@msuite/katana";
import { DocumentString } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { db } from "core/_firebase";
import { RowActions } from "./row-actions";

const columnHelper = createColumnHelper<ILoadCarrier>();

export const columns = [
	columnHelper.accessor("loadCarrierId", {
		header: "Ladungsträger-ID",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "20%",
		},
	}),
	columnHelper.accessor("updatedAt", {
		header: "Zuletzt aktualisiert",
		cell: (cell) => moment(cell.getValue()).format("DD.MM.YYYY - HH:mm [Uhr]"),
		meta: {
			width: "20%",
		},
	}),
	columnHelper.accessor("updatedBy", {
		header: "Zuletzt aktualisiert von",
		cell: (cell) => (
			<DocumentString<Employee>
				db={db}
				fields={["vorname", "nachname"]}
				path={`mitarbeiter/${cell.getValue()}`}
			/>
		),
		meta: {
			width: "20%",
		},
	}),
	columnHelper.display({
		id: "actions",
		header: "In Use",
		cell: (cell) => (cell.row.original.currentConstructionSiteId ? "Ja" : "Nein"),
		meta: {
			width: "20%",
		},
	}),
	columnHelper.display({
		id: "actions",
		header: "",
		cell: (cell) => <RowActions loadCarrier={cell.row.original} />,
		meta: {
			width: "20%",
			isNumeric: true,
		},
	}),
];

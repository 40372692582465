import type { IVendor } from "@msuite/katana";
import {
	Box,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Text,
	VStack,
	WhiteIconButton,
	useUIContext,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { type UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { TbTrash } from "react-icons/tb";

/** Props Interface */
interface VendorAdressesFormProps {
	addressesArray: UseFieldArrayReturn<IVendor, "addresses", "id">;
}

export const VendorAdressesForm: FC<VendorAdressesFormProps> = ({ addressesArray }) => {
	/** Context */
	const { colors } = useUIContext();
	const methods = useFormContext<IVendor>();

	/** Render */
	return (
		<Fragment>
			{!addressesArray.fields.length && <Text>Es wurden noch keine Adressen hinzugefügt</Text>}
			{addressesArray.fields.map((field, index) => {
				const isLast = index === addressesArray.fields.length - 1;
				return (
					<VStack
						key={field.id}
						sx={{
							paddingBottom: !isLast ? "2rem" : undefined,
							borderBottomWidth: !isLast ? "1px" : undefined,
						}}
					>
						<FormControl>
							<FormLabel>Typ</FormLabel>
							<HStack spacing={6}>
								<Select {...methods.register(`addresses.${index}.type`)}>
									<option value="hq">Hauptsitz</option>
									<option value="branch">Außenstelle</option>
								</Select>
								<WhiteIconButton
									icon={
										<Box color={colors.red}>
											<TbTrash />
										</Box>
									}
									onClick={() => addressesArray.remove(index)}
									aria-label="Delete address"
								/>
							</HStack>
						</FormControl>
						<Grid
							templateColumns="1.5fr 1fr"
							gap={6}
						>
							<FormControl>
								<FormLabel>Straße</FormLabel>
								<Input
									{...methods.register(`addresses.${index}.street`)}
									placeholder="z.B.: Roedernallee"
								/>
							</FormControl>
							<FormControl>
								<FormLabel>Hausummer</FormLabel>
								<Input
									{...methods.register(`addresses.${index}.number`)}
									placeholder="z.B.: 13a"
								/>
							</FormControl>
						</Grid>
						<Grid
							templateColumns="1fr 1.5fr"
							gap={6}
						>
							<FormControl>
								<FormLabel>PLZ</FormLabel>
								<Input
									{...methods.register(`addresses.${index}.zipCode`)}
									placeholder="z.B.: 13407"
								/>
							</FormControl>
							<FormControl>
								<FormLabel>Ort</FormLabel>
								<Input
									{...methods.register(`addresses.${index}.city`)}
									placeholder="z.B.: Berlin"
								/>
							</FormControl>
						</Grid>
						<FormControl>
							<FormLabel>E-Mail</FormLabel>
							<Input
								{...methods.register(`addresses.${index}.email`)}
								placeholder="z.B.: max@mustermann.de"
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Telefon</FormLabel>
							<Input
								{...methods.register(`addresses.${index}.phone`)}
								placeholder="z.B.: 0176 20 22 63 60"
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Fax</FormLabel>
							<Input
								{...methods.register(`addresses.${index}.fax`)}
								placeholder="z.B.: 030 / 490 05 06"
							/>
						</FormControl>
					</VStack>
				);
			})}
		</Fragment>
	);
};

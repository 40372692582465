import type { StockFileUploadResult } from "@msuite/katana";
import {
	Box,
	Center,
	Collapse,
	FormControl,
	FormLabel,
	HStack,
	IconButton,
	Label,
	Spacer,
	Text,
	VStack,
	toast,
	useDisclosure,
	useUIContext,
} from "@msuite/picasso";
import { type FC, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { TbChevronRight, TbTrash } from "react-icons/tb";
import { centerizeImage } from "./handlers";

/** Props Interface */
interface FileUploaderProps {
	label: string;
	onChange: (result: StockFileUploadResult) => void;
}

export const FileUploader: FC<FileUploaderProps> = ({ label, onChange }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [centerResult, setCenterResult] = useState<StockFileUploadResult | undefined>(undefined);

	/** Hooks */
	const preview = useDisclosure();

	/** Context */
	const { colors } = useUIContext();
	const { getRootProps, getInputProps, isDragAccept } = useDropzone({
		onDrop,
		multiple: false,
		disabled: isLoading,
		accept: {
			"image/png": [],
			"image/jpeg": [],
		},
	});

	/** Functions */
	async function onDrop(acceptedFiles: File[]) {
		try {
			setIsLoading(true);
			const file: File = acceptedFiles[0];
			if (!fileIsImage(file)) {
				alert("Bitte nur Bilder hochladen.");
				return;
			}
			const centeredFile = await centerizeImage(file);
			if (!centeredFile) throw new Error("Could not centerize image.");
			const dataUrl = URL.createObjectURL(centeredFile);
			setCenterResult({ file: centeredFile, dataUrl });
		} catch (error) {
			console.error(error);
			toast.error("Bild konnte nicht bearbeitet werden.");
		} finally {
			setIsLoading(false);
		}
	}

	function fileIsImage(file: File | undefined) {
		if (!file) return false;
		return file.type === "image/png" || file.type === "image/jpeg";
	}

	useEffect(() => {
		if (centerResult) {
			toast.success("Bild erfolgreich bearbeitet.");
			onChange(centerResult);
		}
	}, [centerResult]);

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>{label}</FormLabel>
				<Center
					width="100%"
					padding={14}
					backgroundColor={colors.white}
					rounded="xl"
					borderWidth={1}
					cursor="pointer"
					{...getRootProps()}
					borderColor={isDragAccept ? "brand.500" : undefined}
					_hover={{
						borderColor: "brand.500",
					}}
				>
					<input {...getInputProps()} />
					<Text>{isLoading ? "Bild wird bearbeitet..." : "Datei auswählen oder hier ablegen"}</Text>
				</Center>
			</FormControl>
			{centerResult?.dataUrl && (
				<VStack spacing={0}>
					<HStack
						cursor="pointer"
						onClick={preview.onToggle}
						spacing={2}
					>
						<IconButton
							icon={<TbChevronRight />}
							style={{
								transform: preview.isOpen ? "rotate(90deg)" : undefined,
								transition: "transform 0.2s ease-in-out",
							}}
							size="xs"
							variant="ghost"
							aria-label="chevron"
						/>
						<Label>Bildvorschau</Label>
						<Spacer />
						<IconButton
							icon={<TbTrash />}
							colorScheme="red"
							size="xs"
							variant="ghost"
							aria-label="chevron"
							onClick={() => {
								setCenterResult(undefined);
								preview.onClose();
							}}
						/>
					</HStack>
					<Collapse in={preview.isOpen}>
						<Box
							borderWidth={1}
							rounded="xl"
							overflow="hidden"
						>
							<img
								src={centerResult.dataUrl}
								style={{ width: "100%", aspectRatio: "1", objectFit: "contain" }}
								alt="Preview"
							/>
						</Box>
					</Collapse>
				</VStack>
			)}
		</VStack>
	);
};

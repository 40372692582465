import type { IStorageLocation } from "@msuite/katana";
import { HStack, IconButton, useDisclosure } from "@msuite/picasso";
import { BarcodeModalTrigger } from "components/modals";
import { EditStorageLocationModal } from "components/modals/edit-storage-location-modal";
import { type FC, Fragment } from "react";
import { TbEdit } from "react-icons/tb";

/** Props Interface */
interface RowActionsProps {
	storageLocation: IStorageLocation;
}

export const RowActions: FC<RowActionsProps> = ({ storageLocation }) => {
	/** Hooks */
	const editStorageLocationModal = useDisclosure();

	/** Render */
	return (
		<Fragment>
			<HStack justifyContent="flex-end">
				<BarcodeModalTrigger barcodeText={storageLocation.id} />
				<IconButton
					icon={<TbEdit />}
					aria-label="Edit storage location"
					size="xs"
					onClick={editStorageLocationModal.onOpen}
				/>
			</HStack>
			{editStorageLocationModal.isOpen && storageLocation.id && (
				<EditStorageLocationModal
					isOpen={editStorageLocationModal.isOpen}
					onClose={editStorageLocationModal.onClose}
					storageLocationId={storageLocation.id}
				/>
			)}
		</Fragment>
	);
};

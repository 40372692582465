import { yupResolver } from "@hookform/resolvers/yup";
import type { IStorageLocation } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import { StorageLocationForm } from "components/forms";
import { storageLocationFormSchema } from "components/forms/storage-location-form/schema";
import { db } from "core/_firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface EditStorageLocationModalProps {
	isOpen: boolean;
	onClose: () => void;
	storageLocationId: string;
}

export const EditStorageLocationModal: FC<EditStorageLocationModalProps> = ({ isOpen, onClose, storageLocationId }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const storageLocationRef = doc(db, `_stock/maindata/storageLocations/${storageLocationId}`);
	const methods = useForm<IStorageLocation>({
		defaultValues: async () => {
			const storageLocation = (await getDoc(storageLocationRef)).data() as IStorageLocation;
			storageLocation.id = storageLocationRef.id;
			return storageLocation;
		},
		resolver: yupResolver(storageLocationFormSchema),
	});

	/** Functions */
	async function onSubmit(data: IStorageLocation) {
		try {
			setIsLoading(true);
			await updateDoc(storageLocationRef, { ...data });
			onClose();
		} catch (error) {
			console.error(error);
			toast.error("Etwas ist schief gelaufen");
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Lagerbereich bearbeiten</ModalHeader>
						<ModalBody>
							<StorageLocationForm />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={onClose}
								>
									abbrechen
								</Button>
								<Button
									isLoading={isLoading}
									type="submit"
								>
									Aktualisieren
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};

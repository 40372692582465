import type { IVendorArticle } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as zod from "zod";

export const FormError = FormErrorMessage<IVendorArticle>;

export const vendorArticleSchema = zod.object({
	vendorId: zod.string().min(1, "Bitte wählen Sie einen Lieferanten aus"),
	vendorArticleId: zod.string().nullish(),
	internalArticleId: zod.string().nullish(),
	purchasePrice: zod
		.number({ invalid_type_error: "Wert muss eine Zahl sein." })
		.min(0.01, "Bitte geben Sie einen Preis ein")
		.default(0),
	purchasePriceWithDiscount: zod.number().nullish(),
	specialDiscount: zod.number({ invalid_type_error: "Wert muss eine Zahl sein." }).min(0).default(0),
	deliveryDays: zod.number({ invalid_type_error: "Wert muss eine Zahl sein." }).min(0).default(0),
});

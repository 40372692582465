import { Button, HStack, Portal, toast, useDisclosure } from "@msuite/picasso";
import { AddArticleModal } from "components/modals/add-article-modal";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { IoAdd, IoList } from "react-icons/io5";
import type { DataMaintenanceChildrenProps } from "../data-maintenance";

/** Props Interface */
interface HeaderProps extends DataMaintenanceChildrenProps {}

export const Header: FC<HeaderProps> = ({ headerRef }) => {
	/** State */
	const [isGenerateIndexLoading, setIsGenerateIndexLoading] = useState<boolean>(false);

	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Function */
	async function handleOnGenerateIndex() {
		try {
			setIsGenerateIndexLoading(true);
			toast.promise(ax.post("/v2/stock/articles/index"), {
				loading: "Artikel Index wird erstellt...",
				success: () => {
					setIsGenerateIndexLoading(false);
					return "Artikel Index wurde erfolgreich erstellt.";
				},
				error: () => {
					setIsGenerateIndexLoading(false);
					return "Artikel Index konnte nicht erstellt werden.";
				},
			});
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<>
			<Portal containerRef={headerRef}>
				<HStack>
					<Button
						leftIcon={<IoList />}
						onClick={handleOnGenerateIndex}
						isLoading={isGenerateIndexLoading}
					>
						Artikel Index erstellen
					</Button>
					<Button
						leftIcon={<IoAdd />}
						onClick={onOpen}
					>
						Artikel hinzufügen
					</Button>
				</HStack>
			</Portal>
			{isOpen && (
				<AddArticleModal
					isOpen={isOpen}
					onClose={onClose}
				/>
			)}
		</>
	);
};

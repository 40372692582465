import type { ITransportOrder } from "@msuite/katana";
import { HStack, IconButton, toast, useDisclosure } from "@msuite/picasso";
import { db } from "core/_firebase";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { type FC, Fragment, useState } from "react";
import { TbArrowRight, TbX } from "react-icons/tb";
import { SelectVehicleModal } from "./select-vehicle-modal";

/** Props Interface */
interface RowActionProps {
	transportOrder: ITransportOrder;
}

export const RowAction: FC<RowActionProps> = ({ transportOrder }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const selectVehicleModal = useDisclosure();

	/** Functions */
	async function handleOnClick(vehicleId?: string) {
		try {
			setIsLoading(true);
			const transportOrderRef = doc(db, "_stock/maindata/transportOrders", transportOrder.id);

			if (transportOrder.destination === "load-on-truck" && !vehicleId) {
				toast.info("Bitte Fahrzeug auswählen");
				selectVehicleModal.onOpen();
				return;
			}
			await updateDoc(transportOrderRef, {
				status: transportOrder.status === "approved" ? "pending" : "approved",
				vehicleId: vehicleId || deleteField(),
			});
			if (transportOrder.status === "pending") {
				toast.success("Transportauftrag wurde zur Verladung freigegeben.");
			} else {
				toast.success("Freigabe wurde aufgehoben.");
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	async function onSelectVehicle(vehicleId: string) {
		try {
			await handleOnClick(vehicleId);
		} catch (error) {
			console.error(error);
		}
	}

	if (!["approved", "pending"].includes(transportOrder.status)) return null;

	/** Render */
	return (
		<Fragment>
			<HStack justify="flex-end">
				<IconButton
					size="sm"
					icon={transportOrder.status === "pending" ? <TbArrowRight /> : <TbX />}
					aria-label="Zuweisen"
					onClick={() => handleOnClick()}
					isLoading={isLoading}
				/>
			</HStack>
			<SelectVehicleModal
				isOpen={selectVehicleModal.isOpen}
				onClose={selectVehicleModal.onClose}
				onConfirm={onSelectVehicle}
			/>
		</Fragment>
	);
};

import type { IArticleStockHistoryItem } from "@msuite/katana";
import { Input, StandardDataTable, VStack, useCollection, useTableOptions } from "@msuite/picasso";
import { useArticleStockModalContext } from "context/article-stock-modal";
import { db } from "core/_firebase";
import type { FC } from "react";
import { columns } from "./table-config";

export const StockHistory: FC = () => {
	/** Hooks */
	const tableOptions = useTableOptions();

	/** Context */
	const { articleId } = useArticleStockModalContext();
	const { data: stockHistory } = useCollection<IArticleStockHistoryItem>(db, {
		path: `_stock/maindata/articles/${articleId}/stockHistory`,
		orderBy: "updatedAt",
		orderByDirection: "desc",
		subscribe: true,
	});

	/** Render */
	return (
		<VStack>
			<Input
				isSearch
				value={tableOptions.globalFilter}
				onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
			/>
			<StandardDataTable
				{...tableOptions}
				key={stockHistory.length}
				columns={columns}
				data={stockHistory}
				isLoading={false}
			/>
		</VStack>
	);
};

import { zodResolver } from "@hookform/resolvers/zod";
import type { IVendorArticle } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	handleOnReject,
	toast,
} from "@msuite/picasso";
import { VendorArticleForm } from "components/forms/vendor-article-form";
import { vendorArticleSchema } from "components/forms/vendor-article-form/schema";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface EditVendorArticleModalProps {
	isOpen: boolean;
	onClose: () => void;
	articleId: string;
	vendorArticle: IVendorArticle & { id: string };
}

export const EditVendorArticleModal: FC<EditVendorArticleModalProps> = ({
	isOpen,
	onClose,
	articleId,
	vendorArticle,
}) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const methods = useForm<IVendorArticle>({
		resolver: zodResolver(vendorArticleSchema),
		defaultValues: vendorArticle,
	});

	/** Functions */
	async function onSubmit(data: IVendorArticle) {
		try {
			setIsLoading(true);
			if (!articleId || articleId === "undefined") throw new Error("Artikel ID fehlt");
			await ax.patch(`/v2/stock/article/${articleId}/vendor-article/${vendorArticle.id}`, {
				data: { values: data },
			});
		} catch (error) {
			toast.error("Fehler beim Aktualisieren des Lieferantenartikels");
			console.error(error);
		} finally {
			handleOnClose();
			setIsLoading(false);
		}
	}

	function handleOnClose() {
		methods.reset();
		onClose();
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form
					onSubmit={methods.handleSubmit(onSubmit, handleOnReject)}
					name="vendorArticleForm"
				>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Lieferantenartikel bearbeiten</ModalHeader>
						<ModalBody>
							<VendorArticleForm />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									onClick={handleOnClose}
									variant="ghost"
								>
									abbrechen
								</Button>
								<Button
									type="submit"
									isLoading={isLoading}
								>
									Aktualisieren
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};

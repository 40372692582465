import type { IVendorArticle } from "@msuite/katana";
import { HStack, useDisclosure, WhiteIconButton } from "@msuite/picasso";
import { EditVendorArticleModal } from "components/modals";
import { type FC, Fragment } from "react";
import { IoPencil } from "react-icons/io5";

/** Props Interface */
interface RowActionsProps {
	vendorArticle: IVendorArticle & { id: string };
}

export const RowActions: FC<RowActionsProps> = ({ vendorArticle }) => {
	/** Hooks */
	const editVendorArticleModal = useDisclosure();

	/** Render */
	return (
		<Fragment>
			<HStack
				gap={2}
				justify="flex-end"
			>
				<WhiteIconButton
					icon={<IoPencil />}
					onClick={editVendorArticleModal.onOpen}
					size="xs"
					aria-label="edit"
				/>
			</HStack>
			{editVendorArticleModal.isOpen && (
				<EditVendorArticleModal
					isOpen={editVendorArticleModal.isOpen}
					onClose={editVendorArticleModal.onClose}
					articleId={vendorArticle.internalArticleId}
					vendorArticle={vendorArticle}
				/>
			)}
		</Fragment>
	);
};

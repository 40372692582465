import type { Vehicle } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Text,
	VStack,
	toast,
	useCollection,
} from "@msuite/picasso";
import { db } from "core/_firebase";
import { type FC, useState } from "react";
import { useForm } from "react-hook-form";

/** Props Interface */
interface SelectVehicleModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: (vehicleId: string) => Promise<void>;
}

interface SelectVehicleModalForm {
	vehicleId: string;
}

export const SelectVehicleModal: FC<SelectVehicleModalProps> = ({ isOpen, onClose, onConfirm }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const methods = useForm<SelectVehicleModalForm>();

	/** Hooks */
	const { data } = useCollection<Vehicle>(db, {
		path: "fahrzeuge",
		subscribe: true,
	});

	/** Function */
	async function handleOnSubmit(data: SelectVehicleModalForm) {
		try {
			setIsLoading(true);
			if (!data.vehicleId) throw new Error("No vehicle id found.");
			await onConfirm(data.vehicleId);
			onClose();
			methods.reset();
		} catch (error) {
			toast.error("Bitte Fahrzeug auswählen.");
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<form onSubmit={methods.handleSubmit(handleOnSubmit)}>
				<ModalContent>
					<ModalCloseButton />
					<ModalHeader>Fahrzeug auswählen</ModalHeader>
					<ModalBody>
						<VStack>
							<Text>Bitte wählen Sie das Fahrzeug aus, auf welches der Transportbehälter verladen werden soll.</Text>
							<Select
								placeholder="Fahrzeug auswählen"
								{...methods.register("vehicleId")}
							>
								{data.map((vehicle) => (
									<option
										key={vehicle.id}
										value={vehicle.id}
									>
										{vehicle.kennzeichen} / {vehicle.typ}
									</option>
								))}
							</Select>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<ModalButtonGroup>
							<Button
								variant="ghost"
								onClick={onClose}
							>
								abbrechen
							</Button>
							<Button
								isLoading={isLoading}
								type="submit"
							>
								Auswählen
							</Button>
						</ModalButtonGroup>
					</ModalFooter>
				</ModalContent>
			</form>
		</Modal>
	);
};

import { type IMaterialListEmptyLoadCarriers, loadCarrierTypeToReadable } from "@msuite/katana";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<IMaterialListEmptyLoadCarriers>();

export const columns = [
	columnHelper.accessor("loadCarrierType", {
		header: "Typ",
		cell: (cell) => loadCarrierTypeToReadable(cell.getValue()),
		meta: {
			width: "80%",
		},
	}),
	columnHelper.accessor("quantity", {
		header: "Anzahl",
		cell: (cell) => cell.getValue(),
		meta: {
			isNumeric: true,
			width: "20%",
		},
	}),
];

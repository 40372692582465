import type { IStorageLocation } from "@msuite/katana";
import { createColumnHelper } from "@tanstack/react-table";
import { RowActions } from "./row-actions";

const columnHelper = createColumnHelper<IStorageLocation>();

export const columns = [
	columnHelper.accessor("id", {
		header: "id",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "10%",
		},
	}),
	columnHelper.accessor("name", {
		header: "Name",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "25%",
		},
	}),
	columnHelper.display({
		id: "qr",
		header: "",
		cell: (cell) => <RowActions storageLocation={cell.row.original} />,
		meta: {
			isNumeric: true,
		},
	}),
];

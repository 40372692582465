import type { IExactStorageLocation } from "@msuite/katana";
import { IconButton, useDisclosure, type useTableOptions } from "@msuite/picasso";
import type { FC } from "react";
import { IoPencil } from "react-icons/io5";
import { EditExactStorageLocationModal } from "./edit-exact-storage-location-modal";

/** Props Interface */
interface EditExactStorageLocationModalTriggerProps {
	storageLocation: IExactStorageLocation;
	storageLocationIds: string[];
	setRowSelection: ReturnType<typeof useTableOptions>['setRowSelection'];
}

export const EditExactStorageLocationModalTrigger: FC<EditExactStorageLocationModalTriggerProps> = ({
	storageLocation,
	storageLocationIds,
	setRowSelection
}) => {
	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Render */
	return (
		<>
			<IconButton
				icon={<IoPencil />}
				aria-label="Edit"
				size="xs"
				onClick={onOpen}
			/>
			{isOpen && (
				<EditExactStorageLocationModal
					isOpen={isOpen}
					onClose={onClose}
					exactStorageLocation={storageLocation}
					storageLocationIds={storageLocationIds}
					setRowSelection={setRowSelection}
				/>
			)}
		</>
	);
};

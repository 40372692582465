import { useColorModeValue, useToken } from "@msuite/picasso";
import { type FC, useRef, useState } from "react";
import { useUpdateEffect } from "usehooks-ts";

/** Props Interface */
interface CurrentStockHandlerProps {
	currentStock: number;
}

export const CurrentStockHandler: FC<CurrentStockHandlerProps> = ({ currentStock }) => {
	const [prevValue, setPrevValue] = useState<number>(currentStock);

	/** Hooks */
	const ref = useRef<HTMLParagraphElement>(null);
	const lightGreen = useToken("colors", "green.100");
	const darkestGreen = useToken("colors", "green.900");
	const lightRed = useToken("colors", "red.100");
	const darkestRed = useToken("colors", "red.900");
	const green = useColorModeValue(lightGreen, darkestGreen);
	const red = useColorModeValue(lightRed, darkestRed);

	/** Effects */
	useUpdateEffect(() => {
		if (currentStock === prevValue) return;
		const increased = currentStock > prevValue;
		const tr = ref.current?.closest("tr");
		if (!tr) return;
		tr.style.transition = "background-color 0.5s ease";
		tr.style.backgroundColor = increased ? green : red;
		const timeout = setTimeout(() => {
			tr.style.backgroundColor = "";
		}, 500);
		setPrevValue(currentStock);
		return () => {
			clearTimeout(timeout);
		};
	}, [currentStock]);

	/** Render */
	return <p ref={ref}>{currentStock}</p>;
};

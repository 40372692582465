import axios, { type AxiosRequestConfig } from "axios";
import { getIdToken } from "firebase/auth";
import { __API_URL__ } from "./_env";
import { auth } from "./_firebase";

/** Interceptor */
async function axiosInterceptor(config: AxiosRequestConfig<any>) {
	const platform = "MenkeSuite";
	if (!auth.currentUser) return config;
	const Authorization = await getIdToken(auth.currentUser, false);
	if (!Authorization) return config;
	config.headers = { Authorization, platform };
	return config;
}

/** Initialize axios */
export const ax = axios.create({
	baseURL: __API_URL__,
});

ax.interceptors.request.use(axiosInterceptor);

export const axV2 = axios.create({
	baseURL: __API_URL__ + "/v2",
});

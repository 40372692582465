import type { IExactStorageLocation, IStorageLocation } from "@msuite/katana";
import { DocumentString } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { db } from "core/_firebase";
import { RowActions } from "./row-actions";

const columnHelper = createColumnHelper<IExactStorageLocation>();

export const columns = [
	columnHelper.accessor("id", {
		header: "ID",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "20%",
		},
	}),
	columnHelper.accessor("storageLocationId", {
		header: "Lagerbereich",
		cell: (cell) => (
			<DocumentString<IStorageLocation>
				db={db}
				fields={["name"]}
				path={`_stock/maindata/storageLocations/${cell.getValue()}`}
			/>
		),
		meta: {
			width: "35%",
		},
	}),
	columnHelper.accessor("currentlyStoredArticleId", {
		header: "Artikel",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "35%",
		},
	}),
	columnHelper.display({
		id: "actions",
		header: "",
		cell: (cell) => <RowActions storageLocation={cell.row.original} />,
		meta: {
			isNumeric: true,
			width: "10%",
		},
	}),
];

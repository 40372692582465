import type { IArticle } from "@msuite/katana";
import { Button, Portal, useDisclosure } from "@msuite/picasso";
import type React from "react";
import type { FC } from "react";
import { AddDependencyModal } from "../../add-dependency-modal";

/** Props Interface */
interface AddDependencyModalWrapperProps {
	headerRef: React.MutableRefObject<HTMLDivElement>;
	article: IArticle | undefined;
}

export const AddDependencyModalWrapper: FC<AddDependencyModalWrapperProps> = ({ headerRef, article }) => {
	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Render */
	return (
		<>
			{article !== undefined && (
				<AddDependencyModal
					isOpen={isOpen}
					onClose={onClose}
					rootArticle={article}
				/>
			)}
			<Portal containerRef={headerRef}>
				<Button onClick={onOpen}>Abhängigkeit hinzufügen</Button>
			</Portal>
		</>
	);
};

import type { ISuborder } from "@msuite/katana";
import { ButtonGroup, IconButton, toast, useDisclosure } from "@msuite/picasso";
import { useOrderDetailsModalContext } from "context/order-details-modal";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { IoArrowForward, IoEllipsisVertical } from "react-icons/io5";
import { SuborderDetailsModal } from "../../suborder-details-modal";

/** Props Interface */
interface RowActionsProps {
	suborder: ISuborder;
}

export const RowActions: FC<RowActionsProps> = ({ suborder }) => {
	/** Context */
	const { order, constructionSite } = useOrderDetailsModalContext();

	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Functions */
	async function handleOnChangeStatus() {
		try {
			setIsLoading(true);
			if (!suborder.storageLocationId) throw new Error("Storage location not found");
			if (!order) throw new Error("Order not found");
			const suborderPath = `baustellen/${constructionSite?.id}/orders/${order?.id}/suborders/${suborder.storageLocationId}`;
			await ax.patch(`/v2/stock/orders/${order.id}/picking/prepare?suborderPath=${suborderPath}`);
		} catch (error) {
			console.error(error);
			toast.error("Fehler beim Freigeben der Suborder");
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<>
			<SuborderDetailsModal
				isOpen={isOpen}
				onClose={onClose}
				suborder={suborder}
			/>
			<ButtonGroup>
				<IconButton
					icon={<IoArrowForward />}
					aria-label="Details"
					onClick={() => handleOnChangeStatus()}
					isLoading={isLoading}
					text="Freigeben"
					size="xs"
				/>
				<IconButton
					icon={<IoEllipsisVertical />}
					aria-label="Details"
					size="xs"
					onClick={onOpen}
				/>
			</ButtonGroup>
		</>
	);
};

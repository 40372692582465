import { zodResolver } from "@hookform/resolvers/zod";
import type { IVendor } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@msuite/picasso";
import { VendorForm } from "components/forms/vendor-form";
import { vendorSchema } from "components/forms/vendor-form/schema";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddVendorModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AddVendorModal: FC<AddVendorModalProps> = ({ isOpen, onClose }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** React-Hook-Form */
	const methods = useForm<IVendor>({
		resolver: zodResolver(vendorSchema),
		defaultValues: {
			vendorName: "",
			regularDiscount: 0,
			contacts: [],
			addresses: [],
		},
	});

	/** Functions */
	async function handleSubmit() {
		try {
			setIsLoading(true);
			const isValid = await methods.trigger();
			if (!isValid) throw new Error("Form is not valid");
			await ax.post("/v2/stock/vendor", { data: { values: methods.getValues() } });
			onClose();
			methods.reset();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleSubmit)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Lieferant hinzufügen</ModalHeader>
						<ModalBody>
							<VendorForm />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									colorScheme="gray"
									variant="ghost"
									onClick={onClose}
								>
									abbrechen
								</Button>
								<Button
									type="submit"
									isLoading={isLoading}
								>
									Hinzufügen
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};

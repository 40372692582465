import {
	type IArticleForm,
	type IProductGroup,
	type IProductType,
	type StockFileUploadResult,
	type SupportedLanguage,
	convertLangToLongString,
} from "@msuite/katana";
import {
	Divider,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	GridItem,
	Input,
	Select,
	Switch,
	Text,
	useCollection,
} from "@msuite/picasso";
import { FileUploader } from "components/file-uploader";
import { db } from "core/_firebase";
import { type FC, useEffect, useId, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormError } from "./schema";

const languages: SupportedLanguage[] = ["de", "en", "pl", "ro", "sk", "tr"];

interface ArticleFormProps {
	onChangeFileUpload: (result: StockFileUploadResult) => void;
}

export const ArticleForm: FC<ArticleFormProps> = ({ onChangeFileUpload }) => {
	/** Context */
	const { register, watch } = useFormContext<IArticleForm>();

	/** State */
	const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguage>("de");
	const [selectedProductGroup, setSelectedProductGroup] = useState<string | undefined>();

	/** Hooks */
	const renderId = useId();
	const { data: productGroups } = useCollection<IProductGroup>(db, {
		path: "_stock/maindata/productGroups",
		subscribe: false,
	});
	const { data: productTypes } = useCollection<IProductType>(db, {
		path: "_stock/maindata/productTypes",
		subscribe: false,
	});
	const filteredProductTypes = productTypes?.filter(
		(productType) => productType.productGroupId === selectedProductGroup,
	);

	/** Effects */
	useEffect(() => {
		if (!selectedProductGroup && productGroups.length) {
			setSelectedProductGroup(productGroups[0].id);
		}
	}, [productGroups, selectedProductGroup]);

	/** Render */
	return (
		<>
			<Grid
				templateColumns="repeat(2, 1fr)"
				gap={6}
				rowGap={0}
			>
				<FormControl>
					<FormLabel>Produktgruppe</FormLabel>
					<Select
						value={selectedProductGroup}
						onChange={(e) => setSelectedProductGroup(e.target.value)}
					>
						{productGroups?.map((productGroup) => (
							<option
								key={productGroup.id}
								value={productGroup.id}
							>
								{productGroup.id} - {productGroup.name}
							</option>
						))}
					</Select>
				</FormControl>
				<FormControl>
					<FormLabel>Produkttyp-ID</FormLabel>
					<Select
						{...register("productTypeId")}
						placeholder="Produkttyp auswählen"
						key={filteredProductTypes.length + "-" + renderId}
					>
						{filteredProductTypes?.map((productType) => (
							<option
								key={productType.id}
								value={productType.id}
							>
								{productType.id} - {productType.name}
							</option>
						))}
					</Select>
				</FormControl>
				<GridItem colSpan={2}>
					<FormError name="productTypeId" />
				</GridItem>
			</Grid>
			<FormControl>
				<FormLabel>Artikelbezeichnung</FormLabel>
				<Grid
					templateColumns="1fr 4fr"
					gap={6}
				>
					<Select
						value="de"
						isDisabled
					>
						{languages.map((language) => (
							<option
								key={language}
								value={language}
							>
								{convertLangToLongString(language)}
							</option>
						))}
					</Select>
					<Input
						{...register("articleName_de")}
						key={selectedLanguage}
						placeholder="z.B.: Kippstiftkuplung (Kippfinger)"
					/>
					<FormError name={"articleName_de"} />
				</Grid>
				<FormHelperText>Artikelbezeichnung in andere Sprachen werden automatisch übersetzt.</FormHelperText>
			</FormControl>
			<Grid
				templateColumns="repeat(4, 1fr)"
				gap={6}
			>
				<FormControl>
					<FormLabel>Länge (Meter)</FormLabel>
					<Input
						type="number"
						defaultValue={0}
						step={0.01}
						min={0}
						{...register("length", { valueAsNumber: true })}
						key={selectedLanguage}
						placeholder="0,00"
					/>
					<FormError name="length" />
				</FormControl>
				<FormControl>
					<FormLabel>Breite (Meter)</FormLabel>
					<Input
						type="number"
						defaultValue={0}
						step={0.01}
						min={0}
						{...register("width", { valueAsNumber: true })}
						key={selectedLanguage}
						placeholder="0,00"
					/>
					<FormError name="width" />
				</FormControl>
				<FormControl>
					<FormLabel>Höhe (Meter)</FormLabel>
					<Input
						type="number"
						defaultValue={0}
						step={0.01}
						min={0}
						{...register("height", { valueAsNumber: true })}
						key={selectedLanguage}
						placeholder="0,00"
					/>
					<FormError name="height" />
				</FormControl>
				<FormControl>
					<FormLabel>Zusatzinformation</FormLabel>
					<Input
						{...register("additionalSizeInfo")}
						placeholder="z.B.: Feldhöhe 2,00 m"
					/>
					<FormError name="additionalSizeInfo" />
				</FormControl>
			</Grid>
			<FormControl
				display="flex"
				alignItems="center"
				gap={4}
			>
				<Switch
					isChecked={watch("isCustomizable")}
					{...register("isCustomizable")}
				/>
				<Text>Artikel ist individualisierbar</Text>
			</FormControl>
			<FormControl
				display="flex"
				alignItems="center"
				gap={4}
			>
				<Switch
					isChecked={watch("isStoredOutside")}
					{...register("isStoredOutside")}
				/>
				<Text>Artikel wird im Außenbereich gelagert</Text>
			</FormControl>
			<Divider />
			<Grid
				templateColumns="repeat(4, 1fr)"
				gap={6}
			>
				<FormControl>
					<FormLabel>Aktueller Bestand</FormLabel>
					<Input
						defaultValue={0}
						type="number"
						{...register("currentStock", { valueAsNumber: true })}
						placeholder="0"
						step={1}
						min={0}
					/>
					<FormError name="currentStock" />
				</FormControl>
				<FormControl>
					<FormLabel>Mindestbestand</FormLabel>
					<Input
						type="number"
						defaultValue={0}
						{...register("minimumStock", { valueAsNumber: true })}
						placeholder="0"
						step={1}
						min={0}
					/>
					<FormError name="minimumStock" />
				</FormControl>
				<FormControl>
					<FormLabel>Meldebestand</FormLabel>
					<Input
						type="number"
						defaultValue={0}
						{...register("warningStock", { valueAsNumber: true })}
						placeholder="0"
						step={1}
						min={0}
					/>
					<FormError name="warningStock" />
				</FormControl>
				<FormControl>
					<FormLabel>Priorisierung (Optional)</FormLabel>
					<Select
						{...register("pickingPriorization", { valueAsNumber: true })}
						placeholder="Priorisierung auswählen"
						defaultValue={0}
					>
						<option value={0}>Keine Priorisierung</option>
						<option disabled />
						<option disabled>1 (Sehr Hoch) - 10 (Sehr Niedrig)</option>
						{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((priority) => (
							<option
								key={priority}
								value={priority}
							>
								{priority}
							</option>
						))}
					</Select>
					<FormError name="warningStock" />
				</FormControl>
			</Grid>
			<FileUploader
				label="Artikelbild"
				onChange={onChangeFileUpload}
			/>
		</>
	);
};

import type { IVendorArticle } from "@msuite/katana";
import {
	Input,
	Portal,
	StandardDataTable,
	VStack,
	WhiteIconButton,
	useCollection,
	useDisclosure,
	useTableOptions,
} from "@msuite/picasso";
import { AddVendorArticleModal } from "components/modals/add-vendor-article-modal";
import { db } from "core/_firebase";
import { type FC, Fragment, useId } from "react";
import { TbPlus } from "react-icons/tb";
import { columns } from "./table-config";

/** Props Interface */
interface VendorArticlesProps {
	articleId: string;
	headerRef: React.MutableRefObject<HTMLDivElement>;
}

export const VendorArticles: FC<VendorArticlesProps> = ({ articleId, headerRef }) => {
	/** Hooks */
	const renderId = useId();
	const addVendorArticleModal = useDisclosure();
	const tableOptions = useTableOptions();
	const { data: vendorArticles } = useCollection<IVendorArticle>(db, {
		path: `_stock/maindata/articles/${articleId}/vendorArticles`,
		subscribe: true,
	});

	/** Render */
	return (
		<Fragment>
			<VStack>
				<Input
					isSearch
					value={tableOptions.globalFilter}
					onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
				/>
				<StandardDataTable
					{...tableOptions}
					isLoading={false}
					columns={columns}
					data={vendorArticles}
					key={renderId}
				/>
			</VStack>
			<Portal containerRef={headerRef}>
				<WhiteIconButton
					size="sm"
					onClick={addVendorArticleModal.onOpen}
					icon={<TbPlus />}
					text="Lieferantenartikel hinzufügen"
					aria-label="add-vendor-article"
				/>
			</Portal>
			{addVendorArticleModal.isOpen && (
				<AddVendorArticleModal
					articleId={articleId}
					isOpen={addVendorArticleModal.isOpen}
					onClose={addVendorArticleModal.onClose}
				/>
			)}
		</Fragment>
	);
};

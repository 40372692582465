import type { useTableOptions } from "@msuite/picasso";
import { createContext, useContext } from "react";

/** Interface */
export interface IExactStorageLocations {
	selectedIds: string[];
	setRowSelection: ReturnType<typeof useTableOptions>["setRowSelection"];
}

/** Context */
export const ExactStorageLocationsContext = createContext<IExactStorageLocations>({} as IExactStorageLocations);

/** Hooks */
export const useExactStorageLocationsContext = () => {
	return useContext(ExactStorageLocationsContext);
};

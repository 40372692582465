import type { IArticle, IArticleDependency } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import { useQueryClient } from "@tanstack/react-query";
import { ArticleDependencyForm } from "components/forms/article-dependency-form/article-dependency-form";
import { db } from "core/_firebase";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddDependencyModalProps {
	isOpen: boolean;
	onClose: () => void;
	rootArticle: IArticle;
}

export const AddDependencyModal: FC<AddDependencyModalProps> = ({ isOpen, onClose, rootArticle }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const qc = useQueryClient();
	const articles = qc.getQueryData<Array<IArticle>>(["_stock/maindata/articles"]);
	const methods = useForm<IArticleDependency>({
		defaultValues: {
			dependencyFactor: 1,
		},
	});
	const { reset, getValues } = methods;

	/** Functions */
	async function handleAddDependency() {
		try {
			setIsLoading(true);
			const articleRef = doc(db, "_stock/maindata/articles", rootArticle.articleId);
			const values = getValues();
			toast.promise(
				async () => {
					if (!values.articleId) throw new Error("Kein Artikel ausgewählt.");
					if (rootArticle.dependingArticles?.some((a) => a.articleId === values.articleId))
						throw new Error("Abhängigkeit existiert bereits.");
					await updateDoc(articleRef, {
						dependingArticles: arrayUnion(values),
					});
				},
				{
					loading: "Abhängigkeit wird hinzugefügt...",
					success: "Abhängigkeit wurde hinzugefügt.",
					error: (error) => {
						return error.message;
					},
				},
			);
			onClose();
			reset();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent maxWidth="70rem">
					<ModalCloseButton />
					<ModalHeader>Abhängigkeit hinzufügen</ModalHeader>
					<ModalBody>
						<ArticleDependencyForm
							articles={articles}
							rootArticle={rootArticle}
						/>
					</ModalBody>
					<ModalFooter>
						<ModalButtonGroup>
							<Button
								variant="ghost"
								onClick={onClose}
							>
								abbrechen
							</Button>
							<Button
								onClick={handleAddDependency}
								isLoading={isLoading}
							>
								Speichern
							</Button>
						</ModalButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</FormProvider>
	);
};

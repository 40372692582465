import type { IGetConstructionSitesByWorkspaceApiResponse } from "@msuite/katana";
import {
	InnerSiteLayout,
	Input,
	SegmentedControl,
	StandardDataTable,
	toast,
	useApi,
	useTableOptions,
} from "@msuite/picasso";
import { ReturnControlContext } from "context/return-control";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { Header } from "./header";
import { ReturnControlModal } from "./modals";
import { columns } from "./table-config";

const workspaceSegmentedControlOptions = [
	{ label: "Menke", value: "menke" },
	{ label: "Schröter", value: "schröter" },
	{ label: "Scan-Rent", value: "scan-rent" },
];

export const ReturnControl: FC = () => {
	/** State */
	const [activeWorkspace, setActiveWorkspace] = useState<string>("menke");
	const [selectedConstructionSiteId, setSelectedConstructionSiteId] = useState<string | undefined>(undefined);

	/** Hooks */
	const DEFAULT_PAGE_SIZE = 50;
	const tableOptions = useTableOptions({
		defaultPageSize: DEFAULT_PAGE_SIZE,
	});
	const { data } = useApi<IGetConstructionSitesByWorkspaceApiResponse>(ax, {
		url: `/v2/construction-sites/${activeWorkspace}`,
		dependencies: ["constructionSites", "workspace", activeWorkspace],
	});

	/** Functions */
	function handleOnOpenConstructionSite(constructionSiteId: string | undefined) {
		if (!constructionSiteId) return toast.error("Keine Baustelle ausgewählt");
		setSelectedConstructionSiteId(constructionSiteId);
	}

	function handleOnCloseConstructionSite() {
		setSelectedConstructionSiteId(undefined);
	}

	/** Render */
	return (
		<ReturnControlContext.Provider
			value={{
				globalFilter: tableOptions.globalFilter,
				setGlobalFilter: tableOptions.setGlobalFilter,
			}}
		>
			<ReturnControlModal
				isOpen={!!selectedConstructionSiteId}
				onClose={handleOnCloseConstructionSite}
				selectedConstructionSiteId={selectedConstructionSiteId}
			/>
			<InnerSiteLayout>
				<Header />
				<Input
					isSearch
					value={tableOptions.globalFilter}
					onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
				/>
				<SegmentedControl
					options={workspaceSegmentedControlOptions}
					value={activeWorkspace}
					onChange={setActiveWorkspace}
				/>
				<StandardDataTable
					{...tableOptions}
					hidePagination={(data?.constructionSites?.length ?? 0) < DEFAULT_PAGE_SIZE}
					columns={columns}
					data={data?.constructionSites ?? []}
					isLoading={!data}
					onRowClick={(constructionSite) => {
						handleOnOpenConstructionSite(constructionSite.id);
					}}
				/>
			</InnerSiteLayout>
		</ReturnControlContext.Provider>
	);
};

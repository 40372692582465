import { type IArticle, type IArticleDependency, getArticleMeasureString } from "@msuite/katana";
import {
	Button,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	toast,
} from "@msuite/picasso";
import { ArticleDependencyForm } from "components/forms/article-dependency-form";
import { db } from "core/_firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface EditDependencyModalProps {
	isOpen: boolean;
	onClose: () => void;
	articleDependency: IArticleDependency;
	articles: IArticle[];
	rootArticle: IArticle | undefined;
}

export const EditDependencyModal: FC<EditDependencyModalProps> = ({
	onClose,
	isOpen,
	articleDependency,
	articles,
	rootArticle,
}) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Default */
	const methods = useForm<IArticleDependency>({
		defaultValues: articleDependency,
	});
	const selectedArticle = articles.find((article) => article.articleId === articleDependency.articleId);

	/** Functions */
	async function handleEditDependency() {
		try {
			setIsLoading(true);
			if (!rootArticle) throw new Error("Kein Artikel ausgewählt.");
			const rootArticleRef = doc(db, "_stock/maindata/articles", rootArticle?.articleId);
			toast.promise(
				async () => {
					const refetchedRootArticle = (await getDoc(rootArticleRef)).data() as IArticle;
					const dependencyIndex = refetchedRootArticle.dependingArticles?.findIndex(
						(a) => a.articleId === articleDependency.articleId,
					);
					if (dependencyIndex === undefined || dependencyIndex === -1) throw new Error("Abhängigkeit existiert nicht.");
					if (!refetchedRootArticle.dependingArticles) throw new Error("Abhängigkeit existiert nicht.");
					refetchedRootArticle.dependingArticles[dependencyIndex].dependencyFactor =
						methods.getValues().dependencyFactor;
					await updateDoc(rootArticleRef, {
						dependingArticles: refetchedRootArticle.dependingArticles,
					});
				},
				{
					loading: "Abhängigkeit wird bearbeitet...",
					success: () => {
						setIsLoading(true);
						return "Abhängigkeit wurde bearbeitet.";
					},
					error: (error) => {
						setIsLoading(false);
						return error.message ?? "Fehler beim Bearbeiten der Abhängigkeit.";
					},
				},
			);
			onClose();
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent maxWidth="70rem">
					<ModalCloseButton />
					<ModalHeader>Abhängigkeit bearbeiten</ModalHeader>
					<ModalBody>
						<FormControl mb={6}>
							<FormLabel>Artikel</FormLabel>
							<Text>
								{selectedArticle?.articleId} / {selectedArticle?.articleName_de} /{" "}
								{getArticleMeasureString(selectedArticle)}
							</Text>
						</FormControl>
						<ArticleDependencyForm
							articles={articles}
							rootArticle={rootArticle}
							isEdit
						/>
					</ModalBody>
					<ModalFooter>
						<ModalButtonGroup>
							<Button
								variant="ghost"
								onClick={onClose}
							>
								abbrechen
							</Button>
							<Button
								onClick={handleEditDependency}
								isLoading={isLoading}
							>
								Speichern
							</Button>
						</ModalButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</FormProvider>
	);
};

import { type IArticle, type IArticleDependency, convertFloatToSizeInformation } from "@msuite/katana";
import { DocumentString } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { db } from "core/_firebase";
import { RowActions } from "./row-actions";

const columnHelper = createColumnHelper<IArticleDependency>();

export const columns = [
	columnHelper.accessor("articleId", {
		header: "Artikel-ID",
		cell: (cell) => <p>{cell.row.original.articleId}</p>,
		meta: {
			width: "15%",
		},
	}),
	columnHelper.display({
		id: "articleProductGroup",
		header: "Produktgruppe",
		cell: (cell) => (
			<DocumentString<IArticle>
				db={db}
				path={`_stock/maindata/articles/${cell.row.original.articleId}`}
				fields={["articleProductGroup"]}
			/>
		),
		meta: {
			width: "15%",
		},
	}),
	columnHelper.display({
		id: "articleName_de",
		header: "Artikelname",
		cell: (cell) => (
			<DocumentString<IArticle>
				db={db}
				path={`_stock/maindata/articles/${cell.row.original.articleId}`}
				fields={["articleName_de"]}
			/>
		),
		meta: {
			width: "15%",
		},
	}),
	columnHelper.display({
		id: "height",
		header: "Höhe",
		cell: (cell) => (
			<DocumentString<IArticle>
				db={db}
				path={`_stock/maindata/articles/${cell.row.original.articleId}`}
				fields={["height"]}
				transformers={[{ field: "height", transformer: convertFloatToSizeInformation }]}
			/>
		),
		meta: {
			width: "10%",
		},
	}),
	columnHelper.display({
		id: "width",
		header: "Breite",
		cell: (cell) => (
			<DocumentString<IArticle>
				db={db}
				path={`_stock/maindata/articles/${cell.row.original.articleId}`}
				fields={["width"]}
				transformers={[{ field: "width", transformer: convertFloatToSizeInformation }]}
			/>
		),
		meta: {
			width: "10%",
		},
	}),
	columnHelper.display({
		id: "length",
		header: "Läge",
		cell: (cell) => (
			<DocumentString<IArticle>
				db={db}
				path={`_stock/maindata/articles/${cell.row.original.articleId}`}
				fields={["length"]}
				transformers={[{ field: "length", transformer: convertFloatToSizeInformation }]}
			/>
		),
		meta: {
			width: "10%",
		},
	}),
	columnHelper.accessor("dependencyFactor", {
		header: "Faktor",
		cell: (cell) => <p>{cell.row.original.dependencyFactor}</p>,
		meta: {
			isNumeric: true,
			width: "15%",
		},
	}),
	columnHelper.display({
		id: "actions",
		header: "",
		cell: (cell) => (
			<RowActions
				articleDependency={cell.row.original}
				index={cell.row.index}
			/>
		),
		meta: {
			width: "10%",
			isNumeric: true,
		},
	}),
];

import type { ConstructionSite, Employee, IOrder, OrderStatus } from "@msuite/katana";
import { useQueryClient } from "@tanstack/react-query";
import { db } from "core/_firebase";
import { collectionGroup, doc, getDoc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

/** Interface */
interface UseOrdersOptions {
	orderStatusQuery: Array<OrderStatus>;
}

export interface IFullInformationOrder extends IOrder {
	id: string;
	constructionSiteId: string;
	constructionSite: ConstructionSite;
	employee: Employee;
}

export type FullInformationOrder = IFullInformationOrder;

export type PrefetchOrder = Omit<IFullInformationOrder, "constructionSite">;

/**
 * This is a custom hook to manage collectiongroup fetching. It isn't used as the utility hook from @msuite/katana because
 * of the way the data is structured in the database. Also this hook should be as customizable as possible.
 */
export const useOrders = ({ orderStatusQuery = ["pending"] }: UseOrdersOptions) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [orders, setOrders] = useState<IFullInformationOrder[]>([]);

	/** Hooks */
	const qc = useQueryClient();

	async function createdParsedOrder(order: PrefetchOrder) {
		const parsedOrder = order as IFullInformationOrder;
		let cs = (await qc.getQueryData(["constructionSites", order.constructionSiteId])) as ConstructionSite | undefined;
		if (!cs) {
			cs = (await getDoc(doc(db, "baustellen", order.constructionSiteId))).data() as ConstructionSite;
		}
		cs.id = order.constructionSiteId;
		let employee = (await qc.getQueryData(["employees", order.createdBy])) as Employee | undefined;
		if (!employee) {
			employee = (await getDoc(doc(db, "mitarbeiter", order.createdBy))).data() as Employee;
		}
		parsedOrder.employee = employee;
		qc.setQueryData(["constructionSites", order.constructionSiteId], cs);
		parsedOrder.constructionSite = cs;
		return parsedOrder;
	}

	/** Effects */
	useEffect(() => {
		const subscribe = () => {
			setOrders([]);
			setIsLoading(true);
			const reference = collectionGroup(db, "orders");
			const q = query(reference, where("status", "in", orderStatusQuery), orderBy("createdAt", "desc"));
			return onSnapshot(q, async (snapshot) => {
				if (snapshot.empty) {
					setOrders([]);
				} else {
					const orders = snapshot.docs.map(
						(doc) =>
							({
								id: doc.id,
								...doc.data(),
								constructionSiteId: doc.ref.parent.parent?.id,
							}) as PrefetchOrder,
					);
					const promises = orders.map(createdParsedOrder);
					const parsedOrders = await Promise.all(promises);
					setOrders(parsedOrders);
				}
				setIsLoading(false);
			});
		};
		const unsubscribe = subscribe();
		return unsubscribe;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderStatusQuery]);

	/** Return */
	return { orders, isLoading };
};

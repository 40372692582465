import { type IArticle, convertFloatToSizeInformation } from "@msuite/katana";
import { Center } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { CurrentStockHandler } from "./current-stock-handler";
import { RowActions } from "./row-actions";

const columnHelper = createColumnHelper<IArticle>();

export const columns = [
	columnHelper.accessor("articleId", {
		header: "Artikel-ID",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "10%",
		},
	}),
	columnHelper.accessor("articleProductGroup", {
		header: "Produktgruppe",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "10%",
		},
	}),
	columnHelper.accessor("exactStorageLocationId", {
		header: "Lagerplatz",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "10%",
		},
	}),
	columnHelper.accessor("isStoredOutside", {
		header: "Außen",
		cell: (cell) =>
			cell.getValue() ? (
				<Center>
					<p>&#10003;</p>
				</Center>
			) : (
				""
			),
		meta: {
			width: "5%",
		},
	}),
	columnHelper.accessor("articleName_de", {
		header: "Artikel",
		cell: (cell) => cell.getValue(),
		meta: {
			width: "10%",
		},
	}),
	columnHelper.accessor("currentStock", {
		header: "Bestand",
		cell: (cell) => <CurrentStockHandler currentStock={cell.row.original.currentStock} />,
		meta: {
			width: "12.5%",
			isNumeric: true,
		},
	}),
	columnHelper.accessor("height", {
		header: "Höhe",
		cell: (cell) => convertFloatToSizeInformation(cell.getValue()),
		meta: {
			width: "7.5%",
		},
	}),
	columnHelper.accessor("width", {
		header: "Breite",
		cell: (cell) => convertFloatToSizeInformation(cell.getValue()),
		meta: {
			width: "7.5%",
		},
	}),
	columnHelper.accessor("length", {
		header: "Länge",
		cell: (cell) => convertFloatToSizeInformation(cell.getValue()),
		meta: {
			width: "7.5%",
		},
	}),
	columnHelper.accessor("additionalSizeInfo", {
		header: "Zusatzinformationen",
		cell: (cell) => cell.getValue() || "-",
		meta: {
			width: "10%",
		},
	}),
	columnHelper.display({
		id: "actions",
		header: "",
		cell: (cell) => <RowActions article={cell.row.original} />,
		meta: {
			width: "10%",
		},
	}),
];

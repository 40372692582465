import { type IEmployeeSessionExtended, moment } from "@msuite/katana";
import { createColumnHelper } from "@tanstack/react-table";
import { StorageLocationName } from "components/storage-locations";
import { StateBadge } from "./state-badge";

export const columnHelper = createColumnHelper<IEmployeeSessionExtended>();

export const columns = [
	columnHelper.accessor("employee.nachname", {
		header: () => "Mitarbeiter",
		cell: (cell) => cell.row.original.employee?.vorname + " " + cell.row.original.employee?.nachname,
		meta: {
			width: "20%",
		},
	}),
	columnHelper.accessor("startedAt", {
		header: () => "Start",
		cell: (cell) => moment(cell.getValue()).format("DD.MM.YYYY / HH:mm:ss [Uhr]"),
		meta: {
			width: "20%",
		},
	}),
	columnHelper.accessor("terminatedAt", {
		header: () => "Ende",
		cell: (cell) => {
			const terminatedAt = cell.row.original.terminatedAt;
			const startedAt = cell.row.original.startedAt;
			return moment(terminatedAt).isBefore(startedAt)
				? "Noch nicht beendet"
				: moment(terminatedAt).format("DD.MM.YYYY / HH:mm:ss [Uhr]");
		},
		meta: {
			width: "20%",
		},
	}),
	columnHelper.accessor("state", {
		header: () => "Status",
		cell: (cell) => <StateBadge state={cell.getValue()} />,
		meta: {
			width: "10%",
		},
	}),
	columnHelper.accessor("storageLocation", {
		header: () => "Kommissionierbereich",
		cell: (cell) => <StorageLocationName storageLocationId={cell.getValue()} />,
		meta: {
			width: "30%",
		},
	}),
];

import type { IProductType } from "@msuite/katana";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<IProductType>();

export const columns = [
	columnHelper.accessor("productGroupId", {
		header: "Produktgruppe-ID",
		cell: (cell) => cell.getValue(),
	}),
	columnHelper.accessor("productTypeId", {
		header: "Produkttyp-ID",
		cell: (cell) => cell.getValue(),
	}),
	columnHelper.accessor("name", {
		header: "Name",
		cell: (cell) => cell.getValue(),
	}),
];

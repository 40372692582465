import type { IStorageLocation } from "@msuite/katana";
import { FormControl, FormLabel, Input, Select, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormError } from "./schema";

export const StorageLocationForm: FC = () => {
	/** Context */
	const methods = useFormContext<IStorageLocation>();

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Name</FormLabel>
				<Input
					{...methods.register("name")}
					placeholder="Name"
				/>
				<FormError name="name" />
			</FormControl>
			<FormControl>
				<FormLabel>Type</FormLabel>
				<Select
					{...methods.register("type")}
					placeholder="Bitte auswählen"
				>
					<option value="articles">Artikel</option>
					<option value="loadCarriers">Ladungsträger</option>
				</Select>
				<FormError name="type" />
			</FormControl>
		</VStack>
	);
};

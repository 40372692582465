import type { IStorageLocation, ISuborder } from "@msuite/katana";
import { DocumentString } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { db } from "core/_firebase";
import { StatusBadge } from "sites/orders/status-badge";
import { RowActions } from "./row-actions";

const columnHelper = createColumnHelper<ISuborder & { id: string }>();

export const columns = [
	columnHelper.accessor("id", {
		header: () => "Kommissionierbereich",
		cell: (cell) => (
			<DocumentString<IStorageLocation>
				db={db}
				path={`_stock/maindata/storageLocations/${cell.getValue()}`}
				fields={["name"]}
			/>
		),
		meta: {
			width: "20%",
		},
	}),
	columnHelper.display({
		id: "articles",
		header: () => "Artikel",
		cell: (cell) =>
			cell.row.original.articles.reduce((acc, article) => {
				const result = article.quantity + acc;
				return result;
			}, 0) + " Artikel",
		meta: {
			width: "30%",
		},
	}),
	columnHelper.display({
		id: "status",
		header: () => "Status",
		cell: (cell) => <StatusBadge status={cell.row.original.status} />,
		meta: {
			width: "30%",
		},
	}),
	columnHelper.display({
		id: "articles",
		header: () => "",
		cell: (cell) => <RowActions suborder={cell.row.original} />,
		meta: {
			isNumeric: true,
			width: "20%",
		},
	}),
];

import { type Employee, type IArticleStockHistoryItem, getArticleStockUpdateTypeString } from "@msuite/katana";
import { DocumentString } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { db } from "core/_firebase";
import moment from "moment";

const columnHelper = createColumnHelper<IArticleStockHistoryItem>();

export const columns = [
	columnHelper.accessor("updatedAt", {
		header: "Datum",
		cell: (cell) => moment(cell.getValue()).format("DD.MM.YYYY / HH:mm:ss [Uhr]"),
	}),
	columnHelper.accessor("stockChangeValue", {
		header: "Bestandsänderung",
		cell: (cell) => `${cell.getValue()} Stk.`,
	}),
	columnHelper.accessor("oldStock", {
		header: "Alter Bestand",
		cell: (cell) => `${cell.getValue()} Stk.`,
	}),
	columnHelper.accessor("newStock", {
		header: "Neuer Bestand",
		cell: (cell) => `${cell.getValue()} Stk.`,
	}),
	columnHelper.accessor("updatedBy", {
		header: "User",
		cell: (cell) => (
			<DocumentString<Employee>
				db={db}
				path={`mitarbeiter/${cell.getValue()}`}
				fields={["vorname", "nachname"]}
				shouldExtendSearch
				id={cell.getValue()}
			/>
		),
	}),
	columnHelper.accessor("updateType", {
		header: "Art",
		cell: (cell) => getArticleStockUpdateTypeString(cell.getValue()),
	}),
];

import { type GenerateLoadCarrierForm, loadCarrierTypeToReadable } from "@msuite/katana";
import { FormControl, FormLabel, HStack, Input, Select, Switch, Text, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormError, loadCarrierTypes } from "./schema";

export const LoadCarrierForm: FC = () => {
	/** Context */
	const { register, formState } = useFormContext<GenerateLoadCarrierForm>();

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Typ</FormLabel>
				<Select
					placeholder="Typ auswählen"
					{...register("type")}
				>
					{loadCarrierTypes.map((type) => (
						<option
							key={type}
							value={type}
						>
							{loadCarrierTypeToReadable(type)}
						</option>
					))}
				</Select>
				<FormError name="type" />
			</FormControl>
			<FormControl>
				<FormLabel>Anzahl der Ladungsträger</FormLabel>
				<Input
					type="number"
					step={1}
					min={1}
					max={9999}
					{...register("numberOfLoadCarriers", { valueAsNumber: true })}
				/>
				<FormError name="numberOfLoadCarriers" />
			</FormControl>
			<HStack
				justify="flex-start"
				gap={0}
			>
				<Switch {...register("fillIdGaps")} />
				<Text>ID Lücken automatisch füllen</Text>
			</HStack>
		</VStack>
	);
};

import { yupResolver } from "@hookform/resolvers/yup";
import { type IArticleForm, type StockFileUploadResult, generateId } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	VStack,
	handleOnReject,
	toast,
} from "@msuite/picasso";
import { ArticleForm } from "components/forms";
import { articleFormSchema } from "components/forms/article-form/schema";
import { ax } from "core/_axios";
import { storage } from "core/_firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Interface */
interface AddArticleModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AddArticleModal: FC<AddArticleModalProps> = ({ isOpen, onClose }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [fileUploadResult, setFileUploadResult] = useState<StockFileUploadResult | undefined>(undefined);

	/** Hooks */
	const methods = useForm<IArticleForm>({
		resolver: yupResolver(articleFormSchema),
	});

	/** Functions */
	async function onSubmit(data: IArticleForm) {
		try {
			setIsLoading(true);
			if (fileUploadResult) {
				const fileType = fileUploadResult.file.type.split("/")[1];
				const randomId = generateId();
				const storageRef = await ref(storage, `stock/articleImages/${randomId}.${fileType}`);
				await uploadBytes(storageRef, fileUploadResult.file);
				const downloadUrl = await getDownloadURL(storageRef);
				data.imageUrl = downloadUrl;
			}
			await ax.post("/v2/stock/article", { data });
			onClose();
			methods.reset();
		} catch (error) {
			console.error(error);
			toast.error("Etwas ist schief gelaufen.");
		} finally {
			setIsLoading(false);
		}
	}

	async function onChangeFileUpload(result: StockFileUploadResult) {
		setFileUploadResult(result);
	}

	function handleOnClose() {
		setFileUploadResult(undefined);
		onClose();
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(onSubmit, handleOnReject)}>
					<ModalContent maxW="80rem">
						<ModalCloseButton />
						<ModalHeader>Artikel hinzufügen</ModalHeader>
						<ModalBody>
							<VStack>
								<ArticleForm onChangeFileUpload={onChangeFileUpload} />
							</VStack>
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={handleOnClose}
								>
									abbrechen
								</Button>
								<Button
									isLoading={isLoading}
									type="submit"
								>
									Artikel erstellen
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};

import { createContext, useContext } from "react";

/** Interface */
interface IVendorContext {
	isTrusted: boolean;
	setIsTrusted: (value: boolean) => void;
}

/** Context */
export const VendorsContext = createContext<IVendorContext>({} as IVendorContext);

/** Hooks */
export const useVendorsContext = () => {
	return useContext(VendorsContext);
};

import { type IExactStorageLocation, uniqueArray } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
	type useTableOptions,
} from "@msuite/picasso";
import { AssignStorageLocationForm } from "components/forms/assign-storage-location-form";
import { ax } from "core/_axios";
import { type FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface EditExactStorageLocationModalProps {
	exactStorageLocation: IExactStorageLocation;
	storageLocationIds: Array<string>;
	setRowSelection: ReturnType<typeof useTableOptions>["setRowSelection"];
	isOpen: boolean;
	onClose: () => void;
}

export const EditExactStorageLocationModal: FC<EditExactStorageLocationModalProps> = ({
	isOpen,
	onClose,
	exactStorageLocation,
	storageLocationIds,
	setRowSelection,
}) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const uniqueLocationIds = uniqueArray([...storageLocationIds, exactStorageLocation.id]).filter(Boolean);

	/** React-Hook-Form */
	const methods = useForm<IExactStorageLocation>({
		defaultValues: exactStorageLocation,
	});
	const { reset } = methods;

	/** Effects */
	useEffect(() => {
		reset(exactStorageLocation);
	}, [exactStorageLocation, reset]);

	/** Functions */
	async function onSubmit() {
		try {
			setIsLoading(true);
			const promises = [];
			for (const uniqueLocationId of uniqueLocationIds) {
				promises.push(
					ax.patch(`/v2/stock/storage-location/${uniqueLocationId}`, {
						data: {
							values: methods.getValues(),
						},
					}),
				);
			}
			setRowSelection({});
			onClose();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent maxW="70rem">
					<ModalCloseButton />
					<ModalHeader>{exactStorageLocation.id}</ModalHeader>
					<ModalBody>
						<VStack>
							{storageLocationIds.length > 0 && <Text>Ausgewählte Lagerorte: {uniqueLocationIds.length}</Text>}
							<AssignStorageLocationForm />
						</VStack>
					</ModalBody>
					<ModalFooter>
						<ModalButtonGroup>
							<Button
								variant="ghost"
								colorScheme="gray"
								onClick={onClose}
							>
								abbrechen
							</Button>
							<Button
								onClick={onSubmit}
								isLoading={isLoading}
							>
								Aktualisieren
							</Button>
						</ModalButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</FormProvider>
	);
};

import type { ReturnControlListDelivered, ReturnControlListReturned } from "@msuite/katana";
import {
	BlankSlate,
	Button,
	ModalBody,
	ModalButtonGroup,
	ModalFooter,
	SegmentedControl,
	useDocument,
	useModalContext,
	VStack,
} from "@msuite/picasso";
import { ReturnControlModalContext } from "context/return-control-modal";
import { db } from "core/_firebase";
import { type FC, useState } from "react";
import { IoFileTray } from "react-icons/io5";
import { ReturnControlArticlesOverview } from "./return-control-articles-overview";
import { ReturnControlLoadCarriersOverview } from "./return-control-load-carriers-overview";

/** Props Interface */
interface ContentProps {
	selectedConstructionSiteId: string | undefined;
}

export const Content: FC<ContentProps> = ({ selectedConstructionSiteId }) => {
	/** Context */
	const { onClose } = useModalContext();

	/** State */
	const [mode, setMode] = useState<"articles" | "loadCarriers">("articles");

	/** Hooks */
	const { data: delivered } = useDocument<ReturnControlListDelivered>(db, {
		path: `baustellen/${selectedConstructionSiteId}/stockReturnControl/delivered`,
		subscribe: true,
	});

	const { data: returned } = useDocument<ReturnControlListReturned>(db, {
		path: `baustellen/${selectedConstructionSiteId}/stockReturnControl/returned`,
		subscribe: true,
	});

	/** Render */
	return (
		<ReturnControlModalContext.Provider value={{ delivered, returned }}>
			<ModalBody>
				{returned === undefined || delivered === undefined ? (
					<BlankSlate
						heading="Bisher keine Bestellungen vorhanden."
						subtext=""
						icon={<IoFileTray />}
					/>
				) : (
					<VStack>
						<SegmentedControl
							options={[
								{
									label: "Artikel",
									value: "articles",
									icon: <p>{delivered.articles?.length ?? 0}</p>,
								},
								{
									label: "Ladungsträger",
									value: "loadCarriers",
									icon: <p>{delivered.loadCarriers?.length ?? 0}</p>,
								},
							]}
							value={mode}
							onChange={setMode}
						/>
						{mode === "articles" && (
							<ReturnControlArticlesOverview
								returned={returned}
								delivered={delivered}
							/>
						)}
						{mode === "loadCarriers" && (
							<ReturnControlLoadCarriersOverview
								returned={returned}
								delivered={delivered}
							/>
						)}
					</VStack>
				)}
			</ModalBody>
			<ModalFooter>
				<ModalButtonGroup>
					<Button onClick={onClose}>Fertig</Button>
				</ModalButtonGroup>
			</ModalFooter>
		</ReturnControlModalContext.Provider>
	);
};

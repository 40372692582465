import { zodResolver } from "@hookform/resolvers/zod";
import type { IVendor } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import { VendorForm } from "components/forms/vendor-form";
import { vendorSchema } from "components/forms/vendor-form/schema";
import { ax } from "core/_axios";
import { type FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface EditVendorModalProps {
	isOpen: boolean;
	onClose: () => void;
	vendor: IVendor;
}

export const EditVendorModal: FC<EditVendorModalProps> = ({ isOpen, onClose, vendor }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** React-Hook-Form */
	const methods = useForm<IVendor>({
		resolver: zodResolver(vendorSchema),
		defaultValues: {
			...vendor,
			contacts: vendor.contacts?.length ? vendor.contacts : [{ type: "unknown" }],
			addresses: vendor.addresses?.length ? vendor.addresses : [{ street: "", zipCode: "", city: "" }],
		},
	});
	const { reset } = methods;

	/** Effects */
	useEffect(() => {
		if (!isOpen) return;
		reset(vendor);
	}, [isOpen, reset, vendor]);

	/** Functions */
	async function handleSubmit() {
		try {
			setIsLoading(true);
			await ax.patch(`/v2/stock/vendor/${vendor.vendorId}`, {
				data: { values: methods.getValues() },
			});
			onClose();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	function handleReject() {
		const errors = methods.formState.errors;
		toast(JSON.stringify(errors, null, 2), {});
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleSubmit, handleReject)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Lieferant bearbeiten</ModalHeader>
						<ModalBody>
							<VendorForm />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									colorScheme="gray"
									onClick={onClose}
								>
									abbrechen
								</Button>
								<Button
									isLoading={isLoading}
									type="submit"
								>
									Aktualisieren
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};

import {
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	PinInput,
	PinInputField,
	Text,
	VStack,
} from "@msuite/picasso";
import { ax } from "core/_axios";
import { type FC, useEffect, useState } from "react";

/** Props Interface */
interface TrustedModalProps {
	isOpen: boolean;
	onClose: () => void;
	isTrusted: boolean;
	setIsTrusted: (value: boolean) => void;
}

export const TrustedModal: FC<TrustedModalProps> = ({ isOpen, onClose, isTrusted, setIsTrusted }) => {
	/** State */
	const [value, setValue] = useState<string>("");
	const [checkedTrustStatus, setCheckedTrustStatus] = useState<boolean>(false);

	/** Functions */
	function handleOnInputChange(value: string) {
		setValue(value);
		checkTrustStatus(value);
	}

	async function checkTrustStatus(value: string) {
		if (value.length < 4) return;
		try {
			await ax.get(`/v2/stock/trusted-check?trustedCode=${value}`);
			setIsTrusted(true);
			setCheckedTrustStatus(true);
			onClose();
		} catch (error) {
			setIsTrusted(false);
			setCheckedTrustStatus(true);
			setValue("");
		}
	}

	/** Effects */
	useEffect(() => {
		setValue("");
		setCheckedTrustStatus(false);
	}, [isOpen]);

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent w="max-content">
				<ModalCloseButton />
				<ModalHeader>Zugänge entsperren</ModalHeader>
				<ModalBody>
					<VStack>
						<Text>Bitte geben Sie den Code ein um die Zugangsdaten anzuzeigen:</Text>
						<HStack justify="center">
							<PinInput
								size="lg"
								autoFocus
								otp
								onChange={handleOnInputChange}
								value={value}
							>
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
							</PinInput>
						</HStack>
						{checkedTrustStatus && <p>{isTrusted ? "Zugangsdaten werden angezeigt" : "Code ist ungültig"}</p>}
					</VStack>
				</ModalBody>
				<ModalFooter>
					<Button onClick={onClose}>Fertig</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

import { StandardDataTable, useTableOptions } from "@msuite/picasso";
import { useOrderDetailsModalContext } from "context/order-details-modal";
import type { FC } from "react";
import { columns } from "./table-config";

export const EmptyLoadCarrierSuborders: FC = () => {
	/** Context */
	const { emptyLoadCarrierSuborders } = useOrderDetailsModalContext();
	const suborder: (typeof emptyLoadCarrierSuborders)[0] | undefined = emptyLoadCarrierSuborders?.[0];

	/** Hooks */
	const tableOptions = useTableOptions();

	/** Render */
	return (
		<StandardDataTable
			{...tableOptions}
			data={suborder?.emptyLoadCarriers ?? []}
			columns={columns}
			isLoading={false}
			hidePagination
		/>
	);
};

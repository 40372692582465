import { type ILoadCarrier, loadCarrierTypeToReadable } from "@msuite/katana";
import { HStack, Text, useDocument, useUIContext, VStack } from "@msuite/picasso";
import { db } from "core/_firebase";
import type { FC } from "react";

/** Props Interface */
interface LoadCarrierCardReturnControlProps {
	loadCarrierId: string;
	rightAppendix?: React.ReactNode;
}

export const LoadCarrierCardReturnControl: FC<LoadCarrierCardReturnControlProps> = ({
	loadCarrierId,
	rightAppendix,
}) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { data: loadCarrier } = useDocument<ILoadCarrier>(db, {
		path: `_stock/maindata/loadCarriers/${loadCarrierId}`,
		subscribe: false,
	});

	/** Render */
	return (
		<HStack
			justify="space-between"
			backgroundColor={colors.whiteAdjusted}
			px={4}
			py={2}
			borderWidth={1}
			rounded="md"
		>
			<VStack spacing={0}>
				<Text fontWeight="semibold">{loadCarrierTypeToReadable(loadCarrier?.type)}</Text>
				<Text
					fontSize="sm"
					fontWeight="medium"
					color="gray.500"
				>
					Ladungsträger-ID: {loadCarrierId}
				</Text>
			</VStack>
			{rightAppendix}
		</HStack>
	);
};

import type { Employee } from "@msuite/katana";
import { Text, Tooltip, useDocument, useUIContext } from "@msuite/picasso";
import { db } from "core/_firebase";
import type { FC } from "react";

/** Props Interface */
interface ManagerBadgeProps {
	id: string;
	tooltip?: boolean;
	isDeputy?: boolean;
}

export const ManagerBadge: FC<ManagerBadgeProps> = ({ id, tooltip, isDeputy }) => {
	/** Context */
	const { data: employee } = useDocument<Employee>(db, {
		path: `mitarbeiter/${id}`,
	});
	const { colors } = useUIContext();
	const { grayLightDarker, orange } = colors;

	/** Render */
	return (
		<Tooltip
			label={employee?.vorname + " " + employee?.nachname}
			isDisabled={!tooltip}
		>
			<Text
				cursor="default"
				fontSize="0.7rem"
				fontWeight="semibold"
				bg={grayLightDarker}
				borderColor={isDeputy ? orange : undefined}
				borderWidth={1}
				lineHeight="0.6rem"
				py="0.25rem"
				px="0.4rem"
				rounded="md"
			>
				{employee?.kurz}
			</Text>
		</Tooltip>
	);
};

import type { IArticle } from "@msuite/katana";
import { useDisclosure, WhiteIconButton } from "@msuite/picasso";
import type { FC } from "react";
import { IoPencil } from "react-icons/io5";
import { EditArticleModal } from "./edit-article-modal";

/** Props Interface */
interface EditArticleModalTriggerProps {
	article: IArticle;
}

export const EditArticleModalTrigger: FC<EditArticleModalTriggerProps> = ({ article }) => {
	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Render */
	return (
		<>
			{isOpen && (
				<EditArticleModal
					isOpen={isOpen}
					onClose={onClose}
					prefetchedArticle={article}
				/>
			)}
			<WhiteIconButton
				icon={<IoPencil />}
				aria-label="Edit"
				size="xs"
				onClick={onOpen}
			/>
		</>
	);
};

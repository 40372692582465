import { moment, type User } from "@msuite/katana";
import { FormLabel, HStack, Text, useDocument, VStack } from "@msuite/picasso";
import { useOrderDetailsModalContext } from "context/order-details-modal";
import { db } from "core/_firebase";
import type { FC } from "react";
import { StatusBadge } from "sites/orders/status-badge";

export const Details: FC = () => {
	/** Context */
	const { order } = useOrderDetailsModalContext();

	const { data: createdBy } = useDocument<User>(db, {
		path: `user/${order?.createdBy}`,
		subscribe: false,
		queryClientOptions: {
			enabled: order !== null,
		},
	});

	const { data: updatedBy } = useDocument<User>(db, {
		path: `user/${order?.updatedBy}`,
		subscribe: false,
		queryClientOptions: {
			enabled: order !== null,
		},
	});

	const isUpdated = !moment(order?.updatedAt).isSame(order?.createdAt);

	/** Render */
	return (
		<VStack>
			<HStack spacing={12}>
				<VStack spacing={0}>
					<FormLabel>Bestellt am</FormLabel>
					<Text>{moment(order?.createdAt).format("LLLL")}</Text>
				</VStack>
				<VStack spacing={0}>
					<FormLabel>Bestellt von</FormLabel>
					<Text>
						{createdBy?.vorname} {createdBy?.nachname}
					</Text>
				</VStack>
			</HStack>
			{isUpdated && (
				<HStack spacing={12}>
					<VStack spacing={0}>
						<FormLabel>Zuletzt aktualisert am</FormLabel>
						<Text>{moment(order?.updatedAt).format("LLLL")}</Text>
					</VStack>
					<VStack spacing={0}>
						<FormLabel>Zuletzt aktualisiert von</FormLabel>
						<Text>
							{updatedBy?.vorname} {updatedBy?.nachname}
						</Text>
					</VStack>
				</HStack>
			)}

			<VStack spacing={0}>
				<FormLabel>Status</FormLabel>
				<div>
					<StatusBadge status={order?.status ?? "pending"} />
				</div>
			</VStack>

			<VStack spacing={0}>
				<FormLabel>Kommentar</FormLabel>
				<Text>{order?.comment ?? "Kein Kommentar vorhanden."}</Text>
			</VStack>
		</VStack>
	);
};

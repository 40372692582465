import type { IArticle, IArticleDependency } from "@msuite/katana";
import { ButtonGroup, useDisclosure, WhiteIconButton } from "@msuite/picasso";
import { useQueryClient } from "@tanstack/react-query";
import { useEditArticleModalContext } from "context/edit-article-modal";
import { type FC, Fragment } from "react";
import { IoPencil, IoTrash } from "react-icons/io5";
import { DeleteDependencyModal } from "../../delete-dependency-modal";
import { EditDependencyModal } from "../../edit-dependency-modal";

/** Props Interface */
interface RowActionsProps {
	articleDependency: IArticleDependency;
	index: number;
}

export const RowActions: FC<RowActionsProps> = ({ articleDependency, index }) => {
	/** Context */
	const { article } = useEditArticleModalContext();

	/** Hooks */
	const editModal = useDisclosure();
	const deleteModal = useDisclosure();
	const qc = useQueryClient();
	const articles = qc.getQueryData<Array<IArticle>>(["_stock/maindata/articles"]);

	/** Render */
	return (
		<>
			{articles !== undefined && article && (
				<Fragment>
					<EditDependencyModal
						isOpen={editModal.isOpen}
						onClose={editModal.onClose}
						rootArticle={article}
						articleDependency={articleDependency}
						articles={articles}
					/>
					<DeleteDependencyModal
						index={index}
						isOpen={deleteModal.isOpen}
						onClose={deleteModal.onClose}
						rootArticle={article}
						articleDependency={articleDependency}
					/>
				</Fragment>
			)}
			<ButtonGroup>
				<WhiteIconButton
					icon={<IoPencil />}
					aria-label="Edit"
					size="xs"
					onClick={editModal.onOpen}
				/>
				<WhiteIconButton
					color="red.500"
					icon={<IoTrash />}
					aria-label="Edit"
					size="xs"
					onClick={deleteModal.onOpen}
				/>
			</ButtonGroup>
		</>
	);
};

import { zodResolver } from "@hookform/resolvers/zod";
import type { IArticle } from "@msuite/katana";
import {
	Button,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Portal,
	VStack,
	toast,
} from "@msuite/picasso";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { useForm } from "react-hook-form";
import { type InventorizeArticleForm, inventorizeArticleSchema } from "./schema";

/** Props Interface */
interface InventorizeArticleModalProps {
	isOpen: boolean;
	onClose: () => void;
	article: IArticle;
}

export const InventorizeArticleModal: FC<InventorizeArticleModalProps> = ({ isOpen, onClose, article }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const methods = useForm<InventorizeArticleForm>({
		defaultValues: {
			newStock: article.currentStock ?? 0,
		},
		resolver: zodResolver(inventorizeArticleSchema),
	});

	/** Functions */
	async function handleOnSubmit({ newStock }: InventorizeArticleForm) {
		try {
			setIsLoading(true);
			await ax.patch(`/v2/stock/article/${article.articleId}/inventorize-article`, { data: { newStock } });
			toast.success("Inventur erfolgreich");
			onClose();
		} catch (error) {
			console.error(error);
			toast.error("Fehlgeschlagen");
		} finally {
			setIsLoading(false);
		}
	}

	async function handleOnReject(error: typeof methods.formState.errors) {
		toast.error("Fehlgeschlagen");
	}

	/** Render */
	return (
		<Portal>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, handleOnReject)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Artikel Inventieren</ModalHeader>
						<ModalBody>
							<VStack>
								<FormControl>
									<FormLabel>Aktueller Bestand</FormLabel>
									<Heading>{article.currentStock ?? 0}</Heading>
								</FormControl>
								<FormControl>
									<FormLabel>Neuer Bestand</FormLabel>
									<Input
										type="number"
										step={1}
										min={0}
										{...methods.register("newStock", { valueAsNumber: true })}
									/>
								</FormControl>
							</VStack>
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									onClick={onClose}
									variant="ghost"
								>
									abbrechen
								</Button>
								<Button
									isLoading={isLoading}
									type="submit"
								>
									Abschließen
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</Portal>
	);
};

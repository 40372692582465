import type { ITransferCarrier } from "@msuite/katana";
import { createColumnHelper } from "@tanstack/react-table";
import { RowActions } from "./row-actions";

const columnHelper = createColumnHelper<ITransferCarrier>();

export const columns = [
	columnHelper.accessor("transferCarrierId", {
		header: "ID",
		cell: (cell) => cell.getValue(),
	}),
	columnHelper.accessor("hasLoad", {
		header: "Ladung",
		cell: (cell) => (cell.getValue() ? "Ja" : "Nein"),
	}),
	columnHelper.display({
		id: "actions",
		header: "",
		cell: (cell) => <RowActions transferCarrierId={cell.row.original.transferCarrierId} />,
		meta: {
			isNumeric: true,
		},
	}),
];

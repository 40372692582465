import { Heading, HStack } from "@msuite/picasso";
import type { FC } from "react";

export const Header: FC = () => {
	return (
		<HStack>
			<Heading fontSize="xl">Bestellungen</Heading>
		</HStack>
	);
};

import { type MenuItems, type NavigationLinks, useAuthContext, useCoreContext } from "@msuite/picasso";
import { signOut } from "firebase/auth";
import { routes } from "managers/routes";
import { TbLogout } from "react-icons/tb";
import packageJson from "../../package.json";

export function useNavigationLinks() {
	/** Context */
	const { auth } = useCoreContext();
	const { validation } = useAuthContext();

	const isAllowed = validation?.isAdmin || validation?.isLogistics;

	/** Render */
	function createMenuItems(): MenuItems {
		if (!validation) return [];
		return [
			{
				id: "app-information",
				render: `${packageJson.name} ${packageJson.version}`,
				onClick: undefined,
			},
			"divider",
			{
				icon: <TbLogout />,
				label: "Logout",
				onClick: () => {
					signOut(auth);
				},
			},
		];
	}

	function createNavigationLinks(): NavigationLinks {
		if (!validation) return [];
		return [
			{ label: "Home", icon: null, to: "/home", associatedRoutes: ["/"], isInvalid: !isAllowed },
			{
				label: "Bestellungen",
				icon: null,
				to: routes.orders,
				associatedRoutes: [routes.orders],
				isInvalid: !isAllowed,
			},
			{
				label: "Rücklaufkontrolle",
				icon: null,
				to: routes.returnControl,
				associatedRoutes: [routes.returnControl],
				isInvalid: !isAllowed,
			},
			{
				label: "Bestandsverwaltung",
				icon: null,
				to: routes.dataMaintenance,
				associatedRoutes: [routes.dataMaintenance],
				isInvalid: !isAllowed,
			},
			{
				label: "Mitarbeiter",
				icon: null,
				to: routes.employees,
				associatedRoutes: [routes.employees],
				isInvalid: !isAllowed,
			},
			{
				label: "Transportaufträge",
				icon: null,
				to: routes.transportOrders,
				associatedRoutes: [routes.transportOrders],
				isInvalid: !isAllowed,
			},
		];
	}

	const navigationLinks = createNavigationLinks();
	const menuItems = createMenuItems();

	return {
		navigationLinks,
		menuItems,
	};
}

export function isLocalhost(): boolean {
	return window.location.hostname === "localhost";
}

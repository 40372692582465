import type { ITransportOrder } from "@msuite/katana";
import { db } from "core/_firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useTransportOrders = (status: ITransportOrder["status"][]) => {
	/** State */
	const [transportOrders, setTransportOrders] = useState<ITransportOrder[]>([]);

	/** Functions */
	function getTransportOrders() {
		try {
			const collectionRef = collection(db, "_stock/maindata/transportOrders");
			const q = query(collectionRef, where("status", "in", status));
			return onSnapshot(q, (snapshot) => {
				const transportOrders: ITransportOrder[] = [];
				for (const doc of snapshot.docs) {
					transportOrders.push(doc.data() as ITransportOrder);
				}
				transportOrders.sort((a, b) => a.updatedAt.localeCompare(b.updatedAt));
				setTransportOrders(transportOrders);
			});
		} catch (error) {
			console.error(error);
		}
	}

	/** Effects */
	useEffect(() => {
		const unsubscribe = getTransportOrders();
		return () => unsubscribe?.();
	}, [status]);

	/** Return */
	return { transportOrders };
};

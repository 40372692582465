import type { ITransferCarrier } from "@msuite/katana";
import { Input, Portal, StandardDataTable, useCollection, useTableOptions } from "@msuite/picasso";
import { db } from "core/_firebase";
import { type FC, Fragment } from "react";
import type { DataMaintenanceChildrenProps } from "../data-maintenance";
import { Header } from "./header";
import { columns } from "./table-config";

/** Props Interface */
interface TransferCarriersProps extends DataMaintenanceChildrenProps {}

export const TransferCarriers: FC<TransferCarriersProps> = ({ searchBarRef, headerRef }) => {
	/** Hooks */
	const tableOptions = useTableOptions();
	const { data: transferCarriers } = useCollection<ITransferCarrier>(db, {
		path: "_stock/maindata/transferCarriers",
		subscribe: true,
	});

	/** Render */
	return (
		<Fragment>
			<Header
				headerRef={headerRef}
				searchBarRef={searchBarRef}
			/>
			<Portal containerRef={searchBarRef}>
				<Input
					isSearch
					value={tableOptions.globalFilter}
					onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
				/>
			</Portal>
			<StandardDataTable
				{...tableOptions}
				data={transferCarriers}
				columns={columns}
				isLoading={!transferCarriers}
			/>
		</Fragment>
	);
};

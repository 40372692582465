import type { ConstructionSite, IEmptyLoadCarriersSuborder, IOrder, ISuborder } from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface IOrderDetailsModalContext {
	order: IOrder | undefined;
	specialSizes: (ISuborder & { id: string }) | undefined;
	suborders: Array<ISuborder>;
	emptyLoadCarrierSuborders: Array<IEmptyLoadCarriersSuborder>;
	constructionSite: ConstructionSite | undefined;
	suborderStatus?: Array<ISuborder["status"]>;
	selectedOrderPath: string | undefined | null;
}

/** Context */
export const OrderDetailsModalContext = createContext<IOrderDetailsModalContext>({} as IOrderDetailsModalContext);

/** Provider */
export const useOrderDetailsModalContext = () => {
	return useContext(OrderDetailsModalContext);
};

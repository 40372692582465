import { Badge } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface StateBadgeProps {
	state: "active" | "inactive";
}

/** Mapping */
const stateMapping = {
	active: "Aktiv",
	inactive: "Inaktiv",
};

export const StateBadge: FC<StateBadgeProps> = ({ state }) => {
	/** Render */
	return (
		<Badge
			colorScheme={state === "active" ? "green" : "gray"}
			size="sm"
		>
			{stateMapping[state]}
		</Badge>
	);
};

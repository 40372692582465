import { AuthProvider, CoreProvider, QueryClientProvider, ThemeProvider } from "@msuite/picasso";
import { ax } from "core/_axios";
import { auth, db, storage } from "core/_firebase";
import { queryClient } from "core/_query";
import ReactDOM from "react-dom/client";
import packageJson from "../package.json";
import { App } from "./App";
import "./global.css";

const root = document.getElementById("root") as HTMLElement;

if (root) {
	ReactDOM.createRoot(root).render(
		<CoreProvider value={{ ax, auth, storage, db, version: packageJson.version }}>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<ThemeProvider titlePrefix="MenkeStock">
						<App />
					</ThemeProvider>
				</AuthProvider>
			</QueryClientProvider>
		</CoreProvider>,
	);
}

import type { IVendor } from "@msuite/katana";
import { FormControl, FormLabel, Input, Label } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { FormError } from "./schema";

export const VendorShopAuthentication: FC = () => {
	/** Context */
	const methods = useFormContext<IVendor>();

	/** Render */
	return (
		<Fragment>
			<Label>Shop</Label>
			<FormControl>
				<FormLabel>Shop-URL</FormLabel>
				<Input
					placeholder="https://shop.vendor-shop.com"
					{...methods.register("signIn.shopUrl")}
				/>
				<FormError name="signIn.shopUrl" />
			</FormControl>
			<FormControl>
				<FormLabel>Benutzername / E-Mail</FormLabel>
				<Input
					placeholder="max@mustermann.de"
					{...methods.register("signIn.username")}
				/>
				<FormError name="signIn.username" />
			</FormControl>
			<FormControl>
				<FormLabel>Passwort</FormLabel>
				<Input
					type="password"
					{...methods.register("signIn.password")}
					isPassword
					placeholder="Passwort"
				/>
				<FormError name="signIn.password" />
			</FormControl>
		</Fragment>
	);
};

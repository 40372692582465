import { type IArticle, type StockFileUploadResult, generateId } from "@msuite/katana";
import {
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Portal,
	SegmentedControl,
	VStack,
	handleOnReject,
	toast,
	useDocument,
} from "@msuite/picasso";
import { VendorArticles } from "components/articles";
import { ArticleForm } from "components/forms";
import { EditArticleModalContext } from "context/edit-article-modal";
import { ax } from "core/_axios";
import { db, storage } from "core/_firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { type FC, Fragment, useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { ArticleDependencies } from "./article-dependencies";

/** Props Interface */
interface EditArticleModalProps {
	isOpen: boolean;
	onClose: () => void;
	prefetchedArticle: IArticle;
}

export const EditArticleModal: FC<EditArticleModalProps> = ({ isOpen, onClose, prefetchedArticle }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [mode, setMode] = useState<"article" | "vendorArticles" | "dependencies">("article");
	const [fileUploadResult, setFileUploadResult] = useState<StockFileUploadResult | undefined>(undefined);

	const { data: article } = useDocument<IArticle>(db, {
		path: `_stock/maindata/articles/${prefetchedArticle.articleId}`,
		subscribe: true,
	});

	/** React-Hook-Form */
	const vendorArticlesRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const headerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const methods = useForm<IArticle>({
		defaultValues: article,
	});
	const { reset, handleSubmit } = methods;
	const [articleId] = useWatch({ control: methods.control, name: ["articleId"] });

	/** Functions */
	async function onSubmit(values: IArticle) {
		try {
			setIsLoading(true);
			setMode("article");
			if (fileUploadResult) {
				const fileType = fileUploadResult.file.type.split("/")[1];
				const randomId = generateId();
				const storageRef = await ref(storage, `stock/articleImages/${randomId}_now.${fileType}`);
				const result = await uploadBytes(storageRef, fileUploadResult.file);
				const downloadUrl = await getDownloadURL(result.ref);
				values.imageUrl = downloadUrl;
			}
			await ax.patch(`/v2/stock/articles/${article?.articleId}`, { data: { values } });
			onClose();
		} catch (error) {
			console.error(error);
			toast.error("Etwas ist schief gelaufen.");
		} finally {
			setIsLoading(false);
		}
	}

	async function onChangeFileUpload(result: StockFileUploadResult) {
		setFileUploadResult(result);
	}

	/** Effects */
	useEffect(() => {
		reset(article);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [article, isOpen]);

	/** Render */
	return (
		<Portal>
			<Fragment>
				<EditArticleModalContext.Provider value={{ article }}>
					<FormProvider {...methods}>
						<Modal
							isOpen={isOpen}
							onClose={onClose}
						>
							<ModalOverlay />
							<form onSubmit={handleSubmit(onSubmit, handleOnReject)}>
								<ModalContent maxW="90rem">
									<ModalCloseButton />
									<ModalHeader>
										{article?.articleName_de ?? "Loading..."}
										<HStack
											mt={6}
											justify="space-between"
										>
											<SegmentedControl
												options={[
													{ label: "Artikel", value: "article" },
													{
														label: "Lieferantenartikel",
														value: "vendorArticles",
													},
													{
														label: "Abhängigkeiten",
														value: "dependencies",
													},
												]}
												value={mode}
												onChange={setMode}
											/>
											<div ref={headerRef} />
										</HStack>
									</ModalHeader>
									<ModalBody>
										<VStack
											flexGrow={0}
											alignItems="stretch"
											spacing={6}
										>
											{mode === "article" && <ArticleForm onChangeFileUpload={onChangeFileUpload} />}
											{mode === "vendorArticles" && <div ref={vendorArticlesRef} />}
											{mode === "dependencies" && (
												<ArticleDependencies
													headerRef={headerRef}
													key={article?.dependingArticles?.length}
												/>
											)}
										</VStack>
									</ModalBody>
									<ModalFooter>
										<ModalButtonGroup>
											<Button
												colorScheme="gray"
												variant="ghost"
												onClick={onClose}
											>
												abbrechen
											</Button>
											<Button
												type="submit"
												isLoading={isLoading}
											>
												Aktualisieren
											</Button>
										</ModalButtonGroup>
									</ModalFooter>
								</ModalContent>
							</form>
						</Modal>
					</FormProvider>
				</EditArticleModalContext.Provider>
				{mode === "vendorArticles" && (
					<Portal containerRef={vendorArticlesRef}>
						<VendorArticles
							articleId={articleId}
							headerRef={headerRef}
						/>
					</Portal>
				)}
			</Fragment>
		</Portal>
	);
};

import { yupResolver } from "@hookform/resolvers/yup";
import type { IProductGroup } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	VStack,
	handleOnReject,
	toast,
} from "@msuite/picasso";
import { ProductGroupForm } from "components/forms";
import { productGroupSchema } from "components/forms/product-group-form/schema";
import { ax } from "core/_axios";
import { type FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddProductGroupModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AddProductGroupModal: FC<AddProductGroupModalProps> = ({ isOpen, onClose }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const methods = useForm<IProductGroup>({
		resolver: yupResolver(productGroupSchema),
	});

	/** Functions */
	async function handleOnSubmit(data: IProductGroup) {
		try {
			setIsLoading(true);
			await ax.post("/v2/stock/product-group", { data });
			onClose();
			methods.reset();
		} catch (error) {
			toast.error(`Produktgruppe mit der ID ${data.id} ist bereits vorhanden.`);
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, handleOnReject)}>
					<ModalContent maxWidth="90rem">
						<ModalHeader>Produktgruppe hinzufügen</ModalHeader>
						<ModalBody>
							<VStack>
								<ProductGroupForm />
							</VStack>
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={onClose}
								>
									abbrechen
								</Button>
								<Button
									type="submit"
									isLoading={isLoading}
								>
									Produktgruppe erstellen
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};

import { moment } from "@msuite/katana";
import { Box, Text } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";
import { IoChatbubble } from "react-icons/io5";
import { TbLetterL, TbLetterS } from "react-icons/tb";
import { StorageLocationActions } from "./storage-location-actions";
import type { FullInformationOrder } from "./use-orders";

const columnHelper = createColumnHelper<FullInformationOrder>();

export const columns = [
	columnHelper.accessor("id", {
		header: () => "Order-Nr.",
		cell: ({ row }) => <Text fontFamily="mono">{row.original.id}</Text>,
		meta: {
			width: "10%",
			cursor: "pointer",
		},
	}),
	columnHelper.accessor("comment", {
		header: () => "",
		cell: (cell) => <Box>{cell.getValue() ? <IoChatbubble /> : null}</Box>,
		meta: {
			width: "5%",
		},
	}),
	columnHelper.accessor("vehicleType", {
		header: () => "LKW-Typ",
		cell: (cell) => (cell.getValue() === "large" ? <TbLetterL /> : <TbLetterS />),
		meta: {
			width: "10%",
		},
	}),
	columnHelper.accessor("hasSpecialSizes", {
		header: () => "M.S.",
		cell: (cell) => (cell.getValue() ? "Ja" : "Nein"),
		meta: {
			width: "10%",
		},
	}),
	columnHelper.accessor("constructionSite.bauvorhaben", {
		header: () => "Bauvorhaben",
		cell: ({ row }) => row.original.constructionSite.bauvorhaben,
		meta: {
			width: "15%",
			cursor: "pointer",
		},
	}),
	columnHelper.accessor("tour", {
		header: () => "Tour",
		cell: ({ row }) => (row.original.tour === "any" ? "Beliebig" : `Tour ${row.original.tour ?? 1}`),
		meta: {
			width: "7.5%",
			cursor: "pointer",
		},
	}),
	columnHelper.accessor("employee.nachname", {
		header: () => "Erstellt von",
		cell: ({ row }) => row.original.employee.vorname + " " + row.original.employee.nachname,
		meta: {
			width: "12.5%",
			cursor: "pointer",
		},
	}),
	columnHelper.accessor("deliveryDate", {
		header: () => "Auslieferungsdatum",
		cell: ({ row }) =>
			row.original.deliveryDateIsWeek
				? moment(row.original.deliveryWeek, "YYYY-[W]WW").format("[Kalenderwoche] WW[ / ]YYYY")
				: moment(row.original.deliveryDate).format("dddd, DD.MM.YYYY"),
		sortingFn: (a, b) => {
			const aDate = a.original.deliveryDateIsWeek
				? moment(a.original.deliveryWeek, "YYYY-[W]WW")
				: moment(a.original.deliveryDate);
			const bDate = b.original.deliveryDateIsWeek
				? moment(b.original.deliveryWeek, "YYYY-[W]WW")
				: moment(b.original.deliveryDate);
			return aDate.isBefore(bDate) ? -1 : 1;
		},
		meta: {
			width: "15%",
			cursor: "pointer",
		},
	}),
	columnHelper.display({
		id: "actions",
		header: () => "Bearbeiten",
		cell: ({ row }) => {
			const storageLocationIds = [...new Set(row.original.articleList.map((article) => article.storageLocationId))].filter(Boolean)
			return (
				<StorageLocationActions
					order={row.original}
					storageLocationIds={storageLocationIds as string[]}
				/>
			);
		},
		meta: {
			width: "25%",
		},
	}),
];

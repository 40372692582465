import type { IStorageLocation } from "@msuite/katana";
import { DocumentString, HStack, Menu, MenuButton, MenuItem, MenuList, Tag, Text, WhiteIconButton } from "@msuite/picasso";
import { useOrderRowContext } from "context/order-row";
import { ax } from "core/_axios";
import { db } from "core/_firebase";
import { ORDER_STATUS_COLOR, ORDER_STATUS_STRING } from "handlers/orders";
import type { FC } from "react";
import { IoArrowForward } from "react-icons/io5";
import type { IFullInformationOrder } from "../use-orders";

/** Props Interface */
interface ActionMenuProps {
	storageLocationIds: string[];
	order: IFullInformationOrder;
}

export const ActionMenu: FC<ActionMenuProps> = ({ storageLocationIds, order }) => {
	/** Context */
	const { suborders, emptyLoadCarriersSuborders } = useOrderRowContext();
	const emptyLoadCarrierSuborder = emptyLoadCarriersSuborders?.at(0);

	/** Functions */
	async function handleStorageLocationAssignment(
		storageLocationId: string | undefined,
		type: "suborder" | "emptyLoadCarrierSuborder",
	) {
		try {
			console.log(storageLocationId);
			let suborderPath: string | undefined;
			if (type === "suborder") {
				if (!storageLocationId) return;
				suborderPath = `baustellen/${order.constructionSiteId}/orders/${order.id}/suborders/${storageLocationId}`;
			} else {
				suborderPath = `baustellen/${order.constructionSiteId}/orders/${order.id}/emptyLoadCarriersSuborders/${emptyLoadCarrierSuborder?.id}`;
			}
			await ax.patch(`/v2/stock/orders/${order.id}/picking/prepare?suborderPath=${suborderPath}`);
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<Menu placement="bottom-end">
			<MenuButton>
				<WhiteIconButton
					icon={<IoArrowForward />}
					size="sm"
					aria-label="label"
				/>
			</MenuButton>
			<MenuList>
				{suborders?.map((suborder) => {
					return (
						<MenuItem
							key={suborder.id}
							closeOnSelect
							onClick={() => handleStorageLocationAssignment(suborder.storageLocationId as any, "suborder")}
						>
							<HStack
								justify="space-between"
								width="100%"
								gap={12}
							>
								<DocumentString<IStorageLocation>
									path={`_stock/maindata/storageLocations/${suborder.storageLocationId}`}
									db={db}
									fields={["name"]}
								/>
								<Tag
									variant="subtle"
									colorScheme={ORDER_STATUS_COLOR[suborder?.status ?? "NA"]}
								>
									{ORDER_STATUS_STRING[suborder?.status ?? "NA"]}
								</Tag>
							</HStack>
						</MenuItem>
					);
				})}
				{emptyLoadCarrierSuborder && (
					<MenuItem
						closeOnSelect
						onClick={() => {
							handleStorageLocationAssignment(undefined, "emptyLoadCarrierSuborder");
						}}
					>
						<HStack
							justify="space-between"
							width="100%"
							gap={12}
						>
							<Text>Leergut</Text>
							<Tag
								variant="subtle"
								colorScheme={ORDER_STATUS_COLOR[emptyLoadCarrierSuborder?.status ?? "NA"]}
							>
								{ORDER_STATUS_STRING[emptyLoadCarrierSuborder?.status ?? "NA"]}
							</Tag>
						</HStack>
					</MenuItem>
				)}
			</MenuList>
		</Menu>
	);
};

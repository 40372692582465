import type { Employee, IEmployeeSession, IEmployeeSessionExtended } from "@msuite/katana";
import { useQueryClient } from "@tanstack/react-query";
import { db } from "core/_firebase";
import { collectionGroup, doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useSessions = () => {
	/** State */
	const [sessions, setSessions] = useState<IEmployeeSessionExtended[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	/** Hooks */
	const qc = useQueryClient();

	/** Render */
	useEffect(() => {
		setIsLoading(true);
		const subscribe = () => {
			const sessionsRef = collectionGroup(db, "sessions");
			return onSnapshot(sessionsRef, async (snapshot) => {
				setIsLoading(false);
				if (snapshot.empty) {
					return setSessions([]);
				}
				const docs = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as IEmployeeSession);
				docs.sort((a, b) => b.startedAt.localeCompare(a.startedAt));
				docs.sort((a, b) => a.state.localeCompare(b.state));
				const employeeIds = [...new Set(docs.map((doc) => doc.employeeId))];
				const employeeDocs = await Promise.all(
					employeeIds.map(async (id) => {
						let employee: Employee | undefined = qc.getQueryData(["employee", id]);
						if (!employee) employee = (await getDoc(doc(db, `mitarbeiter/${id}`))).data() as Employee;
						qc.setQueryData(["employee", id], employee);
						return { ...employee, id } as Employee;
					}),
				);
				const docsExtended: IEmployeeSessionExtended[] = docs.map((doc) => {
					const employee = employeeDocs.find((employee) => employee.id === doc.employeeId);
					return { ...doc, employee };
				});
				setSessions(docsExtended);
			});
		};
		const unsubscribe = subscribe();
		return () => unsubscribe?.();
	}, []);

	return { sessions, isLoading };
};

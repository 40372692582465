import { Button, FormControl, FormLabel, HStack, Input, Switch, Text, VStack } from "@msuite/picasso";
import type { BarcodeOptions } from "components/modals/barcode-modal";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";

/** Props Interface */
interface BarcodeFormProps {
	isMassGeneration?: boolean;
	isLoading?: boolean;
}

export const BarcodeForm: FC<BarcodeFormProps> = ({ isMassGeneration, isLoading }) => {
	/** Context */
	const { register, reset } = useFormContext<BarcodeOptions>();

	/** Render */
	return (
		<VStack>
			{!isMassGeneration && (
				<FormControl isRequired>
					<FormLabel>Wert</FormLabel>
					<Input {...register("value")} />
				</FormControl>
			)}
			<FormControl>
				<FormLabel>Breite</FormLabel>
				<Input
					step={0.5}
					min={0.5}
					max={2.5}
					type="number"
					{...register("width", { valueAsNumber: true })}
					isDisabled={isLoading}
				/>
			</FormControl>
			<FormControl>
				<FormLabel>Höhe</FormLabel>
				<Input
					step={10}
					min={10}
					max={100}
					type="number"
					{...register("height", { valueAsNumber: true })}
					isDisabled={isLoading}
				/>
			</FormControl>
			<FormControl>
				<FormLabel>Text anzeigen</FormLabel>
				<HStack
					spacing={2}
					alignItems="baseline"
				>
					<Switch
						{...register("displayValue")}
						isDisabled={isLoading}
					/>
					<Text>Barcode Wert unterhalb des Barcodes anzeigen</Text>
				</HStack>
			</FormControl>
			<FormControl>
				<FormLabel>Textabstand</FormLabel>
				<Input
					step={0.5}
					min={0.5}
					max={2.5}
					type="number"
					{...register("textMargin", { valueAsNumber: true })}
					isDisabled={isLoading}
				/>
			</FormControl>
			<FormControl>
				<FormLabel>Schriftgröße</FormLabel>
				<Input
					step={1}
					min={10}
					max={100}
					type="number"
					{...register("fontSize", { valueAsNumber: true })}
					isDisabled={isLoading}
				/>
			</FormControl>
			<FormControl>
				<FormLabel>Hintergrundfarbe</FormLabel>
				<Input
					padding="0"
					type="color"
					{...register("background")}
					isDisabled={isLoading}
				/>
			</FormControl>
			<FormControl>
				<FormLabel>Linienfarbe</FormLabel>
				<Input
					padding="0"
					type="color"
					{...register("lineColor")}
					isDisabled={isLoading}
				/>
			</FormControl>
			<HStack justifyContent="flex-end">
				<Button
					variant="link"
					onClick={() => reset()}
					isDisabled={isLoading}
				>
					zurücksetzen
				</Button>
			</HStack>
		</VStack>
	);
};

import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@msuite/picasso";
import type { FC } from "react";
import { Content } from "./content";

/** Props Interface */
interface ReturnControlModalProps {
	isOpen: boolean;
	onClose: () => void;
	selectedConstructionSiteId: string | undefined;
}

export const ReturnControlModal: FC<ReturnControlModalProps> = ({ isOpen, onClose, selectedConstructionSiteId }) => {
	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent minW="80rem">
				<ModalCloseButton />
				<ModalHeader>Rücklaufkontrolle</ModalHeader>
				{isOpen && <Content selectedConstructionSiteId={selectedConstructionSiteId} />}
			</ModalContent>
		</Modal>
	);
};

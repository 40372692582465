import { StandardDataTable, useTableOptions } from "@msuite/picasso";
import { useOrderDetailsModalContext } from "context/order-details-modal";
import { type FC, useMemo } from "react";
import { columns } from "./table-config";

export const Suborders: FC = () => {
	/** Context */
	const { suborders, suborderStatus, selectedOrderPath } = useOrderDetailsModalContext();

	/** Hooks */
	const tableOptions = useTableOptions();

	/** Constants */
	const filteredSuborders = useMemo(() => {
		return suborderStatus ? suborders.filter((suborder) => suborderStatus.includes(suborder.status)) : suborders;
	}, [suborders, suborderStatus]);

	/** Render */
	return (
		<StandardDataTable
			key={selectedOrderPath}
			{...tableOptions}
			data={filteredSuborders}
			columns={columns}
			isLoading={false}
			hidePagination
		/>
	);
};

import { Wrap } from "@msuite/picasso";
import type { FC } from "react";
import { ManagerBadge } from "./manager-badge";

/** Props Interface */
interface ManagerStackProps {
	managerIds: string[] | undefined;
	deputyIds: string[] | undefined;
}

export const ManagerStack: FC<ManagerStackProps> = ({ deputyIds, managerIds = [] }) => {
	return (
		<Wrap spacing={2}>
			{deputyIds?.map((managerId) => (
				<ManagerBadge
					id={managerId}
					key={managerId}
					isDeputy
				/>
			))}
			{managerIds.map((managerId) => (
				<ManagerBadge
					id={managerId}
					key={managerId}
				/>
			))}
		</Wrap>
	);
};

import type { ConstructionSite, OrderStatus } from "@msuite/katana";
import {
	InnerSiteLayout,
	Input,
	SegmentedControl,
	StandardDataTable,
	useTableOptions,
	useTitle,
} from "@msuite/picasso";
import { TableContext } from "context/table";
import { type FC, Fragment, useMemo, useState } from "react";
import { DataRowWrapper } from "./data-row-wrapper";
import { Header } from "./header";
import { OrderDetailsModal } from "./modals";
import { columns } from "./table-config";
import { type FullInformationOrder, useOrders } from "./use-orders";

export const Orders: FC = () => {
	/** State */
	const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
	const [selectedConstructionSite, setSelectedConstructionSite] = useState<ConstructionSite | undefined>(undefined);
	const [orderStatus, setOrderStatus] = useState<"pending" | "completed">("pending");

	/** Hooks */
	const tableOptions = useTableOptions();
	const orderStatusQuery: Array<OrderStatus> = useMemo(
		() => (orderStatus === "pending" ? ["pending", "in_progress", "ready_for_picking"] : ["completed", "canceled"]),
		[orderStatus],
	);
	const { orders, isLoading } = useOrders({ orderStatusQuery });
	useTitle("Bestellungen");

	/** Functions */
	async function handleRowClick(order: FullInformationOrder) {
		const selectedOrderPath = `baustellen/${order.constructionSiteId}/orders/${order.id}`;
		setSelectedOrder(selectedOrderPath);
		setSelectedConstructionSite(order.constructionSite);
	}

	function handleClose() {
		setSelectedOrder(null);
	}

	/** Render */
	return (
		<Fragment>
			{selectedOrder && (
				<OrderDetailsModal
					isOpen={Boolean(selectedOrder)}
					onClose={handleClose}
					selectedOrderPath={selectedOrder}
					selectedConstructionSite={selectedConstructionSite}
				/>
			)}
			<TableContext.Provider value={tableOptions}>
				<InnerSiteLayout>
					<Header />
					<Input
						isSearch
						value={tableOptions.globalFilter}
						onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
					/>
					<SegmentedControl
						options={[
							{ label: "Offen", value: "pending" },
							{ label: "Abgeschlossen", value: "completed" },
						]}
						value={orderStatus}
						onChange={setOrderStatus}
					/>
					<StandardDataTable
						{...tableOptions}
						data={orders}
						columns={columns}
						onRowClick={handleRowClick}
						isLoading={isLoading}
						DataRowContextExtension={DataRowWrapper}
						excludeFromRowClick={["actions"]}
					/>
				</InnerSiteLayout>
			</TableContext.Provider>
		</Fragment>
	);
};

import type { ILoadCarrier } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
	useUIContext,
} from "@msuite/picasso";
import { ax } from "core/_axios";
import { type FC, useState } from "react";

/** Props Interface */
interface DeleteLoadCarrierModalProps {
	isOpen: boolean;
	onClose: () => void;
	loadCarrier: ILoadCarrier;
}

export const DeleteLoadCarrierModal: FC<DeleteLoadCarrierModalProps> = ({ isOpen, onClose, loadCarrier }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Context */
	const { colors } = useUIContext();

	/** Functions */
	async function handleDelete() {
		try {
			setIsLoading(true);
			toast.promise(ax.delete(`/v2/stock/load-carriers/${loadCarrier.loadCarrierId}`), {
				success: () => {
					setIsLoading(false);
					onClose();
					return "Ladungsträger wurde erfolgreich gelöscht";
				},
				error: () => {
					setIsLoading(false);
					return "Ladungsträger konnte nicht gelöscht werden";
				},
				loading: "Ladungsträger wird gelöscht",
			});
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>Ladungsträger löschen</ModalHeader>
				<ModalBody>
					Möchten Sie den Ladungsträger <strong>{loadCarrier.loadCarrierId}</strong> wirklich löschen?
				</ModalBody>
				<ModalFooter>
					<ModalButtonGroup>
						<Button
							variant="ghost"
							onClick={onClose}
						>
							abbrechen
						</Button>
						<Button
							colorScheme="red"
							isLoading={isLoading}
							onClick={handleDelete}
						>
							Löschen
						</Button>
					</ModalButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

import { VStack } from "@msuite/picasso";
import { ArticleOverview } from "components/articles";
import { VendorArticles } from "components/articles/vendor-articles";
import { useArticleStockModalContext } from "context/article-stock-modal";
import type { FC } from "react";
import { CurrentStock } from "./current-stock";
import { StockHistory } from "./stock-history";

/** Props Interface */
interface ContentProps {
	articleId: string;
	headerRef: React.MutableRefObject<HTMLDivElement>;
}

export const Content: FC<ContentProps> = ({ articleId, headerRef }) => {
	/** Context */
	const { currentView } = useArticleStockModalContext();

	/** Render */
	return (
		<VStack>
			<ArticleOverview articleId={articleId} />
			{currentView === "current-stock" && <CurrentStock />}
			{currentView === "vendor-articles" && (
				<VendorArticles
					articleId={articleId}
					headerRef={headerRef}
				/>
			)}
			{currentView === "stock-history" && <StockHistory />}
		</VStack>
	);
};

import { Box, HStack, Text, useUIContext } from "@msuite/picasso";
import { useReturnControlModalContext } from "context/return-control-modal";
import type { FC } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";

/** Props Interface */
interface ReturnControlArticleOverviewProps {
	articleId: string;
}

export const ReturnControlArticleOverview: FC<ReturnControlArticleOverviewProps> = ({ articleId }) => {
	/** Context */
	const { colors } = useUIContext();
	const { delivered, returned } = useReturnControlModalContext();

	/** Constants */
	const deliveredArticle = delivered?.articles.find((article) => article.articleId === articleId);
	const returnedArticle = returned?.articles.find((article) => article.articleId === articleId);

	/** Render */
	return (
		<HStack spacing={12}>
			<HStack
				spacing={2}
				fontSize="sm"
			>
				<Box color={colors.green}>
					<IoArrowForward />
				</Box>
				<Text>{deliveredArticle?.quantity ?? 0}</Text>
			</HStack>
			<HStack
				spacing={2}
				fontSize="sm"
			>
				<Box color={colors.red}>
					<IoArrowBack />
				</Box>
				<Text>{returnedArticle?.quantity ?? 0}</Text>
			</HStack>
		</HStack>
	);
};

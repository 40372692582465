import type { ConstructionSite } from "@msuite/katana";
import { createColumnHelper } from "@tanstack/react-table";
import { AddressInformation, ManagerStack } from "components/construction-sites";

const columnHelper = createColumnHelper<ConstructionSite>();

export const columnSizes = ["10%", "6%", "24%", "20%", "20%", "10%", "10%"];

export const columns = [
	columnHelper.accessor("nummer", {
		header: () => "Nummer",
		cell: (info) => info.getValue(),
		meta: {
			width: "16%",
		},
	}),
	columnHelper.accessor("bauvorhaben", {
		header: () => "Bauvorhaben",
		cell: (info) => info.getValue(),
		meta: {
			width: "24%",
		},
	}),
	columnHelper.accessor("auftraggeber", {
		header: () => "Auftraggeber",
		cell: (info) => info.getValue(),
		meta: {
			width: "20%",
		},
	}),
	columnHelper.accessor("strasse", {
		header: () => "Adresse",
		cell: (info) => <AddressInformation constructionSite={info.row.original} />,
		meta: {
			width: "20%",
		},
	}),
	columnHelper.display({
		id: "bauleiter",
		header: () => "Bauleiter",
		enableSorting: false,
		cell: ({ row }) => (
			<ManagerStack
				deputyIds={row.original.currentDeputies}
				managerIds={row.original.bauleiter}
			/>
		),
		meta: {
			width: "20%",
		},
	}),
];

import type { IVendor } from "@msuite/katana";
import { Text, VStack } from "@msuite/picasso";
import { useVendorsContext } from "context/vendors";
import type { FC } from "react";

/** Props Interface */
interface VendorShopAuthenticationRowProps {
	vendor: IVendor;
}

export const VendorShopAuthenticationRow: FC<VendorShopAuthenticationRowProps> = ({ vendor }) => {
	/** Context */
	const { isTrusted } = useVendorsContext();

	const hasCredentials = Boolean(vendor.signIn?.password.trim());

	if (!hasCredentials) return <Text>--</Text>;

	/** Render */
	return (
		<VStack spacing={0}>
			<Text>Shop-URL: {isTrusted ? vendor.signIn?.shopUrl : "*********"}</Text>
			<Text>Benutzername: {isTrusted ? vendor.signIn?.username : "*********"}</Text>
			<Text>Passwort: {isTrusted ? vendor.signIn?.password : "*********"}</Text>
		</VStack>
	);
};

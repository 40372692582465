import type J from "jimp";
import JPEG from "jpeg-js";

const { Jimp } = window as typeof window & { Jimp: typeof J };
Jimp.decoders["image/jpeg"] = (data: Buffer) => JPEG.decode(data, { maxMemoryUsageInMB: 1024 * 2 });

const IMAGE_SIZE = 700;
const IMAGE_PADDING = 50;

export async function centerizeImage(file: File) {
	try {
		const downloadUrl = await getDownloadUrlFromFile(file);
		if (!downloadUrl) throw new Error("No download url");
		const image = await Jimp.read(downloadUrl);
		image.resize(Jimp.AUTO, IMAGE_SIZE);
		const bounds = findBounds(image);
		const croppedImage = cropImage(image, bounds);
		const rectangle = createRectangle(croppedImage);

		const newFile = await new Promise<File>((resolve, reject) => {
			rectangle.getBuffer(Jimp.MIME_JPEG, (error, buffer) => {
				if (error) reject(error);
				const newFile = new File([buffer], file.name, { type: file.type });
				resolve(newFile);
			});
		});

		return newFile;
	} catch (error) {
		console.error(error);
	}
}

function createRectangle(image: J) {
	const width = image.getWidth();
	const height = image.getHeight();
	const biggestSide = Math.max(width, height);
	const rectangle = new Jimp(biggestSide + IMAGE_PADDING * 2, biggestSide + IMAGE_PADDING * 2, 0xffffffff);
	const x = (biggestSide - width) / 2 + IMAGE_PADDING;
	const y = (biggestSide - height) / 2 + IMAGE_PADDING;
	rectangle.composite(image, x, y);
	return rectangle;
}

function cropImage(image: J, bounds: ReturnType<typeof findBounds>) {
	const { earliestY, latestY, earliestX, latestX } = bounds;
	const width = latestX - earliestX;
	const height = latestY - earliestY;
	const croppedImage = image.clone().crop(earliestX, earliestY, width, height);
	return croppedImage;
}

function findBounds(image: J) {
	const width = image.getWidth();
	const height = image.getHeight();

	let earliestY: number = height;
	let latestY = -1;
	let earliestX: number = width;
	let latestX = -1;

	for (let x = 0; x < width; x++) {
		for (let y = 0; y < height; y++) {
			const pixel = image.getPixelColor(x, y);
			const pixelIsWhite = pixelIsAlmostWhite(pixel);
			if (pixelIsWhite) continue;
			earliestX = Math.min(earliestX, x);
			latestX = Math.max(latestX, x);
			earliestY = Math.min(earliestY, y);
			latestY = Math.max(latestY, y);
		}
	}

	return { earliestY, latestY, earliestX, latestX };
}

function pixelIsAlmostWhite(pixelColor: number) {
	const { r, g, b } = Jimp.intToRGBA(pixelColor);
	return r > 240 && g > 240 && b > 240;
}

export async function getDownloadUrlFromFile(file: File) {
	try {
		const downloadUrl = await new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = () => reject(reader.error);
			reader.readAsDataURL(file);
		});
		return downloadUrl;
	} catch (error) {
		console.error(error);
	}
}

import type { IExactStorageLocation } from "@msuite/katana";
import { Input, Portal, StandardDataTable, useCollection, useTableOptions } from "@msuite/picasso";
import { db } from "core/_firebase";
import { type FC, useMemo } from "react";
import type { DataMaintenanceChildrenProps } from "../data-maintenance";
import { ExactStorageLocationsContext } from "./context";
import { Header } from "./header";
import { columns } from "./table-config";

/** Props Interface */
interface ExactStorageLocationsProps extends DataMaintenanceChildrenProps { }

export const ExactStorageLocations: FC<ExactStorageLocationsProps> = ({ headerRef, searchBarRef }) => {
	/** Hooks */
	const { data: exactStorageLocations } = useCollection<IExactStorageLocation>(db, {
		path: "_stock/maindata/exactStorageLocations",
		limit: 9999,
		subscribe: true,
	});
	const tableOptions = useTableOptions();

	/** State */
	// map ids ccording to tableOptions.rowSelection from exactStorageLocations
	const selectedIds = useMemo(() => {
		return exactStorageLocations.filter((_, index) => {
			return tableOptions.rowSelection[index];
		}).map((item) => item.id);
	}, [exactStorageLocations, tableOptions.rowSelection]);

	/** Render */
	return (
		<ExactStorageLocationsContext.Provider value={{ selectedIds, setRowSelection: tableOptions.setRowSelection }}>
			<Header
				headerRef={headerRef}
				searchBarRef={searchBarRef}
			/>
			<Portal containerRef={searchBarRef}>
				<Input
					isSearch
					value={tableOptions.globalFilter}
					onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
				/>
			</Portal>
			<StandardDataTable
				{...tableOptions}
				columns={columns}
				data={exactStorageLocations}
				isLoading={false}
				enableMultiRowSelection
				hasRowSelection
			/>
		</ExactStorageLocationsContext.Provider>
	);
};

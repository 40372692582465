import { Button, Portal, useDisclosure } from "@msuite/picasso";
import { AddLoadCarrierModal } from "components/load-carriers";
import { useLoadCarriersContext } from "context/load-carriers";
import type { FC } from "react";
import { IoAdd } from "react-icons/io5";
import type { DataMaintenanceChildrenProps } from "../data-maintenance";

/** Props Interface */
interface HeaderProps extends DataMaintenanceChildrenProps {}

export const Header: FC<HeaderProps> = ({ headerRef }) => {
	/** State */
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Context */
	const { type } = useLoadCarriersContext();

	/** Render */
	return (
		<>
			<Portal containerRef={headerRef}>
				<Button
					leftIcon={<IoAdd />}
					onClick={onOpen}
				>
					Ladungträger hinzufügen
				</Button>
			</Portal>
			<AddLoadCarrierModal
				key={type}
				isOpen={isOpen}
				onClose={onClose}
				defaultType={type}
			/>
		</>
	);
};

import type { IExactStorageLocation, IStorageLocation } from "@msuite/katana";
import { FormControl, FormLabel, Select, useCollection } from "@msuite/picasso";
import { db } from "core/_firebase";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";

export const AssignStorageLocationForm: FC = () => {
	/** Context */
	const { register } = useFormContext<IExactStorageLocation>();

	/** Hooks */
	const { data } = useCollection<IStorageLocation>(db, {
		path: "_stock/maindata/storageLocations",
		limit: 9999,
		subscribe: true,
	});

	/** Render */
	return (
		<FormControl isRequired>
			<FormLabel>Lagerbereich</FormLabel>
			<Select
				{...register("storageLocationId")}
				placeholder="Lagerbereich auswählen"
			>
				{data.map((storageLocation) => (
					<option
						key={storageLocation.id}
						value={storageLocation.id}
					>
						{storageLocation.name}
					</option>
				))}
			</Select>
		</FormControl>
	);
};

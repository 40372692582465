import { IconButton, Tooltip, useDisclosure } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { IoBarcodeOutline } from "react-icons/io5";
import { BarcodeModal } from "./barcode-modal";

/** Props Interface */
interface BarcodeModalTriggerProps {
	barcodeText: string | undefined;
}

export const BarcodeModalTrigger: FC<BarcodeModalTriggerProps> = ({ barcodeText }) => {
	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Fallback */
	if (!barcodeText) return null;

	/** Render */
	return (
		<Fragment>
			<Tooltip label="Barcode generieren">
				<IconButton
					icon={<IoBarcodeOutline />}
					aria-label="Edit"
					size="xs"
					onClick={onOpen}
				/>
			</Tooltip>
			{isOpen && (
				<BarcodeModal
					isOpen={isOpen}
					onClose={onClose}
					value={barcodeText}
				/>
			)}
		</Fragment>
	);
};

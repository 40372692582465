export const __API_URL__: string = getApiUrl();

export function getApiUrl(): string {
	if (import.meta.env.VITE_IS_LOCAL) return "http://localhost:3001";
	return import.meta.env.VITE_SERVER_URL || "";
}

export function getWebsocketUrl(): string {
	if (import.meta.env.VITE_IS_LOCAL) return "ws://localhost:3001";
	return import.meta.env.VITE_WS_URL || "";
}

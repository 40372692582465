import { Input } from "@msuite/picasso";
import type { FC } from "react";
import { IoSearch } from "react-icons/io5";

/** Props Interface */
interface InlaySearchInputProps {
	value: string;
	onChange: (value: string) => void;
}

export const InlaySearchInput: FC<InlaySearchInputProps> = ({ value, onChange }) => {
	return (
		<Input
			value={value}
			onChange={({ target: { value } }) => onChange(value)}
			borderWidth={0}
			borderRadius={0}
			focusBorderColor="transparent"
			bg="transparent"
			leftIcon={<IoSearch />}
			placeholder="Suche"
			sx={{
				transform: "translateY(-1px)",
			}}
		/>
	);
};
